import { Link } from "react-router-dom";
import CollectionBrief from "../components/creator/CollectionBrief";
import {useEffect, useState} from "react";
import {NFTCollection} from "../types/Collection";
import {getCollectionsOwner} from "../backend/collections/CollectionGetters";
import {getCurrentUser} from "../ledger/Global";
import ProfileContainer from "../components/global/ProfileContainer/ProfileContainer";
import { useRecoilValue } from "recoil";
import { connectedWallet as connectedWalletRecoil } from "../state/global/connectedWallet";

export default function CollectionCreator() {
  const [collections, setCollections] = useState<NFTCollection[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const connectedWallet = useRecoilValue(connectedWalletRecoil)
  async function getCollectionsOwnedBy(owner: string) {
  let result;
  try {
    result = await getCollectionsOwner(owner);
    console.log(connectedWallet)
    setCollections(result);
    setIsLoading(false);
  } catch {

  }
}

  useEffect(() => {
    getCollectionsOwnedBy(getCurrentUser());
  }, [])

  return (
    <div className="w-full h-full py-[30px] px-[30px] md:py-[80px] md:px-[77px] bg-white text-text-baseDark">
      <ProfileContainer isLoading={isLoading} collectionsNumber={collections.length} personaName={connectedWallet?.persona.label ? connectedWallet.persona.label : ''}/>
      { collections.length > 0 && (
        <>
      <h3 className="font-semibold text-[24px] mt-[50px]">My Collections</h3>

      <table className="w-full table-auto mt-[20px]">
        <thead>
          <tr className="text-left">
            <th className="text-xs text-[#93989A] font-medium uppercase">
              Collection
            </th>
            <th className="text-xs text-[#93989A] font-medium uppercase">
              Volume
            </th>
            <th className="text-xs text-[#93989A] font-medium uppercase">
              24h
            </th>
            <th className="text-xs text-[#93989A] font-medium hidden lg:table-cell uppercase">
              Floor Price
            </th>
            <th className="text-xs text-[#93989A] font-medium hidden lg:table-cell uppercase">
              Owners
            </th>
            <th className="text-xs text-[#93989A] font-medium hidden lg:table-cell uppercase">
              Items
            </th>
          </tr>
        </thead>
        <tbody className="mt-[23px]">
          {collections.map((item: any) => (
            <CollectionBrief collection={item} />
          ))}
        </tbody>
      </table>
      </>
)}
    </div>
  );
}
