const UIButton: React.FC<{
  isDisabled?: boolean,
  onClick: () => void,
  variant: string,
  children: string | JSX.Element | any,
}> = ({isDisabled = false, onClick, variant, children}) => {
  return (
      <button className={`${variant} w-full`}
        disabled={isDisabled}
        onClick={onClick}>
          {children}
      </button>
  )
};

export default UIButton;