import { useParams } from "react-router";
import NFTInformationSection from "../components/NftDetailsPage/NFTInformationSection";
import NftInformation from "../components/NftDetailsPage/NftDetails";
import {
  NFTCollection,
  CollectionItem,
  default_collection,
  default_item,
} from "../types/Collection";
import { useEffect, useState } from "react";
import { getCollectionById } from "../backend/collections/CollectionGetters";
import { getItemById } from "../backend/collections/ItemGetters";
import NFTActivity from "../components/NftDetailsPage/NFTActivity";
import { useLocation } from "react-router-dom";

const NftDetailsPage: React.FC = () => {
  const location = useLocation();
  let collectionId = location.pathname.split("/")[2];
  let nftId = location.pathname.split("/")[3];

  const [isDataLoading, setIsDataLoading] = useState<boolean>(true);
  const [collection, setCollection] = useState<NFTCollection | undefined>(
    undefined,
  );
  const [item, setItem] = useState<CollectionItem | undefined>(undefined);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  async function getData() {
    let result;
    let result2;
    try {
      result = await getCollectionById(parseInt(collectionId));
      setCollection(result);
      result2 = await getItemById(parseInt(collectionId), nftId);
      setItem(result2);
      setIsDataLoading(false);
    } catch (err) {
      console.error("MongoDB connection error:", err);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <div
      className={`w-full h-full flex flex-col py-[30px] px-[30px] md:py-[80px] md:px-[77px] text-center bg-white overflow-x-hidden`}
    >
      <div className="w-full">
        <NFTInformationSection
          item={item}
          collection={collection}
          isLoading={isDataLoading}
        />
      </div>
      <div className="pt-[40px] w-full lg:w-[40%]">
        <NftInformation
          item={item ? item : default_item}
          collection={collection ? collection : default_collection}
          isLoading={isDataLoading}
        />
      </div>
      <NFTActivity
        item_activities={item ? item.activity_history.reverse() : []}
      />
    </div>
  );
};

export default NftDetailsPage;
