import {useNavigate, useParams} from "react-router-dom";
import mockCollectionBanner from "../assets/default/RadLand-BG.jpg";
import mockCollectionProfile from "../assets/default/Radland-icon.jpg";
import { ReactComponent as InstaIcon } from "../assets/global/insta_dark.svg";
import { ReactComponent as TwitterIcon } from "../assets/global/twitter_dark.svg";
import { ReactComponent as WebsiteIcon } from "../assets/global/website.svg";
import { ReactComponent as DiscordIcon } from "../assets/global/discord.svg";
import { ReactComponent as VerifiedIcon } from "../assets/global/verified_icon.svg";
import { ReactComponent as PencilIcon } from "../assets/global/pencil_icon.svg";
import WalletAddress from "../components/utils/WalletAddress";
import CollectionStats from "../components/collection/CollectionStats";
import TabsAlt from "../components/utils/TabsAlt";
import { TabAlt } from "../types/utils.components";
import ItemsList from "../components/global/NftItems/ItemsList";
import MintConfirmModal from "../components/modals/MintConfirmModal";
import React, { useEffect, useState } from "react";
import {NFTCollection, default_collection, ShortCollectionItem} from "../types/Collection";
import {fetchShortItemsFromCollection, getIdsOwnedBy, getMintingInformation} from "../backend/collections/ItemGetters";
import {
  canDeposit,
  getCurrentUser, getNewResourceFromTransaction,
  getNFTTraderCard,
  makeDeposit,
  mintNFTCard, sendNFTs,
} from "../ledger/Global";
import {MintingInformationResponse} from "../types/response/Collection";
import RadixWalletModal from "../components/modals/RadixWalletModal";
import ErrorModal from "../components/modals/ErrorModal";
import {mintCollection, publishCollection, updateCollection} from "../backend/collections/CollectionUpdates";
import {mintCollectionResource} from "../ledger/collections/PublishCollection";
import {kaupa_component, radland_backend_account, radland_trader_card, xrd_address} from "../Constants";
import useFetchCollectionDetails from "../hooks/CollectionDetails/FetchCollectionDetails";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import {rescindProposals} from "../ledger/kaupa/RescindProposal";
import {deleteAllItems} from "../backend/collections/ItemUpdates";

export default function CollectionDetails() {
  const id = useParams().collectionId || "";
  const [nameFilter, setNameFilter] = useState<string | undefined>();
  const [minPrice, setMinPrice] = useState<number | undefined>();
  const [maxPrice, setMaxPrice] = useState<number | undefined>();
  const [priceOrder, setPriceOrder] = useState<"ASC" | "DESC">("ASC");
  const [status, setStatus] = useState<"All" | "Listed" | "Owned">("All");
  const [traitsFilter, setTraitsFilter] = useState<{ [traits_name: string]: string[] }>({});
  const [nextIndex, setNextIndex] = useState<number | undefined>();
  const [needsUpdate, setNeedsUpdate] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false)

  const { data: collectionData, isLoading: isCollectionDetailsLoading } = useFetchCollectionDetails(id);

  const [collection, setCollection] = useState<NFTCollection>(default_collection);
  const [isDescriptionExtended, setIsDescriptionExtended] = useState<boolean>(false);
  const [collectionItems, setCollectionItems] = useState<ShortCollectionItem[]>([]);
  
  const [activeTab, setActiveTab] = useState<number>(0);

  const [isRadixWalletModalOpen, setIsRadixWalletModalOpen] = useState<boolean>(false);
  const [walletMessage, setWalletMessage] = useState<string>("");
  const [walletTitle, setWalletTitle] = useState<string>("")
  const [walletStatus, setWalletStatus] = useState<"success" | "error" | "loading">("loading");
  const [refreshOnSuccess, setRefreshOnSuccess] = useState<boolean>(false);
  const [loadingMessage, setLoadingMessage] = useState<string|undefined>(undefined)

  const [isMintConfirmModalOpen, setIsMintConfirmModalOpen] = useState<boolean>(false);
  const [mintMessage, setMintMessage] = useState<string>("");
  const [mintInfo, setMintInfo] = useState<string>("");
  const [mintInformation, setMintInformation] = useState<MintingInformationResponse>({mint_cost: 0})

  const[itemsOwned, setItemsOwned] = useState<string[]>([])

  const [isErrorModalOpen, setIsErrorModalOpen] = useState<boolean>(false);
  const [errorTitle, setErrorTitle] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [onClickFunction, setOnClickFunction] = useState<"PUBLISH" | "MINT" | "DELETE" | "CONVERT" | "DELIST_ALL">("CONVERT")

  const navigate = useNavigate();


  useEffect(() => {
    if (collectionData){
      setCollection(collectionData)
    }
  }, [collectionData])

  useEffect(() => {
    if (needsUpdate)
    {
      setNeedsUpdate(false);
      setIsLoading(true);
      fetchShortItemsFromCollection(
          parseFloat(id),
          getCurrentUser(),
          status,
          priceOrder,
          traitsFilter,
          nameFilter,
          minPrice,
          maxPrice
      ).then((value) =>{
        setCollectionItems(value.items);
        setNextIndex(value.next_index);
        console.log("ok")
        console.log(collectionItems)
        console.log("ent")
        setIsLoading(false)
      });
    }
  }, [collectionItems, id, maxPrice, minPrice, nameFilter, needsUpdate, priceOrder, status, traitsFilter])

  useEffect(() => {
    document.getElementById('scrollTo')?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  function changePriceOrder() {
    switch(priceOrder){
      case "ASC":{
        setPriceOrder("DESC");
        break;
      }
      case "DESC":{
        setPriceOrder("ASC");
        break;
      }
    }
    if(nextIndex){
      setNeedsUpdate(true);
    }
  }

  async function loadMore() {
    if(nextIndex){
      setIsLoadingMore(true);
      let resp = await fetchShortItemsFromCollection(
          parseFloat(id),
          getCurrentUser(),
          status,
          priceOrder,
          traitsFilter,
          nameFilter,
          minPrice,
          maxPrice,
          nextIndex
      );
      setCollectionItems(collectionItems.concat(resp.items));
      setNextIndex(resp.next_index);
      setIsLoadingMore(false);
    }
  }

  function mintableItems(): ShortCollectionItem[] {
    let result: ShortCollectionItem[] = [];
    collectionItems.forEach(item => {
      if(!item.minted){
        result.push(item);
      }
    })
    return result;
  }

  async function handlePublishClick() {
    if(collection && !collection.address){
      // Check that some items have been uploaded
      if(collection.stats.items === 0){
        setErrorTitle("Empty collection")
        setErrorMessage("Please upload all the items for your collection before minting")
        setIsErrorModalOpen(true);
        return;
      }

      // Checks that all the data has been input
      if(collection.data.icon_url !== ""  && collection.data.name !=="" && collection.data.description !==""){
        setMintMessage("Are you sure you want to publish the collection?")
        setMintInfo("This will mint the resource setting all the possible traits name for your items. You won't be able to change this in the future. The collection will also become public (not minted items will remain private). ")
        setIsMintConfirmModalOpen(true);
        setOnClickFunction("PUBLISH");
      }
      else{
        setErrorTitle("Cannot publish collection")
        setErrorMessage("Make sure that you have defined a name, description, icon and info url!")
        setIsErrorModalOpen(true);
      }
    }
  }

  async function handleMintClick(){
    if(collection && collection.address){
      if(collection.is_minting){
        setIsRadixWalletModalOpen(false);
        setIsMintConfirmModalOpen(false);
        setErrorTitle("Items are already being minted")
        setErrorMessage("Please wait while the process finishes")
        setIsErrorModalOpen(true)
        return
      }
      let items = mintableItems();
      if(items.length >0){
        const mint_info = await getMintingInformation(collection.id);
        setMintInformation(mint_info);
        setMintMessage(`Are you sure you want to mint ${items.length} items?`)
        setMintInfo("After minting those items, you won't be able to modify their data anymore.")
        setOnClickFunction("MINT");
        setIsMintConfirmModalOpen(true);
      }
      else{
        setWalletStatus("error")
        setWalletTitle("No items to mint")
        setWalletMessage("Please create other items before trying to mint.")
        setIsRadixWalletModalOpen(true)
      }
    }
  }

  async function handleDeleteClick(){
    setMintMessage("Are you sure that you want to delete data for draft items ?")
    setMintInfo(`This will delete all of the draft items.`)
    setIsMintConfirmModalOpen(true);
    setOnClickFunction("DELETE")
  }

  async function handleConvertClick(){
    setWalletTitle("Fetching items owned");
    setWalletMessage("Please wait while we check.")
    setWalletStatus("loading");
    setIsRadixWalletModalOpen(true);
    let items_ids = await getIdsOwnedBy(getCurrentUser(), collection);
    setItemsOwned(items_ids.ids)

    if(items_ids.ids.length === 0){
      setErrorTitle("You don't own any items from this collection");
      setErrorMessage("")
      setIsErrorModalOpen(true);
      setIsRadixWalletModalOpen(false);
    }
    else{
      setMintMessage(`${items_ids.ids.length} items can be converted. Do you want to proceed?`)
      setMintInfo("");
      setOnClickFunction("CONVERT");
      setIsMintConfirmModalOpen(true);
      setIsRadixWalletModalOpen(false);
    }

  }

  async function handleDelistClick() {
    let items = collectionItems.filter(item =>{
        return item.owner === getCurrentUser() && item.proposal
    });

    if(items.length === 0){
      setErrorTitle("You don't have any items currently on sale");
      setErrorMessage("")
      setIsErrorModalOpen(true);
    }
    else{
      setMintMessage(`${items.length} items can be delisted. Do you want to proceed?`)
      setMintInfo("");
      setOnClickFunction("DELIST_ALL")
      setIsMintConfirmModalOpen(true);
    }
  }

  async function topButtonClicked(){
    switch (onClickFunction){
      case "PUBLISH": return await makePublish();
      case "MINT": return await makeMint();
      case "DELETE": return await makeDelete();
      case "CONVERT": return await makeConvert();
      case "DELIST_ALL": return await makeDelistAll();
    }
  }

  async function makePublish(){
    if(collection && !collection.address){
      setIsMintConfirmModalOpen(false);
      setIsRadixWalletModalOpen(true);
      setWalletStatus("loading")
      setWalletTitle("Publishing the collection")
      setWalletMessage("Minting the collection resource")

      let receipt = await mintCollectionResource(collection);

      switch (receipt.outcome){
        case "SUCCESS":{
          if(receipt.info.transaction_status === "CommittedSuccess"){
            collection.address = getNewResourceFromTransaction(receipt.info);
            await updateCollection(collection);
            await publishCollection(collection);
            setWalletStatus("success")
            setRefreshOnSuccess(true);
            setWalletTitle("Collection published successfully!")
            setWalletMessage("You can still edit and create new items.")
          }
          break;
        }
        case "FAILED":{
          setWalletStatus("error")
          setWalletTitle("Transaction failed!")
          setWalletMessage(`Transaction failed with error: ${receipt.error}`);
          break;
        }

        case "TIMEOUT":{
          setWalletStatus("error");
          setWalletTitle("Unable to get answer from wallet!")
          setWalletMessage("Interaction with wallet has timed out")
        }
      }
    }
  }

  async function makeMint(){
    if(collection && collection.address){
      let items = mintableItems();
      if(items.length > 0){

        setWalletStatus("loading");
        setWalletTitle("Please wait while we are making some checks")
        setWalletMessage("We are checking that the minting process can be done successfully")
        setIsMintConfirmModalOpen(false);
        setIsRadixWalletModalOpen(true);

        // We First check that we can airdrop to the user

        const can_airdrop = await canDeposit(getCurrentUser());
        if(!can_airdrop)
        {
          setIsRadixWalletModalOpen(false)
          setIsErrorModalOpen(true)
          setErrorTitle("Cannot mint collection")
          setErrorMessage("Please make sure to allow deposit to your account for the minting process.")
          return;
        }

        // We then check that the user has an trader card
        let nft_card = await getNFTTraderCard(radland_trader_card);
        if (!nft_card) {
          setWalletStatus("loading");
          setWalletTitle("Minting a trader NFT card")
          setWalletMessage(`This NFT is needed to put items on sale`)
          try {
            let result = await mintNFTCard();
            if (result.receipt.transaction_status === "CommittedSuccess") {
              setWalletStatus("success");
              setRefreshOnSuccess(false);
              setWalletTitle("Trader card minted successfully")
              setWalletMessage("You can now sell your items.")
              nft_card = result.nft;
            } else {
              setWalletStatus("error")
              setWalletTitle("Transaction failed!")
              setWalletMessage(`Transaction failed with error: ${result.receipt.error_message}`);
              return
            }
          } catch (error) {
            setWalletStatus("error")
            setWalletTitle("Transaction rejected by wallet")
            // @ts-ignore
            let error_message = error.toString();
            setWalletMessage(`Transaction rejected: ${error_message}`)
            return;
          }
        }

        if(nft_card){
          // We can now make the deposit to mint the items
          setWalletStatus("loading");
          setWalletTitle("Please sign deposit to proceed to minting.")
          setWalletMessage(`This deposit is needed to mint items and send your NFTs to your wallet. Unused fees will be refunded.`)
          let deposit_request = await makeDeposit(getCurrentUser(), mintInformation.mint_cost, `We need a ${mintInformation.mint_cost} XRD deposit to mint your items. Extra funds will be refunded.`, xrd_address, radland_backend_account);
          switch (deposit_request.outcome){
            case "SUCCESS":{
              break;
            }
            case "FAILED":{
              setWalletStatus("error");
              setWalletTitle("Deposit failed!");
              setWalletMessage(`Deposit failed with error:${deposit_request.error}`);
              return;
            }
            case "TIMEOUT":{
              setWalletStatus("error");
              setWalletTitle("Unable to get answer from wallet!")
              setWalletMessage("Interaction with wallet has timed out")
              return;
            }
          }

          //
          setWalletStatus("loading");
          setWalletTitle("We are minting your items")
          setWalletMessage("The deposit has been received, we are now minting your NFTs, putting relevant items on sale and depositing the NFTs that you own. Please be patient during the process (can take up to 30 minutes). If you leave the page, you will still receive what you are due.")
          try{

            // We set minting to avoid double-minting even if user does not reload page
            collection.is_minting = true;

            let result = await mintCollection(collection, nft_card);
            if(result.outcome === "SUCCESS"){
              setWalletStatus("success");
              setRefreshOnSuccess(true);
              setWalletTitle("Collection minted successfully!")
              setWalletMessage(`${result.items_minted.length} items have been minted and you have been refunded ${result.refunded} XRD`)
            }
            else{
              setWalletStatus("error")
              if(result.outcome === "PARTIAL SUCCESS"){
                setWalletTitle("Some items have not been minted and airdropped!")
                setWalletMessage("Something went wrong in the process, please contact an administrator!");
              }
              else{
                setWalletTitle("Could not mint any items!")
                setWalletMessage("Something went wrong in the process, please contact an administrator!");
              }
              return;
            }
          }catch (err){
            setWalletStatus("error")
            setWalletTitle("Something went wrong!")
            // @ts-ignore
            let error_message = err.toString();
            setWalletMessage(`Attempt to mint failed with: ${error_message}. Please contact an administrator.`);
            return;
          }
        }
        else{
          setWalletStatus("error")
          setWalletTitle("Something went wrong")
          setWalletMessage("Cannot find or mint a NFT card. Please contact an administrator")
        }
      }
    }
  }

  async function makeDelete(){
    setIsMintConfirmModalOpen(false);
    setWalletMessage("")
    setWalletTitle(`Deleting  draft items`)
    setWalletStatus("loading")
    setLoadingMessage("Please wait while we delete them and recompute traits statistics")
    setIsRadixWalletModalOpen(true);
    try{
      await deleteAllItems(collection.id);
      setWalletTitle("Items deleted successfully")
      setRefreshOnSuccess(true);
      setLoadingMessage(undefined);
      setWalletMessage("")
      setWalletStatus("success")
    }catch (err){
      setErrorTitle("Could not delete items")
      // @ts-ignore
      setErrorMessage(`Failed with: ${err.toString()}`)
      setIsRadixWalletModalOpen(false);
      setIsErrorModalOpen(true);
    }
  }

  async function makeConvert(){
    let items_batch: string[][] = [];
    let amount_per_batch = 200;
    let amount_of_batch = Math.ceil(itemsOwned.length / amount_per_batch);

    for (let i = 0; i < amount_of_batch; i++) {
      const end_index = Math.min((i + 1) * amount_per_batch, itemsOwned.length);
      const new_batch = itemsOwned.slice(i * amount_per_batch, end_index);
      items_batch.push(new_batch)
    }

    setWalletTitle(`Converting your ${itemsOwned.length} items in ${amount_of_batch} transactions`);
    setIsRadixWalletModalOpen(true);
    setIsMintConfirmModalOpen(false);

    let batch_number = 1;
    for(let batch of items_batch){
      setWalletMessage(`Transaction ${batch_number}/${amount_of_batch}`);
      let res = await sendNFTs(getCurrentUser(), radland_backend_account, batch, collection);
      switch (res.outcome) {
        case "SUCCESS": break;
        case "TIMEOUT":{
          setWalletStatus("error");
          setWalletTitle("Unable to get answer from wallet!")
          setWalletMessage(`Interaction with wallet has timed out. Only ${amount_per_batch * (batch_number-1)} items were converted.`)
          return;
        }
        case "FAILED":{
          setWalletStatus("error")
          setWalletTitle("Something went wrong")
          setWalletMessage(`Transaction failed or was rejected by wallet. Only ${amount_per_batch * (batch_number-1)} items were converted.`)
          return;
        }
      }
      batch_number+=1;
    }

    setWalletStatus("success")
    setWalletTitle("All items were converted")
    setWalletMessage("")
  }

  async function makeDelistAll(){
    let items = collectionItems.filter(item =>{
      return item.owner === getCurrentUser() && item.proposal
    });

    let trader_card = await getNFTTraderCard(radland_trader_card);

    if(!trader_card){
      setErrorTitle("You do not have an NFT trader card");
      setErrorMessage("Cannot delist proposals without the correct trader card");
      setIsErrorModalOpen(true);
    }
    else{
      setWalletTitle(`Delisting ${items.length} items`);
      setWalletStatus("loading");
      setIsRadixWalletModalOpen(true);

      let result = await rescindProposals(getCurrentUser(), kaupa_component, trader_card, items);
      switch (result.outcome) {
        case "SUCCESS": {
          setWalletStatus("success");
          setWalletTitle("Successfully delisted items");
          setWalletMessage("");
          return;
        }
        case "TIMEOUT":{
          setWalletStatus("error");
          setWalletTitle("Unable to get answer from wallet!")
          setWalletMessage(`Interaction with wallet has timed out.`)
          return;
        }
        case "FAILED":{
          setWalletStatus("error")
          setWalletTitle("Something went wrong")
          setWalletMessage(`Transaction failed or was rejected by wallet.`)
          return;
        }
      }
    }

  }

  const tabItems: TabAlt[] = [
    {
      id: 0,
      label: "Items",
      content: (
        <ItemsList
          tabId={0}
          items={collectionItems}
          setNeedsUpdate={setNeedsUpdate}
          setNameFilter={setNameFilter}
          setMinPrice={setMinPrice}
          setMaxPrice={setMaxPrice}
          changePriceOrder={changePriceOrder}
          setStatus={setStatus}
          setTraitsFilter={setTraitsFilter}
          loadMore={loadMore}
          isLoading={isLoading}
          isLoadingMore={isLoadingMore}
          traitsStats={ collection ? collection.stats.traits_stats : {}  }
          role={ collection ? (collection.owner === getCurrentUser() ? "creator": "public"): "public"  }
        />
      ),
    }
  ];

  return (
    <div className="w-full min-h-screen bg-white">
      {collection ? collection.owner === getCurrentUser() ? (
        <div className="flex items-center justify-center flex-col px-4 md:flex-row my-4 gap-3">
          {
            collection.address ? (
                mintableItems().length > 0 ? (
                    <button
                        onClick={handleMintClick}
                        className="w-[302px] px-[24px] py-[16px] font-medium text-white bg-[#535AFA] flex items-center justify-center rounded-lg"
                    >
                      {"Mint items"}
                    </button>
                ) : null
            ): (
                <button
                    onClick={handlePublishClick}
                    className="w-[302px] px-[24px] py-[16px] font-medium text-white bg-[#535AFA] flex items-center justify-center rounded-lg"
                >
                  {"Publish Collection"}
                </button>
                )
          }

          <button
              onClick={() => navigate('/collections/'+id+'/upload-json')}
              className="w-[302px] px-[24px] py-[16px] font-medium border border-[#F3F3F3] flex items-center justify-center rounded-lg"
          >
            Upload New Items
          </button>

          {
            mintableItems().length > 0 ? (
                <button
                    onClick={handleDeleteClick}
                    className="w-[302px] px-[24px] py-[16px] font-medium text-white bg-[#E23434] flex items-center justify-center rounded-lg"
                >
                  Delete Draft Items
                </button>
            ): null
          }

          {
            collection.id === 58 ? (
                <div>
                  <button
                      onClick={handleConvertClick}
                      className="w-[302px] px-[24px] py-[16px] font-medium text-white bg-[#535AFA] flex items-center justify-center rounded-lg"
                  >
                    {"Convert items"}
                  </button>
                  <button
                      onClick={handleDelistClick}
                      className="w-[302px] px-[24px] py-[16px] font-medium border border-[#F3F3F3] flex items-center justify-center rounded-lg"
                  >
                    {"Delist all items"}
                  </button>
                </div>
            ): null
          }
        </div>
      ): (
          collection.id === 58 ? (
              <div className="flex items-center justify-center flex-col px-4 md:flex-row my-4 gap-3">
                <button
                    onClick={handleConvertClick}
                    className="w-[302px] px-[24px] py-[16px] font-medium text-white bg-[#535AFA] flex items-center justify-center rounded-lg"
                >
                  {"Convert items"}
                </button>
                <button
                    onClick={handleDelistClick}
                    className="w-[302px] px-[24px] py-[16px] font-medium border border-[#F3F3F3] flex items-center justify-center rounded-lg"
                >
                  {"Delist all items"}
                </button>
              </div>
          ): null
      ) : null }

      <div id="scrollTo" className="relative">
        { isCollectionDetailsLoading ? (
          <SkeletonTheme baseColor="#d6d4d4" highlightColor="#edebeb">
            <Skeleton height={249} />
          </SkeletonTheme>
        ) : (
          <img
          src={collection ? collection.background_image !== "" ? collection.background_image: mockCollectionBanner : mockCollectionBanner}
          alt=""
          className="w-full h-[249px] object-cover"
        />
        )}
        { isCollectionDetailsLoading ? (
          <div className="absolute left-[24px] md:left-[32px] xl:left-[77px] -bottom-[60px] w-[120px] h-[120px]">
           <SkeletonTheme baseColor="#d6d4d4" highlightColor="#edebeb">
           <Skeleton className="rounded-full border-4 border-white border-solid" height={120} width={120} circle={true} />
         </SkeletonTheme>
         </div>
        ) : (
          <img
          src={collection ? collection.data?.icon_url !== "" ? collection.data?.icon_url: mockCollectionProfile : mockCollectionProfile}
          alt=""
          className="absolute object-cover left-[24px] md:left-[32px] xl:left-[77px] -bottom-[60px] w-[120px] h-[120px] rounded-full border-4 border-white border-solid"
        />
        )

        }
      </div>

      <div className="mt-[66px] px-4 md:px-8 lg:px-[48px] xl:px-[77px]">
        {/* collection info */}
        <div>
          <div className="flex flex-col lg:flex-row items-center justify-between">
          { !isCollectionDetailsLoading &&
            <h2 className="text-[32px] font-bold flex items-center gap-x-2">
              {collection ? collection.data?.name : "loading"}
              {collection ? collection.owner === getCurrentUser() && collection.address === undefined ? (
                <button className="rounded-lg bg-[#535AFA] w-[50px] h-[32px] text-xs text-white cursor-text">
                  Draft
                </button>
              ) : (
                <VerifiedIcon />
              ): ""}
            </h2>
          }

            {/* cta btn */}
            {collection ? collection.owner  === getCurrentUser() && collection.address === undefined ? (
                <button className="flex items-center gap-x-2 px-4 py-2 rounded-[10px] border border-solid border-[#F3F3F3] my-2 lg:my-0"
                        onClick={() => {navigate(`/collections/edit/${id}`)}}>

                  <>
                    <PencilIcon />
                    <span className="text-sm font-medium">Edit details</span>
                  </>
                </button>) : null : null}
          </div>

          {/* owner and wallet address */}
          <div className="my-2 flex items-center gap-x-4">
            {isCollectionDetailsLoading ? (
              <Skeleton width={1000} height={24}/>
            ) : (
            <>
            <p>
              By <span className="font-semibold">{collection ? collection.owner : "loading"}</span>
            </p>
            {
              collection ? collection.address ? (
                  <WalletAddress walletAddress={collection.address} />
                  ) : null : null
            }
            </>
            )}
            
          </div>

          {/* social media links */}
          <div className="flex items-center gap-x-3">
            {
              collection ? collection.data?.info_url !== "" ? (
                  <a href={collection.data?.info_url} target="_blank" rel="noopener noreferrer">
                    <WebsiteIcon className="w-[24px] h-[24px]" />
                  </a>
              ): null : null
            }
            {
              collection ? collection.twitter_url ? (
                  <a href={`https://twitter.com/${collection.twitter_url}`} target="_blank" rel="noopener noreferrer">
                    <TwitterIcon className="w-[24px] h-[24px]" />
                  </a>
              ): null : null
            }
            {
              collection ? collection.instagram_url ? (
                  <a href={`https://instagram.com/${collection.instagram_url}`} target="_blank" rel="noopener noreferrer">
                    <InstaIcon className="w-[24px] h-[24px]" />
                  </a>
              ): null : null
            }
            {
              collection ? collection.discord_url ? (
                  <a href={`https://discord.com/{${collection.discord_url}`} target="_blank" rel="noopener noreferrer">
                    <DiscordIcon className="w-[24px] h-[24px]" />
                  </a>
              ): null : null
            }
          </div>

          {/* description */}
          {isCollectionDetailsLoading ? (
            <Skeleton width={500} height={24} />
          ) : ( 
          <>
            <p className="mt-6 text-sm text-[#93989A] max-w-[860px]">
                {!isDescriptionExtended && collection?.data?.description.length > 250 ?
                  collection?.data?.description.substring(0, 250) + '...' : collection?.data?.description}
            </p>
            { collection?.data?.description.length > 250 &&
                <span onClick={() => setIsDescriptionExtended(!isDescriptionExtended)} className="text-[#535AFA] text-sm font-medium cursor-pointer pt-2">
                  {isDescriptionExtended ? 'Hide' : 'Read more'}
                </span>
            }
          </>
          )}

          {/* collection stats */}
          <CollectionStats
            items={collection?.stats?.items}
            owners={collection?.stats?Object.keys(collection.stats.owners).length: undefined}
            volume={collection?.stats?.volume}
            floorPrice={collection?.stats?.floor_price}
            fee={'0%'}
            isLoading={isCollectionDetailsLoading}
          />
        </div>

        {/* collection items */}
        <div>
        <TabsAlt
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tabs={tabItems}
          />
        </div>
      </div>

      {/* mint confirm modal */}
      <MintConfirmModal
        isOpen={isMintConfirmModalOpen}
        setIsOpen={setIsMintConfirmModalOpen}
        onClick={topButtonClicked}
        titleMessage={mintMessage}
        infoMessage={mintInfo}
      />
      <RadixWalletModal
          message={walletMessage}
          title={walletTitle}
          status={walletStatus}
          isOpen={isRadixWalletModalOpen}
          setIsOpen={setIsRadixWalletModalOpen}
          refreshOnSuccess={refreshOnSuccess}
          loadingMessage={loadingMessage}
      />
      <ErrorModal
          isOpen={isErrorModalOpen}
          setIsOpen={setIsErrorModalOpen}
          message={errorMessage}
          title={errorTitle}
      />
    </div>
  );
}
