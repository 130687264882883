import { callPostEndpoint } from "../Utils";
import {
  GetCollectionItemsNamesResponse,
  GetShortCollectionsResponse,
} from "../../types/response/Collection";
import {
  GetCollectionItemsNamesRequest,
  GetShortCollectionsRequest,
} from "../../types/requests/Collection";
import { backend_url } from "../../Constants";
import client from "../client";
import { NFTCollection } from "../../types/Collection";
import { getRolaToken } from "../Rola";

export async function getCollectionsOwner(owner: string) {
  try {
    let response;
    response = await fetch(
      backend_url +
        "/getCollectionsByOwner?owner=" +
        owner +
        "&authenticationToken=" +
        getRolaToken(),
    );
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error; // Rethrow the error for handling by the caller
  }
}

export async function getCollectionById(id: number) {
  try {
    let response;
    response = await fetch(
      backend_url +
        "/getCollectionById?id=" +
        id +
        "&authenticationToken=" +
        getRolaToken(),
    );
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error; // Rethrow the error for handling by the caller
  }
}

export async function getCollectionItemsNames(
  collection: NFTCollection,
): Promise<GetCollectionItemsNamesResponse> {
  const authenticationToken = getRolaToken();
  if (!authenticationToken) {
    console.error("You are not authenticated");
    return { items_name: [] };
  } else {
    let req: GetCollectionItemsNamesRequest = {
      authentication_token: authenticationToken,
      collection: collection,
    };
    return await callPostEndpoint("/getCollectionItemsNames", req);
  }
}

const fetchCollectionDetails = async (id: string) => {
  const response = await client.get(
    `/getCollectionById?id=${id}&authenticationToken=${getRolaToken()}`,
  );
  return response.data;
};

const fetchCollections = async (category?: string) => {
  const response = await client.get(
    `/getCollections${category ? `?category=${category}` : ""}`,
  );
  return response.data;
};

const fetchRecentlyAddedCollections = async (category?: string) => {
  const response = await client.get(
    `/getLatestCollections${category ? `?category=${category}` : ""}`,
  );
  return response.data;
};

const fetchTrendingCollections = async (category?: string) => {
  const response = await client.get(
    `/getTrendingCollections${category ? `?category=${category}` : ""}`,
  );
  return response.data;
};

const fetchCollectionsSlider = async () => {
  const response = await client.post(`/getShortCollections`);
  return response.data;
};

const fetchUserNfts = async (userAddress: string) => {
  const response = await client.get(
    `/getItemsByOwner?ownerAddress=${userAddress}`,
  );
  return response.data;
};
const actions = {
  fetchCollectionDetails,
  fetchCollections,
  fetchRecentlyAddedCollections,
  fetchTrendingCollections,
  fetchCollectionsSlider,
  fetchUserNfts,
};

export default actions;

export async function getShortCollections(): Promise<GetShortCollectionsResponse> {
  let request: GetShortCollectionsRequest = undefined;
  return await callPostEndpoint("/getShortCollections", request);
}
