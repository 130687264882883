import { ResourceAddress } from "./types/Radix";

// Check for all necessary environment variables
if (!process.env.REACT_APP_BACKEND_URL) {
    throw new Error("BACKEND_URL is not set");
}
if (!process.env.REACT_APP_DAPP_DEFINITION) {
    throw new Error("DAPP_DEFINITION is not set");
}
if (!process.env.REACT_APP_FRONTEND_URL) {
    throw new Error("FRONTEND_URL is not set");
}
if (!process.env.REACT_APP_NETWORK_ID) {
    throw new Error("NETWORK_ID is not set");
}
if (!process.env.REACT_APP_RADLAND_ADMIN_BADGE) {
    throw new Error("RADLAND_ADMIN_BADGE is not set");
}
if (!process.env.REACT_APP_BACKEND_ACCOUNT) {
    throw new Error("BACKEND_ACCOUNT is not set");
}
if (!process.env.REACT_APP_KAUPA_COMPONENT) {
    throw new Error("KAUPA_COMPONENT is not set");
}
if (!process.env.REACT_APP_RADLAND_TRADER_CARD) {
    throw new Error("RADLAND_TRADER_CARD is not set");
}

/**
 * Backend's URL without the '/' at the end
 * Example: https://radland-backend-chi.vercel.app
 */
export const backend_url = process.env.REACT_APP_BACKEND_URL;

/**
 * Address of the dApp definition account.
 */
export const dapp_definition_address = process.env.REACT_APP_DAPP_DEFINITION;

/**
 * Frontend's url address.
 */
export const website_url = process.env.REACT_APP_FRONTEND_URL;

/**
 * Network ID on which the frontend will run.
 * Mainnet: 1
 * Stokenet: 2
 */
export const network_id: number = parseInt(process.env.REACT_APP_NETWORK_ID || "2");

export const badge_image_url: string = "https://www.radland.io/RadLandBadge.png";

/**
 * Address of the XRD resource.
 * Mainnet: resource_rdx1tknxxxxxxxxxradxrdxxxxxxxxx009923554798xxxxxxxxxradxrd
 * Stokenet: resource_tdx_2_1tknxxxxxxxxxradxrdxxxxxxxxx009923554798xxxxxxxxxtfd2jc
 */
export const xrd_address: ResourceAddress = network_id === 1 ? "resource_rdx1tknxxxxxxxxxradxrdxxxxxxxxx009923554798xxxxxxxxxradxrd" : "resource_tdx_2_1tknxxxxxxxxxradxrdxxxxxxxxx009923554798xxxxxxxxxtfd2jc";

/**
 * Address of the backend account that makes automated transactions.
 */
export const radland_backend_account = process.env.REACT_APP_BACKEND_ACCOUNT;

/**
 * Address of the Kaupa package.
 */
// const kaupa_package_address = "package_rdx1pkyamrxffty33p7zg0cfk7mgcc6v9vshe84e0eg73w44y6w7zgfvn8";
// Stokenet package_tdx_2_1pku2jdq075279jr8eaqdxmmt5fqh4flzaq6uavwvyy06gemtllha8a

/**
 * Address of the admin badge detained by the backend wallet that will enable it to mint collections.
 */
export const radland_admin_badge = process.env.REACT_APP_RADLAND_ADMIN_BADGE;

/**
 * Address of the kaupa component.
 */
export const kaupa_component = process.env.REACT_APP_KAUPA_COMPONENT;

/**
 * Address of the RadLand trader card resource.
 */
export const radland_trader_card = process.env.REACT_APP_RADLAND_TRADER_CARD;

// TODO: use our own Mainnet gateway?
export const gateway_url = () =>{
    if(network_id === 1){
        return "https://mainnet.radixdlt.com/"
    }
    else{
        if(network_id === 2){
            return "https://babylon-stokenet-gateway.radixdlt.com/"
        }
        else{
            throw new Error("Wrong configuration")
        }
    }
}

export const dashboard_address = () => {
    if(network_id === 1){
        return "https://dashboard.radixdlt.com/"
    }
    else{
        return "https://stokenet-dashboard.radixdlt.com/"
    }
}
