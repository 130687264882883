import { CollectionItem, ShortCollectionItem } from "../../types/Collection";
import { callPostEndpoint } from "../Utils";
import {
  CreateItemsRequest,
  DeleteAllItemsRequest,
  DeleteItemsRequest,
  UpdateItemRequest,
  UpdateItemsRequest,
} from "../../types/requests/Item";
import {
  CreateItemsResponse,
  DeleteItemsResponse,
  UpdateItemsResponse,
} from "../../types/response/Item";
import { getRolaToken } from "../Rola";

export async function updateItem(item: CollectionItem) {
  const authentication_token = getRolaToken();
  if (!authentication_token) {
    console.error("You are not authenticated");
  } else {
    let request: UpdateItemRequest = {
      authentication_token: authentication_token,
      item: item,
    };
    await callPostEndpoint("/updateItem", request);
  }
}

export async function createItems(items: CollectionItem[]) {
  const authentication_token = getRolaToken();
  if (!authentication_token) {
    console.error("You are not authenticated");
  } else {
    //Send by batches of 500 items
    let nb_batch = Math.ceil(items.length / 500);

    for (let i = 0; i < nb_batch; i++) {
      const end_index = Math.min((i + 1) * 500, items.length);
      const batch = items.slice(i * 500, end_index);
      let request: CreateItemsRequest = {
        authentication_token: authentication_token,
        items: batch,
      };
      await callPostEndpoint<CreateItemsRequest, CreateItemsResponse>(
        "/createItems",
        request,
      );
    }
  }
}

export async function deleteItems(items: ShortCollectionItem[]) {
  const authentication_token = getRolaToken();
  if (!authentication_token) {
    console.error("You are not authenticated");
  } else {
    let request: DeleteItemsRequest = {
      authentication_token: authentication_token,
      items: items,
    };
    await callPostEndpoint<DeleteItemsRequest, DeleteItemsResponse>(
      "/deleteItems",
      request,
    );
  }
}

export async function deleteAllItems(collection_id: number) {
  const authentication_token = getRolaToken();
  if (!authentication_token) {
    console.error("You are not authenticated");
  } else {
    let request: DeleteAllItemsRequest = {
      authentication_token: authentication_token,
      collection_id: collection_id,
    };
    await callPostEndpoint<DeleteAllItemsRequest, DeleteItemsResponse>(
      "/deleteAllItems",
      request,
    );
  }
}
