import { getCurrentUser, id_to_url } from "../../ledger/Global";
import { NFTCollection, CollectionItem } from "../../types/Collection";
import ActionButtonsSection from "./ActionButtonsSection";
import { ReactComponent as PencilIcon } from "../../assets/global/pencil_icon.svg";
import NFTImage from "./NFTImage";
import PersonaCard from "./PersonaCard";
import React, { useEffect, useState } from "react";
import profileImage from "../../assets/default/Icon-Artist.jpg";
import { useLocation, useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

const NFTInformationSection: React.FC<{
  item: CollectionItem | undefined;
  collection: NFTCollection | undefined;
  isLoading: boolean;
}> = ({ item, collection, isLoading }) => {
  const location = useLocation();
  const navigate = useNavigate();
  let id = location.pathname.split("/")[3];
  let collection_id = location.pathname.split("/")[2];

  return (
    <div className="w-full flex flex-col lg:flex-row">
      <div className="w-full lg:w-[40%]">
        {isLoading ? (
          <Skeleton height={412} />
        ) : (
          <NFTImage url={item ? item.item_data.image_url : ""} />
        )}
      </div>
      <div className="w-full lg:w-[60%] text-left flex flex-col px-[40px]">
        {isLoading ? (
          <>
            <Skeleton style={{ marginBottom: "10px" }} height={32} />
            <Skeleton height={24} />
          </>
        ) : (
          <>
            <p className="mt-[12px] lg:mt-0 text-[24px] lg:text-[32px] font-semibold">
              {item ? item.item_data.name : "loading"}
            </p>
            <p className="text-[18px] lg:text-[24px] font-semibold mt-2">
              {item && item.proposal
                ? item.proposal.payment.amount + " XRD"
                : "Not for sale"}
            </p>
          </>
        )}

        <div className="flex flex-col lg:flex-row pt-auto lg:pt-[40px] pb-[20px] border-b-[2px] border-grey-light">
          <PersonaCard
            label="Owned by"
            title={item ? item.owner : ""}
            isLoading={isLoading}
            imgUrl={profileImage}
          />
        </div>
        <div className="flex flex-col lg:flex-row pt-auto lg:pt-[40px] pb-[20px] border-b-[2px] border-grey-light">
          <PersonaCard
            label="Collection"
            isLoading={isLoading}
            title={collection ? collection.data.name : "loading"}
            imgUrl={collection ? collection.data.icon_url : ""}
            link={collection ? `/collections/${collection_id}` : undefined}
          />
        </div>
        <div className="mt-auto">
          {item && collection ? (
            collection.owner === getCurrentUser() && !item.minted ? (
              <button
                className="flex items-center gap-x-2 px-4 py-2 rounded-[10px] border border-solid border-[#F3F3F3] my-2 lg:my-0"
                onClick={() => {
                  navigate(
                    `/collections/${collection_id}/${id_to_url(id)}/edit`,
                  );
                }}
              >
                <>
                  <PencilIcon />
                  <span className="text-sm font-medium">Edit details</span>
                </>
              </button>
            ) : null
          ) : null}
          <div className="pt-[40px]">
            {item ? (
              item.proposal ? (
                <ActionButtonsSection item={item} collection={collection} />
              ) : null
            ) : null}
            {item ? (
              !item.proposal && item.owner === getCurrentUser() ? (
                <ActionButtonsSection item={item} collection={collection} />
              ) : null
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NFTInformationSection;
