import { useNavigate } from "react-router";
import {CollectionItem, ShortCollectionItem} from "../../../types/Collection";
import {id_to_url} from "../../../ledger/Global";

interface NftItemProps {
  item: ShortCollectionItem;
  setIsListForSaleModalOpen?: (isOpen: boolean) => void;
  role?: "owner" | "public" | "creator";
}
export default function NftItem({
                                    item,
                                    role,
                                }: NftItemProps) {
    const navigate = useNavigate();
    return (
        <div className="w-full max-h-fit p-2 pb-4 shadow-sm bg-white rounded-xl flex flex-col group cursor-pointer">
            <div onClick={() => navigate(`/collections/${item.collection_id}/${id_to_url(item.id)}`)}
                 className="overflow-hidden rounded-xl">
                <img
                    src={item.image_url}
                    alt=""
                    className="w-full h-auto aspect-square rounded-xl object-cover group-hover:scale-110 transition-all duration-200 ease-in-out"
                />
            </div>
            <p className="mt-3 font-bold">{item.name}</p>
            <div className="mt-auto  flex justify-between items-center">
                { item.proposal ?  (  <div>
                    <p className="text-[15px] text-[#93989A]">Price</p>

                    <div className="flex items-center">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="30"
                            height="30"
                            viewBox="0 0 18 18"
                            fill="none"
                        >
                            <path
                                d="M7.13837 15C6.87277 15 6.62029 14.8774 6.46195 14.6664L3.15603 10.2409H1V8.62973H3.58253C3.85069 8.62973 4.10189 8.75356 4.25888 8.96337L6.96045 12.5788L11.0828 3.48275C11.2162 3.18954 11.5158 3 11.8468 3H17V4.61121H12.3923L7.9024 14.5173C7.78202 14.7826 7.52429 14.9646 7.22598 14.9949C7.19853 14.9987 7.16845 15 7.13837 15Z"
                                fill="#535AFA"
                            />
                        </svg>

                        <span className="text-sm font-medium ml-[6px]">
              {item.proposal.payment.amount}
            </span>
                    </div>
                </div>) : null}
            </div>
        </div>
    );
}
