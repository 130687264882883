const CollectionsCheckboxList: React.FC<{
    collections: Array<{collection: string, isActive: boolean}>
    onCollectionsClick: (collectionName: any) => void,
}> = ({onCollectionsClick, collections}) => {
    return (
      <div className={`w-full flex flex-col mb-4 bg-white rounded-xl border-grey-medium shadow-sm px-4 mr-4 gap-5 py-4`}>
        {collections.map((item: any, index) => (
           <div key={index} className="w-full flex justify-between items-center">
              <p className='text-black-base text-[18px] text-semibold mr-6'>{item.collection}</p>
              <div className="flex items-center justify-center w-4 h-4 border border-solid border-neutral-300 rounded-full"
                onClick={() => onCollectionsClick(item.collection)}>
                {item.isActive && <div className="w-2 h-2 bg-black-base rounded-full" />}
              </div>
            </div>
        ))}
      </div>
    )
}

export default CollectionsCheckboxList;