import {backend_url} from "../Constants";

export async function callGetEndpoint<RequestType, ResponseType>(endpoint: string, request: RequestType): Promise<ResponseType> {
    try {
        const response = await fetch(backend_url + endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json', // Set the appropriate content type
            },
            body: JSON.stringify(request)
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        try {
            return await response.json() as ResponseType;
        } catch (err) {
            throw new Error(`Wrong type returned!`);
        }
    } catch (err) {
        throw new Error(`${err}`);
    }
}

export async function callPostEndpoint<RequestType, ResponseType>(endpoint: string, request: RequestType): Promise<ResponseType> {
    try {
        console.log(endpoint);
        const response = await fetch(backend_url + endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json', // Set the appropriate content type
            },
            body: JSON.stringify(request)
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        try {
            return await response.json() as ResponseType;
        } catch (err) {
            console.error("Could not parse in the wrong type")
            // @ts-ignore
            return undefined;
        }
    } catch (err) {
        throw new Error(`${err}`);
    }
}