import { getCurrentUser } from "../ledger/Global";
import { CheckRolaTokenRequest } from "../types/requests/Rola";
import { callPostEndpoint } from "./Utils";
import { CheckRolaTokenResponse } from "../types/response/Rola";
import { network_id } from "../Constants";

export function getRolaToken(): string | null {
  return localStorage.getItem(getRolaTokenName());
}

export async function isRolaTokenValid(): Promise<boolean> {
  let token = getRolaToken();
  console.log(`Checking token ${token}`);
  if (token !== null) {
    let request: CheckRolaTokenRequest = {
      account: getCurrentUser(),
      token: token,
    };
    let resp: CheckRolaTokenResponse = await callPostEndpoint(
      "/check-token",
      request,
    );
    console.log("Token is valid: ", resp.is_valid);
    return resp.is_valid;
  } else {
    return false;
  }
}

export function deleteToken() {
  localStorage.removeItem(getRolaTokenName());
}

export function setRolaToken(token: string) {
  return localStorage.setItem(getRolaTokenName(), token);
}

function getRolaTokenName(): string {
  return "radland-auth-token-" + network_id;
}
