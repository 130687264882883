import { useEffect, useRef, useState } from "react";

interface TagsProps {
  options: string[];
  tags: string[];
  setTags: React.Dispatch<React.SetStateAction<string[]>>;
}

export default function Tags({ options, tags, setTags }: TagsProps) {
  const [inputValue, setInputValue] = useState("");
  const [suggestions, setSuggestions] = useState<string[]>([]);

  const inputRef = useRef<HTMLInputElement>(null);
  const suggestionsRef = useRef<HTMLDivElement>(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);
    setSuggestions(
      options.filter(
        (option) =>
          option.toLowerCase().includes(value.toLowerCase()) &&
          !tags.includes(option)
      )
    );
  };

  const handleTagAdd = () => {
    if (tags.length >= 5) return;
    // check if tag already exists, if not add it
    if (inputValue && !tags.includes(inputValue)) {
      setTags([...tags, inputValue]);
      setInputValue("");
    } else {
      setInputValue("");
    }
  };

  const handleTagRemove = (tag: string) => {
    setTags(tags.filter((t) => t !== tag));
  };

  // on outside click, close suggestions
  const handleClickOutside = (e: MouseEvent) => {
    if (
      suggestionsRef.current &&
      !suggestionsRef.current.contains(e.target as Node)
    ) {
      setSuggestions([]);
    }
  };

  // add event listener on mount
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="flex space-x-2 items-center w-full px-[20px] py-[16px] border border-solid border-[#F3F3F3] rounded-lg">
      <div className="flex space-x-2">
        {tags.map((tag) => (
          <div
            key={tag}
            className="flex items-center px-[10px] py-1 rounded-lg bg-[#F3F3F3]"
          >
            <span>{tag}</span>
            <button
              className="ml-1 text-red-500"
              onClick={() => handleTagRemove(tag)}
            >
              &times;
            </button>
          </div>
        ))}
      </div>
      <div className="relative w-full">
        <input
          type="text"
          className="w-full placeholder:text-[#A6A9B9]"
          ref={inputRef}
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={(e) => e.key === "Enter" && handleTagAdd()}
          placeholder="(Optional) Add up to 5 tags"
        />
        {suggestions.length > 0 && (
          <div
            className="absolute left-0 w-full mt-2 bg-white border rounded-lg border-solid border-[#F3F3F3] shadow z-10"
            ref={suggestionsRef}
          >
            {suggestions.slice(0, 3).map((suggestion) => (
              <div
                key={suggestion}
                className="p-2 first:rounded-t-lg last:rounded-b-lg cursor-pointer hover:bg-[#F3F3F3] hover:text-purple-medium transition-all duration-150 ease-in-out"
                onClick={() => {
                  if (tags.length >= 5) return;
                  setTags([...tags, suggestion]);
                  setInputValue("");
                  setSuggestions([]);
                  inputRef.current?.focus();
                }}
              >
                {suggestion}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
