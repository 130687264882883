import { useQuery } from 'react-query';

import actions  from '../../../backend/collections/CollectionGetters';

const useFetchUserNfts = (userAddress:string) => {
  return useQuery(
    ['fetchUserNfts'],
    async () => {
      console.log(userAddress)
      const userNfts = await actions.fetchUserNfts(userAddress);
      return userNfts;
    },
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
    },
  );
};

export default useFetchUserNfts;