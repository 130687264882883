import {AccountAddress, NonFungibleLocalId, ResourceAddress} from "./Radix";
import {Proposal} from "./Kaupa";
import {NetworkId, RadixEngineToolkit} from "@radixdlt/radix-engine-toolkit";
import {xrd_address} from "../Constants";

export type Transaction = {
    id: NonFungibleLocalId,
    seller: string,
    buyer: string,
    date: Date,
    price_xrd: number,
}

export type Activity = {
    event: "Sale" | "Transfer" | "Listing" | "Minted" | "Burnt";
    price: number | null,
    from: string | null,
    to: string | null,
    time: number,
    transaction_id: string
};

export type CollectionStats = {
    volume: number,
    two_days_transactions: Transaction[],
    daily_volume: number,
    before_volume: number,
    floor_price: number,
    owners: { [owner: AccountAddress]: number },
    items: number,
    traits_stats: { [key: string]: TraitCategoryStat }
}

export type TraitCategoryStat={
    items_in_category: number,
    items_with_trait: { [trait: string]: number }
}

export type CollectionData = {
    name: string,
    description: string,
    tags: string[],
    icon_url: string,
    info_url: string
}

export type NFTCollection = {
    id: number,
    address?: ResourceAddress,
    data: CollectionData,
    stats: CollectionStats,
    user_admin_badge: ResourceAddress,
    radland_admin_badge: ResourceAddress,
    owner: AccountAddress,
    background_image: string,
    twitter_url?: string,
    instagram_url?: string,
    discord_url?: string,
    is_minting: boolean
}

export type ShortCollection = {
    id: number,
    owner:AccountAddress,
    icon_url: string,
    name: string,
    item_amount: number
}

export type ItemData = {
    name: string,
    description: string,
    image_url: string,
    id: string,
    hash: string | null,
    traits: { [key: string]: string },
}

export type CollectionItem = {
    collection_id: number,
    collection_address?: ResourceAddress,
    item_data: ItemData,
    proposal?: Proposal,
    minted: boolean,
    activity_history: Activity[],
    owner: string
}

export type ShortCollectionItem = {
    collection_id: number;
    id: string;
    name: string;
    image_url: string;
    proposal?: Proposal;
    minted: boolean;
    owner: string;
};


export const default_item: CollectionItem = {
    collection_id: -1,
    item_data: {
        name: "",
        description: "",
        image_url: "",
        id: "",
        hash: null,
        traits: {}
    },
    activity_history: [],
    owner: "",
    minted: false
}

export type JSONItemDataInput = {
    name: string,
    description: string,
    key_image_url: string,
    traits: { [key: string]: string },
    hash?: string,
    price?: number,
    payment_resource?: string,
    olympia_owner?: string
}

export const default_collection: NFTCollection = {
    id: -1,
    data: {
        name: "",
        description: "",
        tags: [],
        icon_url: "",
        info_url: ""
    },
    stats: {
        volume: 0,
        two_days_transactions: [],
        daily_volume: 0,
        before_volume: 0,
        floor_price: 0,
        owners: {},
        items: 0,
        traits_stats: {}
    },
    user_admin_badge: "",
    radland_admin_badge: "",
    owner: "",
    background_image: "",
    is_minting: false
}

/**
 * Converts a JSONItemData to a CollectionItem with right `item_data` and `proposal` and everything else wrong.
 * @param collection - collection to which the items belongs.
 * @param json_item - JSONItemData to convert.
 */

export async function JSONToCollectionItem(collection: NFTCollection, json_item: JSONItemDataInput): Promise<CollectionItem> {

    let item_data: ItemData = {
        name: json_item.name,
        description: json_item.description,
        image_url: json_item.key_image_url,
        traits: json_item.traits,
        hash: null,
        id: ItemNumberID(collection.stats.items + 1, collection)
    }

    if (!item_data.name) {
        throw new Error("Could not parse field 'name'");
    }
    if (!item_data.description) {
        item_data.description = "None"
    }
    if (!item_data.image_url) {
        throw new Error("Could not parse field 'key_image_url'");
    }


    if (json_item.hash) {
        item_data.hash = json_item.hash
    }

    let proposal: Proposal | undefined = undefined;
    let owner = collection.owner;

    if (json_item.price && json_item.olympia_owner) {
        throw new Error("Cannot have a price and an owner!")
    } else {
        if (json_item.olympia_owner) {
            owner = json_item.olympia_owner;
            if (!owner.includes("account_")) {
                owner = await RadixEngineToolkit.Derive.virtualAccountAddressFromOlympiaAccountAddress(
                    json_item.olympia_owner,
                    NetworkId.Mainnet
                );
            }
        }

        if (json_item.price) {
            if (json_item.payment_resource) {
                proposal = {
                    id: -1,
                    payment: {address: json_item.payment_resource, amount: json_item.price}
                };
            } else {
                proposal = {
                    id: -1,
                    payment: {address: xrd_address, amount: json_item.price}

                }
            }
        }
    }




    return {
        collection_address: collection.address,
        collection_id: collection.id,
        item_data: item_data,
        minted: false,
        owner: owner,
        proposal: proposal,
        activity_history: []
    };
}

export function toShortItem(item: CollectionItem): ShortCollectionItem{
    return {
        collection_id: item.collection_id,
        id: item.item_data.id,
        name: item.item_data.name,
        image_url: item.item_data.image_url,
        proposal: item.proposal,
        minted: item.minted,
        owner: item.owner
    }
}

export function toUpperCamelCase(name: string): string {
    // Remove non-alphanumeric characters and split the string into words
    let words = name.replace(/[^a-zA-Z0-9 ]/g, '').split(' ');

    // Convert the first letter of each word to uppercase
    for (let i = 0; i < words.length; i++) {
        words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
    }

    // Join the words back together to form the upper camel case string
    return words.join('');
}

function ItemNumberID(id: number, collection:NFTCollection): string{
    return `<${toUpperCamelCase(collection.data.name)}_${id}>`
}