import {sendTransaction} from "../Global";
import {NFTCollection} from "../../types/Collection";
import {MetadataToArg, VecContentToArg} from "../ManifestUtils";
import {TransactionResult} from "../../types/Transaction";

/**
 * Creates a new NFT collection with given metadata and the following permissions:
 * - minting: needs RadLand badge or owner badge, can be changed by collection's owner;
 * - burning: everyone, cannot be changed;
 * - freeze: no-one, cannot be changed;
 * - recall: no-one, cannot be changed;
 * - nf data update: no-one, can be changed by collection's owner;
 * - withdraw: everyone, cannot be changed;
 * - deposit: everyone, cannot be changed;
 *
 *
 * TODO: (1) Make manifest humanly readable
 */
export async function mintCollectionResource(collection: NFTCollection): Promise<TransactionResult> {
    if(collection.user_admin_badge == null){
        throw new Error()
    }

    let collection_data = collection.data
    let user_admin_badge = collection.user_admin_badge
    let radland_badge = collection.radland_admin_badge
    let tags_cont = VecContentToArg(collection_data.tags);
    let tags = `Array<String>(${tags_cont})`;

    const manifest = `
CREATE_NON_FUNGIBLE_RESOURCE
    Enum<2u8>(
        Enum<2u8>(
            Enum<0u8>(
                Enum<0u8>(
                    Enum<1u8>(
                        Address("${user_admin_badge}")
                    )
                )
            )
        )
    )
    Enum<NonFungibleIdType::String>() 
    true
    ${MetadataToArg(collection)}
    Tuple(
        Enum<1u8>(
            Tuple(
                Enum<1u8>(
                    Enum<2u8>(
                        Enum<1u8>(
                            Array<Enum>(
                                Enum<0u8>(
                                    Enum<0u8>(
                                        Enum<1u8>(
                                            Address("${user_admin_badge}")
                                        )
                                    )
                                ),
                                Enum<0u8>(
                                    Enum<0u8>(
                                        Enum<1u8>(
                                            Address("${radland_badge}")
                                        )
                                    )
                                )
                            )
                        )
                    )
                ),
                Enum<0u8>()
            )
        ),
        Enum<1u8>(
            Tuple(
                Enum<1u8>(
                    Enum<0u8>()
                ),
                Enum<1u8>(
                    Enum<1u8>()
                )
            )
        ),
        Enum<1u8>(
            Tuple(
                Enum<1u8>(
                    Enum<1u8>()
                ),
                Enum<1u8>(
                    Enum<1u8>()
                )
            )
        ),
        Enum<1u8>(
            Tuple(
                Enum<1u8>(
                    Enum<1u8>()
                ),
                Enum<1u8>(
                    Enum<1u8>()
                )
            )
        ),
        Enum<1u8>(
            Tuple(
                Enum<1u8>(
                    Enum<0u8>()
                ),
                Enum<1u8>(
                    Enum<1u8>()
                )
            )
        ),
        Enum<1u8>(
            Tuple(
                Enum<1u8>(
                    Enum<0u8>()
                ),
                Enum<1u8>(
                    Enum<1u8>()
                )
            )
        ),
        Enum<1u8>(
            Tuple(
                Enum<1u8>(
                    Enum<1u8>()
                ),
                Enum<0u8>()
            )
        )
    )
        Tuple(
            Map<String, Tuple>(                                                
                "name" => Tuple(
                    Some(Enum<Metadata::String>("${collection_data.name}")),                       # Collection Name
                    true                                                         # Locked
                ),
                "description" => Tuple(
                    Some(Enum<Metadata::String>("${collection_data.description}")),                # Collection description
                    true                                                         # Locked
                ),
                "tags" => Tuple(
                    Some(Enum<Metadata::StringArray>(${tags})),              # Collection tags
                    true                                                         # Locked
                ),
                "icon_url" => Tuple(
                    Some(Enum<Metadata::Url>("${collection_data.icon_url}")),                   # Collection icon
                    true                                                         # Locked
                ),
                "info_url" => Tuple(
                    Some(Enum<Metadata::String>("${collection_data.info_url}")),                   # Collection info
                    true                                                         # Locked
                ),
                "metadata_standard" =>  Tuple(
                    Some(Enum<Metadata::String>("radland_v1")),                   # Collection info
                    true                                                         # Locked
                ),
                "creator_name" =>  Tuple(
                    Some(Enum<Metadata::String>("${collection.owner}")),         # Collection info
                    true                                                         # Locked
                ),
                "royalty_type" =>  Tuple(
                    Some(Enum<Metadata::String>("None")),                   # Collection info
                    false                                                         # Unlocked
                ),
                "royalty_collection_type" =>  Tuple(
                    Some(Enum<Metadata::String>("N/A")),                   # Collection info
                    false                                                         # Unlocked
                ),
                "royalty_fee" =>  Tuple(
                    Some(Enum<Metadata::String>("0")),                   # Collection info
                    false                                                         # Unlocked
                )
            ),
            Map<String, Enum>(                                                   # Metadata roles
                "metadata_setter" => None,         # Metadata setter role
                "metadata_setter_updater" => None,                               # Metadata setter updater role as None defaults to OWNER
                "metadata_locker" => None,          # Metadata locker role
                "metadata_locker_updater" => None                                # Metadata locker updater role as None defaults to OWNER
            )
        )
        Enum<0u8>();`;

    return await sendTransaction(manifest, `Creates the resource for your collection "${collection.data.name}"`);
}