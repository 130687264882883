import React, { useState } from "react";
import { IoChevronUpOutline, IoChevronDownOutline } from "react-icons/io5";
import CheckBox from "../../utils/CheckBox";
import {TraitCategoryStat} from "../../../types/Collection";

interface FilterListProps {
    setMinPrice: (minPrice: number | undefined) => void;
    setMaxPrice: (maxPrice: number | undefined) => void;
    setStatus: (filter: "All" | "Listed" | "Owned") => void;
    addTraitFilter: (category: string, trait: string) => void;
    removeTraitFilter: (category: string, trait: string) => void;
    needsUpdate: (needs: boolean) => void;
    traitsStats: { [key: string]: TraitCategoryStat };
}

const statusList : ("All" | "Listed" | "Owned")[] = ["All", "Listed", "Owned"];

interface TraitItemProps{
    category: string;
    stats: TraitCategoryStat;
    addTraitFilter: (category: string, trait: string) => void;
    removeTraitFilter: (category: string, trait: string) => void;
}

function TraitItem({
    category,
    stats,
    addTraitFilter,
    removeTraitFilter
                   }:TraitItemProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  function onChecked(isChecked: boolean, trait: string){
      if(isChecked){
          addTraitFilter(category, trait)
      }
      else{
          removeTraitFilter(category, trait)
      }
  }

  return (
    <div
      className="py-2 cursor-pointer  mt-2 rounded-lg"
      onClick={() => setIsOpen(!isOpen)}
    >
      <div className="flex items-center justify-between">
        <p className="font-medium">{category}</p>
        {isOpen ? (
          <IoChevronUpOutline className="text-neutral-400 text-xl" />
        ) : (
          <IoChevronDownOutline className="text-neutral-400 text-xl" />
        )}
      </div>

      {isOpen && (
        <div
          className="py-2"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
            {
                Object.keys(stats.items_with_trait).map((trait) => (
                <div
                    className="flex items-center justify-between mt-2"
                    key={trait}
                >
                    <div className="flex items-center gap-2">
                        <CheckBox
                            trait={trait}
                            onClick={onChecked}
                        />
                        <p className="text-sm">{trait}</p>
                    </div>
                    <p className="text-sm text-neutral-400">{stats.items_with_trait[trait]}</p>
                </div>
            ))}
        </div>
      )}
    </div>
  );
}

export default function FilterList({
    setMinPrice,
    setMaxPrice,
    addTraitFilter,
    removeTraitFilter,
    setStatus,
    needsUpdate,
    traitsStats
                                   }: FilterListProps) {
    const [localStatus, setLocalStatus] = useState<"All" | "Listed" | "Owned">("All");

    function setItemFilter(stat: "All" | "Listed" | "Owned"){
        if(stat !== localStatus){
            setLocalStatus(stat);
            setStatus(stat);
            needsUpdate(true);
        }
    }

    function setMin(min:string){
        if(min === "" || parseFloat(min) <= 0){
            setMinPrice(undefined);
        }
        else{
            setMinPrice(parseFloat(min))
        }
    }

    function setMax(max:string){
        if(max === ""){
            setMaxPrice(undefined)
        }
        else{
            setMaxPrice(parseFloat(max))
        }
    }


  return (
    <div className="w-full">
      <div className="shadow-sm bg-white p-3 rounded-xl">
        <p className="font-semibold">Status</p>

        <div className="h-[1px] w-full bg-neutral-100 my-2"></div>

        <div className="flex flex-wrap gap-2 mt-3">
          {statusList.map((statusItem) => (
            <button
              className={`px-2 py-1 text-sm rounded-md font-medium ${localStatus === statusItem ? 'bg-black-base text-white' : 'bg-neutral-100 text-neutral-600 hover:text-white hover:bg-black-base'} transition-all duration-150 ease-in-out`}
              key={statusItem}
              onClick={() => {setItemFilter(statusItem)}}
            >
              {statusItem}
            </button>
          ))}
        </div>
      </div>

      <div className="shadow-sm bg-white p-3 rounded-xl mt-4">
        <p className="font-semibold">Price</p>

        <div className="h-[1px] w-full bg-neutral-100 my-2"></div>

        <div className="flex items-center gap-x-2">
          <input
            type="text"
            className="py-2 px-3 border border-neutral-100 border-solid rounded-xl w-full flex items-center justify-center"
            placeholder="Min"
            onInput={(event) => { setMin(event.currentTarget.value) }}
          />

          <span>to</span>

          <input
            type="text"
            className="py-2 px-3 border border-neutral-100 border-solid rounded-xl w-full flex items-center justify-center"
            placeholder="Max"
            onInput={(event) => { setMax(event.currentTarget.value) }}
          />
        </div>

        <button className="w-full mt-3 px-[16px] py-[8px] font-medium text-sm text-white bg-[#535AFA] flex items-center justify-center rounded-lg"
            onClick={() => { needsUpdate(true) }}>
          Apply
        </button>
      </div>

      <div className="shadow-sm bg-white p-3 rounded-xl mt-4">
        <p className="font-semibold">Traits</p>

        <div className="h-[1px] w-full bg-neutral-100 my-2"></div>

        <div>
            {
                Object.keys(traitsStats).length > 0 ? (
                    Object.keys(traitsStats).sort().map(
                        category => (
                            <TraitItem
                                category={category}
                                stats={traitsStats[category]}
                                addTraitFilter={addTraitFilter}
                                removeTraitFilter={removeTraitFilter}
                            />
                        )
                    )
                ): null
            }
        </div>
      </div>
    </div>
  );
}
