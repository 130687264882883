import {backend_url} from "../../Constants";
import {Stats} from "../../types/Stats";


export async function getHomeStats(): Promise<Stats> {
    try {
        let response;
        response = await fetch(backend_url+'/getStats');
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return await response.json();

    } catch (error) {
        console.error('Error fetching data:', error);
        throw error; // Rethrow the error for handling by the caller
    }
}