import { useQuery } from 'react-query';

import actions  from '../../backend/collections/CollectionGetters';

const useFetchLatestCollections = (category?:string) => {
  return useQuery(
    ['fetchLatestCollections'],
    async () => {
      const latestCollections = await actions.fetchRecentlyAddedCollections(category);
      return latestCollections;
    },
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
    },
  );
};

export default useFetchLatestCollections;