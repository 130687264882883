import {gateway, rdt} from "../App";
import {CommittedTransactionInfo} from "@radixdlt/babylon-gateway-api-sdk";
import {AccountAddress, NFT, ResourceAddress} from "../types/Radix";
import {radland_trader_card} from "../Constants";
import {TransactionResult} from "../types/Transaction";
import {NFTCollection} from "../types/Collection";


function with_timeout_and_update(timeout_in_ms: number, promise: any) {
    const timeoutPromise = new Promise((_, reject) => {
        setTimeout(() => {
            reject(new Error('Timeout'));
        }, timeout_in_ms);
    });

    return Promise.race([promise, timeoutPromise]);
}

export function id_to_url(id: string): string{
    switch (id.at(0)){
        case '{':{
            return '$' + id.slice(1, -1) + '$';
        }
        case '[':{
            return '*' + id.slice(1, -1) + '*';
        }
        case '#':{
            return '!' + id.slice(1, -1) + '!';
        }
        default:{
            return id;
        }
    }
}


/**
 * Sends a transaction to the user and only returns if it did not fail.
 * @param manifest - manifest to deal with.
 * @param message - message.
 */
export async function sendTransaction(manifest: string, message?: string): Promise<TransactionResult> {
    console.log("start sending transaction")

    try{
        const result = await with_timeout_and_update(
            60000, // Request will timeout after 1 minute
            rdt.walletApi.sendTransaction({
                transactionManifest: manifest,
                message: message,
                version: 1
            })
        );
        if (result.isErr()) {
            let error_message = result.error.message;
            if(!error_message){
                error_message = "Unknown error"
            }
            return {
                outcome: "FAILED",
                error: error_message
            }
        } else {

            // Check the result of the transaction
            let intentHash = result.value.transactionIntentHash;
            let response  = await gateway.transaction.getCommittedDetails(intentHash);
            if(response.transaction.transaction_status === "CommittedSuccess") {
                return {
                    outcome: "SUCCESS",
                    info: response.transaction
                }
            }
            else{
                let error_message  = response.transaction.error_message;
                if(!error_message){
                    error_message = "Unknown error"
                }
                // Deal with the error
                return {
                    outcome: "FAILED",
                    error: error_message
                }
            }
        }
    }
    catch (err){
        // Only fails if timeout
        return {
            outcome: "TIMEOUT"
        }
    }
}

/**
 * Returns the address of the current wallet.
 */
export function getCurrentUser(): string {
    try{
        return rdt.walletApi.getWalletData().accounts[0].address;
    }
    catch (err){
        return "";
    }
}

export async function canDeposit(account: AccountAddress){
    console.log(account)
    const response = await gateway.state.innerClient.stateEntityDetails({
        stateEntityDetailsRequest: {
            addresses: [account]
        }
    });

    const result = response.items[0];
    // @ts-ignore
    return result.details["state"]["default_deposit_rule"] === "Accept"
}

export async function getNFTTraderCard(radland_nft: ResourceAddress): Promise<NFT | undefined>{
    let response = await gateway.state.innerClient.entityNonFungibleResourceVaultPage({
        stateEntityNonFungibleResourceVaultsPageRequest: {
            address: getCurrentUser(),
            resource_address: radland_nft
        }
    });
    if(response.items.length >= 1){
        let vault_address = response.items[0].vault_address;
        let response2 = await gateway.state.innerClient.entityNonFungibleIdsPage({
            stateEntityNonFungibleIdsPageRequest: {
                address: getCurrentUser(),
                resource_address: radland_nft,
                vault_address: vault_address
            }
        });
        let id = response2.items[0];

        return {address: radland_nft, id: id};
    }
    else{
        return undefined;
    }
}

export async function mintNFTCard(): Promise<{ receipt: CommittedTransactionInfo, nft: NFT }> {
    let response = await gateway.state.innerClient.nonFungibleIds({
        stateNonFungibleIdsRequest: {
            resource_address: radland_trader_card
        }
    });
    let last_id : number = response.non_fungible_ids.total_count ? response.non_fungible_ids.total_count : 0;

    let id = `#${last_id+1}#`
    let account = getCurrentUser();
    let manifest = `
    MINT_NON_FUNGIBLE
    Address("${radland_trader_card}")
    Map<NonFungibleLocalId, Tuple>(
        NonFungibleLocalId("${id}") => Tuple(Tuple())
    );
    CALL_METHOD
        Address("${account}")
        "deposit_batch"
        Expression("ENTIRE_WORKTOP");
    `;
    let receipt = await sendTransaction(manifest, "Mints your Radland NFT trader card");
    if (receipt.outcome === "SUCCESS"){
        return {receipt: receipt.info, nft: {address: radland_trader_card, id: id}};
    }
    else{
        throw Error("Something went wrong")
    }

}

export function getNewResourceFromTransaction(receipt: CommittedTransactionInfo): ResourceAddress {
    let resource_address: ResourceAddress = "";
    receipt.affected_global_entities?.forEach(address => {
        if(address.includes("resource_")){
            resource_address = address
        }
    });

    if(resource_address === "")
    {
        // Should never happen
        throw new Error();
    }
    else {
        return resource_address;
    }
}

export async function makeDeposit(account: AccountAddress, mint_cost: number, message: string, xrd_address: ResourceAddress, radland_backend_account: AccountAddress): Promise<TransactionResult>{
    let manifest = `
    CALL_METHOD
        Address("${account}")
        "withdraw"
        Address("${xrd_address}")
        Decimal("${mint_cost}");
            
    CALL_METHOD
        Address("${radland_backend_account}")
        "try_deposit_batch_or_abort"
        Expression("ENTIRE_WORKTOP")  
        None;
    `;
    return await sendTransaction(manifest, message);
}

export async function sendNFTs(account: AccountAddress, to_account: AccountAddress, nfts: string[], collection: NFTCollection): Promise<TransactionResult>{
    let withdraw_ids = ``;
    nfts.forEach(id =>{
        withdraw_ids+= `NonFungibleLocalId("${id}"), `
    })
    let manifest = `
        CALL_METHOD
        Address("${account}")
        "withdraw_non_fungibles"
        Address("${collection.address}")
        Array<NonFungibleLocalId>(${withdraw_ids});
        
    CALL_METHOD
        Address("${to_account}")
        "try_deposit_batch_or_abort"
        Expression("ENTIRE_WORKTOP")  
        None;
    `

    return await sendTransaction(manifest, `Converting ${nfts.length} items to the new version.`);
}
