import React, { useEffect, useState } from "react";
import { Activity } from "../../types/Collection";
import { dashboard_address } from "../../Constants";

type FilterOption = {
  label: string;
  value: string;
};

const formatDateTime = (timestamp: number) => {
  const date = new Date(timestamp);

  // how many days ago
  const daysAgo = Math.floor(
    (Date.now() - date.getTime()) / (1000 * 60 * 60 * 24),
  );

  // if today
  if (daysAgo === 0) {
    return "Today";
  }

  // if yesterday
  if (daysAgo === 1) {
    return "Yesterday";
  }

  // if more than 1 day ago
  return `${daysAgo} days ago`;
};

const Filters = ({
  options,
  onFilterChange,
}: {
  options: FilterOption[];
  onFilterChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}) => {
  return (
    <div className="relative w-full ">
      <select
        className={`block appearance-none w-full py-3 px-4 bg-[#F7F9FA] focus:outline-none placeholder:text-[#93989A] rounded-lg`}
        onChange={onFilterChange}
      >
        <option value="" className="text-[#93989A] text-sm">
          Select Filter
        </option>
        {options.map((option) => (
          <option key={option.value} value={option.value} className="text-sm">
            {option.label}
          </option>
        ))}
      </select>
      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-[20px] ">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M13.28 5.96667L8.9333 10.3133C8.41997 10.8267 7.57997 10.8267 7.06664 10.3133L2.71997 5.96667"
            stroke="#93989A"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    </div>
  );
};

type NFTActivityProps = {
  item_activities: Activity[];
};
export default function NFTActivity({ item_activities }: NFTActivityProps) {
  // console.log("NFTActivity", item);
  const availableFilters: FilterOption[] = [
    {
      label: "Sale",
      value: "Sale",
    },
    {
      label: "Transfer",
      value: "Transfer",
    },
    {
      label: "Listing",
      value: "Listing",
    },
    {
      label: "Minted",
      value: "Minted",
    },
    {
      label: "Burnt",
      value: "Burnt",
    },
  ];

  const [filters, setFilters] = useState<FilterOption[]>(availableFilters);
  const [activities, setActivities] = useState<Activity[]>(item_activities);

  useEffect(() => {
    if (item_activities.length > 0) {
      setActivities(item_activities);
    }
  }, [item_activities]);

  // filter activities
  useEffect(() => {
    if (filters.length === 0) {
      setActivities(item_activities);
      return;
    }

    const filteredActivities = item_activities.filter((activity) =>
      filters.some((filter) => filter.value === activity.event),
    );

    setActivities(filteredActivities);
  }, [filters, item_activities, setActivities]);

  // handle filter change
  const handleFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    // check if filter already exists
    const filterExists = filters.find((f) => f.value === e.target.value);

    if (!filterExists) {
      if (e.target.value === "") return;
      setFilters([
        ...filters,
        { label: e.target.value, value: e.target.value },
      ]);
    }
  };

  return (
    <div className="w-full max-w-[984px] mt-8 p-6 rounded-[15px] border border-[#F3F3F3] font-poppins">
      <p className="text-[24px] font-semibold text-left">Item Activity</p>

      <div className="my-6">
        <Filters
          options={availableFilters}
          onFilterChange={handleFilterChange}
        />
        <div className="mt-2 flex flex-wrap items-center gap-2">
          {filters.map((filter) => (
            <div className="flex items-center gap-2 rounded-lg py-2 px-3 bg-[#FBFBFF] border border-[#F3F3F3]">
              <span className="text-[#93989A] text-sm">{filter.label}</span>
              <button>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13"
                  height="13"
                  viewBox="0 0 10 10"
                  fill="none"
                  onClick={() => {
                    setFilters(filters.filter((f) => f.value !== filter.value));
                  }}
                >
                  <path
                    d="M7.5 2.5L2.5 7.5"
                    stroke="#93989A"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M2.5 2.5L7.5 7.5"
                    stroke="#93989A"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
          ))}

          {filters.length > 1 && (
            <span
              className="text-sm text-[#535AFA] font-medium ml-2 cursor-pointer"
              onClick={() => setFilters([])}
            >
              Clear all
            </span>
          )}
        </div>
      </div>

      {/* data table */}
      <table className="w-full table-auto">
        <thead>
          <tr className="text-left">
            <th className="text-xs text-[#93989A] font-medium">Event</th>
            {/* <th className="text-xs text-[#93989A] font-medium hidden md:table-cell">
                Activity
              </th> */}
            <th className="text-xs text-[#93989A] font-medium">Price</th>
            <th className="text-xs text-[#93989A] font-medium hidden lg:table-cell">
              From
            </th>
            <th className="text-xs text-[#93989A] font-medium hidden md:table-cell">
              To
            </th>
            <th className="text-xs text-[#93989A] font-medium hidden lg:table-cell">
              Time
            </th>
          </tr>
        </thead>
        <tbody className="mt-2">
          {activities?.map((activity, i) => (
            <tr
              key={i}
              className="border-b border-solid border-b-[#F3F3F3] text-left"
            >
              <td className=" py-[20px]">
                <div className="text-sm font-medium flex items-center h-full gap-x-2">
                  <span>{getActivityIcon(activity.event)}</span>
                  <span>{activity.event}</span>
                </div>
              </td>
              <td className="">
                {activity.price === null ? (
                  "-"
                ) : (
                  <div className="flex items-center gap-x-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                    >
                      <path
                        d="M7.13837 15C6.87277 15 6.62029 14.8774 6.46195 14.6664L3.15603 10.2409H1V8.62973H3.58253C3.85069 8.62973 4.10189 8.75356 4.25888 8.96337L6.96045 12.5788L11.0828 3.48275C11.2162 3.18954 11.5158 3 11.8468 3H17V4.61121H12.3923L7.9024 14.5173C7.78202 14.7826 7.52429 14.9646 7.22598 14.9949C7.19853 14.9987 7.16845 15 7.13837 15Z"
                        fill="#535AFA"
                      />
                    </svg>
                    <span className="text-sm font-medium">
                      {activity.price} XRD
                    </span>
                  </div>
                )}
              </td>
              {activity.from ? (
                <td className="text-sm font-medium text-[#535AFA] hidden lg:table-cell">
                  <p className="flex items-center gap-x-2">
                    <a
                      href={`${dashboard_address()}account/${activity.from}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span>{`${activity.from.substring(0, 9)}...${activity.from.substring(activity.from.length - 8, activity.from.length)}`}</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        viewBox="0 0 10 10"
                        fill="none"
                      >
                        <path
                          d="M5.41602 4.58317L8.83268 1.1665"
                          stroke="#535AFA"
                          strokeWidth="1.3"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M9.16602 2.8335V0.833496H7.16602"
                          stroke="#535AFA"
                          strokeWidth="1.3"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M4.58398 0.833496H3.75065C1.66732 0.833496 0.833984 1.66683 0.833984 3.75016V6.25016C0.833984 8.3335 1.66732 9.16683 3.75065 9.16683H6.25065C8.33398 9.16683 9.16732 8.3335 9.16732 6.25016V5.41683"
                          stroke="#535AFA"
                          strokeWidth="1.3"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </a>
                  </p>
                </td>
              ) : (
                <td className="text-sm font-medium text-[#535AFA] hidden lg:table-cell">
                  {"-"}
                </td>
              )}
              {activity.to ? (
                <td className="text-sm font-medium text-[#535AFA] hidden lg:table-cell">
                  <p className="flex items-center gap-x-2">
                    <a
                      href={`${dashboard_address()}account/${activity.to}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span>{`${activity.to.substring(0, 9)}...${activity.to.substring(activity.to.length - 8, activity.to.length)}`}</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        viewBox="0 0 10 10"
                        fill="none"
                      >
                        <path
                          d="M5.41602 4.58317L8.83268 1.1665"
                          stroke="#535AFA"
                          strokeWidth="1.3"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M9.16602 2.8335V0.833496H7.16602"
                          stroke="#535AFA"
                          strokeWidth="1.3"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M4.58398 0.833496H3.75065C1.66732 0.833496 0.833984 1.66683 0.833984 3.75016V6.25016C0.833984 8.3335 1.66732 9.16683 3.75065 9.16683H6.25065C8.33398 9.16683 9.16732 8.3335 9.16732 6.25016V5.41683"
                          stroke="#535AFA"
                          strokeWidth="1.3"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </a>
                  </p>
                </td>
              ) : (
                <td className="text-sm font-medium text-[#535AFA] hidden lg:table-cell">
                  {"-"}
                </td>
              )}
              <td className="text-sm font-medium text-[#535AFA] hidden lg:table-cell cursor-pointer">
                <a
                  href={`${dashboard_address()}transaction/${activity.transaction_id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p className="flex items-center gap-x-2">
                    <span>{formatDateTime(activity.time)}</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                      fill="none"
                    >
                      <path
                        d="M5.41602 4.58317L8.83268 1.1665"
                        stroke="#535AFA"
                        strokeWidth="1.3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M9.16602 2.8335V0.833496H7.16602"
                        stroke="#535AFA"
                        strokeWidth="1.3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M4.58398 0.833496H3.75065C1.66732 0.833496 0.833984 1.66683 0.833984 3.75016V6.25016C0.833984 8.3335 1.66732 9.16683 3.75065 9.16683H6.25065C8.33398 9.16683 9.16732 8.3335 9.16732 6.25016V5.41683"
                        stroke="#535AFA"
                        strokeWidth="1.3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </p>
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

const getActivityIcon = (
  type: "Sale" | "Listing" | "Minted" | "Transfer" | "Burnt",
) => {
  if (type === "Sale") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="M1.3335 1.33301H2.4935C3.2135 1.33301 3.78016 1.95301 3.72016 2.66634L3.16683 9.30634C3.0735 10.393 3.93349 11.3263 5.02683 11.3263H12.1268C13.0868 11.3263 13.9268 10.5397 14.0002 9.58634L14.3602 4.58634C14.4402 3.47968 13.6002 2.57967 12.4868 2.57967H3.88017"
          stroke="#040409"
          strokeWidth="1.2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.8333 14.6667C11.2936 14.6667 11.6667 14.2936 11.6667 13.8333C11.6667 13.3731 11.2936 13 10.8333 13C10.3731 13 10 13.3731 10 13.8333C10 14.2936 10.3731 14.6667 10.8333 14.6667Z"
          stroke="#040409"
          strokeWidth="1.2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.50008 14.6667C5.96032 14.6667 6.33342 14.2936 6.33342 13.8333C6.33342 13.3731 5.96032 13 5.50008 13C5.03984 13 4.66675 13.3731 4.66675 13.8333C4.66675 14.2936 5.03984 14.6667 5.50008 14.6667Z"
          stroke="#040409"
          strokeWidth="1.2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6 5.33301H14"
          stroke="#040409"
          strokeWidth="1.2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  if (type === "Listing") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="M2.77977 10.2005L5.79977 13.2205C7.03977 14.4605 9.0531 14.4605 10.2998 13.2205L13.2264 10.2939C14.4664 9.05385 14.4664 7.04052 13.2264 5.79385L10.1998 2.78052C9.56643 2.14719 8.6931 1.80719 7.79977 1.85385L4.46643 2.01385C3.1331 2.07385 2.0731 3.13385 2.00643 4.46052L1.84643 7.79385C1.80643 8.69385 2.14643 9.56719 2.77977 10.2005Z"
          stroke="#040409"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.33317 8.00033C7.25364 8.00033 7.99984 7.25413 7.99984 6.33366C7.99984 5.41318 7.25364 4.66699 6.33317 4.66699C5.4127 4.66699 4.6665 5.41318 4.6665 6.33366C4.6665 7.25413 5.4127 8.00033 6.33317 8.00033Z"
          stroke="#040409"
          strokeWidth="1.2"
          strokeLinecap="round"
        />
      </svg>
    );
  }

  if (type === "Minted") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="M2.33321 13.6666C2.88655 14.22 3.77988 14.22 4.33321 13.6666L12.9999 4.99996C13.5532 4.44663 13.5532 3.55329 12.9999 2.99996C12.4465 2.44663 11.5532 2.44663 10.9999 2.99996L2.33321 11.6666C1.77988 12.22 1.77988 13.1133 2.33321 13.6666Z"
          stroke="#040409"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.0066 5.99329L10.0066 3.99329"
          stroke="#040409"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.6665 1.62634L6.6665 1.33301L6.37317 2.33301L6.6665 3.33301L5.6665 3.03967L4.6665 3.33301L4.95984 2.33301L4.6665 1.33301L5.6665 1.62634Z"
          stroke="#040409"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3 5.62634L4 5.33301L3.70667 6.33301L4 7.33301L3 7.03967L2 7.33301L2.29333 6.33301L2 5.33301L3 5.62634Z"
          stroke="#040409"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13 8.96033L14 8.66699L13.7067 9.66699L14 10.667L13 10.3737L12 10.667L12.2933 9.66699L12 8.66699L13 8.96033Z"
          stroke="#040409"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  if (type === "Burnt") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="800px"
        height="800px"
        viewBox="0 0 512 512"
        fill="none"
      >
        <path
          d="M200.09,233.52c22.42-22.41,61.34-68.33,47.39-105.52,0,0,50.87,52.71,36.45,120.47,0,0,31.72-24,33.4-63.44,25.13,29.08,26.36,97.07,25,112.46C338.1,345.08,303.59,384,255.82,384a86.51,86.51,0,0,1-86.51-86.51,63.38,63.38,0,0,1,2.52-15.8A108.42,108.42,0,0,1,200.09,233.52Z"
          stroke="#083b43"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  if (type === "Transfer") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="M13.6667 9.99329L10.3267 13.34"
          stroke="#040409"
          strokeWidth="1.2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.33325 9.99329H13.6666"
          stroke="#040409"
          strokeWidth="1.2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.33325 6.0067L5.67325 2.66003"
          stroke="#040409"
          strokeWidth="1.2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.6666 6.00671H2.33325"
          stroke="#040409"
          strokeWidth="1.2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
};
