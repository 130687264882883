import { useNavigate } from "react-router";
import { Item } from "../../types/global.components";
import {CollectionItem} from "../../types/Collection";

interface NftItemProps {
  item: CollectionItem;
}

export default function NftItem({ item }: NftItemProps) {
  const navigate = useNavigate();

  return (
    <div className="w-full p-2 pb-4 shadow-sm bg-white rounded-xl flex flex-col group cursor-pointer">
      <div
        onClick={() => navigate(`/assets/${item.collection_id}_${item.item_data.id}`)}
        className="overflow-hidden rounded-xl"
      >
        <img
          src={item.item_data.image_url}
          alt=""
          className="w-full h-auto aspect-square rounded-xl object-cover group-hover:scale-110 transition-all duration-200 ease-in-out"
        />
      </div>
      <p className="mt-3 font-bold">{item.item_data.name}</p>

      <div className="mt-auto  flex justify-between items-center">
        <div>
          <div className="flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
            >
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}
