import React from "react";
import { SelectOption } from "../../types/utils.components";

interface SelectProps {
  options: SelectOption[];
  value: string;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  placeholder?: string;
  optionClassname?: string;
  selectComponentClassname?: string;
}

export default function Select({
  options,
  value,
  onChange,
  placeholder,
  optionClassname,
  selectComponentClassname,
}: SelectProps) {
  return (
    <div className="relative inline-block w-full ">
      <select
        className={`block appearance-none w-full py-[16px] px-[20px] border border-solid bg-white border-[#F3F3F3] focus:outline-none placeholder:text-[#A6A9B9] rounded-lg ${selectComponentClassname}`}
        onChange={onChange}
        value={value}
      >
        {options.map((option) => (
          <option
            key={option.value}
            value={option.value}
            className={optionClassname}
          >
            {option.label}
          </option>
        ))}
      </select>
      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-[20px] ">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M13.28 5.96667L8.9333 10.3133C8.41997 10.8267 7.57997 10.8267 7.06664 10.3133L2.71997 5.96667"
            stroke="#93989A"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
  );
}
