const SearchNavbarMenu: React.FC<{searchText: string, onUserType(event: React.FormEvent<HTMLInputElement>): void}> = ({
  searchText,
  onUserType,
}) => {
  return (
      <input value={searchText} onChange={onUserType} placeholder="Search Collections" 
        className="w-full rounded-[15px] py-[13px] px-[18px] bg-grey-light text-grey-dark" />
  )
};

export default SearchNavbarMenu;