
const ExplorePageNotFound: React.FC = () => {

  return (
      <div className="w-full text-left flex flex-col rounded-[15px] background-header">
        <div className="w-full h-full p-[50px] rounded-[15px] bg-gradient-to-r from-purple-headerGradientStart from-[40%] to-purple-headerGradientEnd">
          <p className="text-[14px] md:text-[24px] text-white mb-[12px] xl:w-[60%]">
            {"No collections found for this category :("}
          </p>
        </div>
      </div>
  )
};

export default ExplorePageNotFound;