import {useNavigate} from "react-router";
import UIButton from "../global/UIButton";
import Skeleton from "react-loading-skeleton";

// mock to remove
const PersonaCard: React.FC<{
  label: string,
  title: string,
  imgUrl: string,
  link?: string,
  isLoading: boolean,
}> = ({label, title, imgUrl, link, isLoading}) => {
  const navigate = useNavigate();

  const navigateTo = (): void => {
    if (link) navigate(link)
  }
  return (
      <div onClick={navigateTo} className={`w-full flex items-center gap-[14px] ${link ? 'cursor-pointer' : ''}`}>
        {isLoading ? (
          <Skeleton width={42} height={42} circle={true} />
        ) : (
          <img className="w-[42px] h-[42px] rounded-full" alt="avatar" src={imgUrl} />
        )}
        <div className="flex flex-col justify-between py-[10px]">
          <p className="text-grey-dark text-[12px] font-medium jus">{label}</p>
          {isLoading ? (
            <Skeleton width={300} height={24} />
          ) : (
            <p className="text-black-base text-[14px] font-semibold">{title}</p>
          )}
        </div>
      </div>
  )
};

export default PersonaCard;