import { callPostEndpoint } from "./Utils";
import {
  ClaimableResponse,
  ClaimingInformationResponse,
  ClaimResponse,
} from "../types/response/Claims";
import {
  ClaimableRequest,
  ClaimingInformationRequest,
  ClaimRequest,
} from "../types/requests/Claims";
import { getCurrentUser } from "../ledger/Global";
import { AccountAddress } from "../types/Radix";
import { getRolaToken } from "./Rola";

export async function getClaimable(): Promise<ClaimableResponse> {
  let token = getRolaToken();
  if (!token) {
    return { claimable: { user: "", items: [], collectable_funds: 0 } };
  } else {
    const request: ClaimableRequest = {
      authentication_token: token,
      user: getCurrentUser(),
    };
    try {
      return await callPostEndpoint("/getClaimable", request);
    } catch (err) {
      console.error(err);
      return { claimable: { user: "", items: [], collectable_funds: 0 } };
    }
  }
}

export async function getClaimableInformation(
  amount: number,
): Promise<ClaimingInformationResponse> {
  let request: ClaimingInformationRequest = { amount: amount };
  return await callPostEndpoint("/getClaimingInformation", request);
}

export async function claimItems(
  account: AccountAddress,
): Promise<ClaimResponse> {
  const authenticationToken = getRolaToken();
  if (!authenticationToken) {
    console.error("You are not authenticated");
    return { outcome: "FAILED", refunded: 0 };
  } else {
    let request: ClaimRequest = {
      authentication_token: authenticationToken,
      user: account,
    };
    return await callPostEndpoint("/claimItems", request);
  }
}
