import { useNavigate } from "react-router";
import UIButton from "../UIButton";
import profileImage from "../../../assets/default/Icon-Artist.jpg";
import { ReactComponent as Items } from "../../../assets/marketStats/totalItemsIcon.svg";
import { ReactComponent as Collections } from "../../../assets/marketStats/totalTransactionsIcon.svg";
import HomePageStatsItem from "../../HomePage/HomePageStatsItem";
import { Link } from "react-router-dom";

const ProfileContainer: React.FC<{
  isLoading: boolean;
  personaName: string;
  collectionsNumber: number;
}> = ({ personaName, isLoading, collectionsNumber }) => {
  return (
    <div
      className="w-full grid grid-cols-1 max-lg:divide-y lg:grid-cols-3
    px-[32px] lg:px-[35px] py-4 lg:py-[24px] rounded-[30px] 
    shadow-md max-w-[900px] mx-auto"
    >
      <HomePageStatsItem
        Icon={Items}
        title="Account"
        amount={personaName}
        type="string"
        isLoading={false}
      />
      <HomePageStatsItem
        Icon={Collections}
        title="Collections"
        amount={collectionsNumber}
        type="string"
        isLoading={isLoading}
      />
      <div>
        <div className="flex justify-center items-center border-l-0 px-[10px]">
          <Link
            to="/collections/create"
            className="w-full max-w-[457px] px-[24px] py-[16px] font-medium text-white bg-[#535AFA] flex items-center justify-center rounded-lg"
          >
            Create New Collection
          </Link>
        </div>
        <div className="flex justify-center items-center border-l-0 px-[10px]">
          `
        </div>
        <div className="flex justify-center items-center border-l-0 px-[10px]">
          <Link
            to="/collections/import"
            className="w-full max-w-[457px] px-[24px] py-[16px] font-medium text-white bg-[#535AFA] flex items-center justify-center rounded-lg"
          >
            Import Collection
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ProfileContainer;
