import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { errorModalState } from "../../state/global/errorModalState";
import { useRecoilState } from "recoil";
import { ReactComponent as ErrorIcon } from "../../assets/global/error.svg";

interface ErrorModalProps{
    isOpen: boolean,
    setIsOpen: (isOpen: boolean) => void;
    message: string
    title: string
}

export default function ErrorModal({
    isOpen,
    setIsOpen,
    message,
    title
                                   }: ErrorModalProps) {

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-10"
                onClose={() => setIsOpen(false)}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black-base/60" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full relative max-w-[650px] transform overflow-hidden rounded-[15px] bg-white px-4 py-6 md:py-8 md:px-8 text-center align-middle shadow-xl transition-all">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    className="absolute top-4 right-6 md:top-6 md:right-10 cursor-pointer"
                                    onClick={() => setIsOpen(false)}
                                >
                                    <path
                                        d="M4.16602 4.1665L15.8319 15.8324"
                                        stroke="#040409"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M4.16615 15.8324L15.832 4.1665"
                                        stroke="#040409"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>

                                <h3 className="text-[24px] font-semibold">{title}</h3>

                                <div className="mt-[40px] md:mt-[60px]">
                                    <ErrorIcon className="mx-auto" />
                                    <p className="font-bold text-[#E23434] text-[20px] mt-4">
                                        {message}
                                    </p>
                                    <p className="text-sm text-[#93989A] max-w-[495px] mx-auto mt-4">
                                        If this is unexpected, please contact an administrator.
                                    </p>
                                    <button
                                        className="w-[277px] h-[50px] rounded-lg bg-[#535AFA] mt-[40px] text-white text-sm font-medium"
                                        onClick={() => setIsOpen(false)}
                                    >
                                        Close
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
}
