import {useNavigate} from 'react-router-dom';

const ExplorePageNavigationItem: React.FC<{
    category: string, 
    isActive: boolean,
    onClick: (category: string) => void,
}> = ({category, isActive, onClick}) => {
  const navigate = useNavigate();
  return (
    <p onClick={() => onClick(category)} className={`text-[24px] font-semibold cursor-pointer ${isActive ? 'text-white underline underline-offset-[10px]' : 'text-slate-300'}`}>{category}</p>
  )
};

export default ExplorePageNavigationItem;