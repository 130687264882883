import { NFTCollection } from "../../types/Collection";
import { callPostEndpoint } from "../Utils";
import {
  CreateCollectionFromLedgerRequest,
  GetCollectionFromAddressRequest,
  MintCollectionRequest,
  PublishCollectionRequest,
  UpdateCollectionRequest,
} from "../../types/requests/Collection";
import { getCurrentUser } from "../../ledger/Global";
import {
  CreateCollectionFromLedgerResponse,
  GetCollectionFromAddressResponse,
  MintCollectionResponse,
  PublishCollectionResponse,
  UpdateCollectionResponse,
} from "../../types/response/Collection";
import { backend_url } from "../../Constants";
import { NFT, ResourceAddress } from "../../types/Radix";
import { getRolaToken } from "../Rola";

/**
 * Creates a new collection and registers it in the backend.
 * @param collection - collection to register on backend.
 *
 */
export async function createCollection(collection: NFTCollection) {
  console.log("creating collection");

  const authenticationToken = getRolaToken();

  if (!authenticationToken) {
    console.error("You are not authentified");
  } else {
    try {
      const response = await fetch(backend_url + "/createCollection", {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Set the appropriate content type
        },
        body: JSON.stringify({
          collection: collection,
          authenticationToken: authenticationToken,
        }), // Convert your data to JSON
        // please send me something like this {id, address, data, user_admin_badge, radland_admin_badge, category,explicit_content,twitter_backend_url,website_backend_url,instagram_backend_url,discord_backend_url}
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      console.error("Error posting data:", error);
      throw error; // Rethrow the error for handling by the caller
    }
  }
}

export async function updateCollection(collection: NFTCollection) {
  const authenticationToken = getRolaToken();
  if (!authenticationToken) {
    console.error("You are not authenticated");
  } else {
    let request: UpdateCollectionRequest = {
      authentication_token: authenticationToken,
      collection: collection,
    };
    await callPostEndpoint<UpdateCollectionRequest, UpdateCollectionResponse>(
      "/updateCollection",
      request,
    );
  }
}

export async function publishCollection(
  collection: NFTCollection,
): Promise<PublishCollectionResponse> {
  const authenticationToken = getRolaToken();
  if (!authenticationToken) {
    console.error("You are not authenticated!");
  } else {
    let request: PublishCollectionRequest = {
      authentication_token: authenticationToken,
      collection: collection,
      user: getCurrentUser(),
    };
    return await callPostEndpoint("/publishCollection", request);
  }
}

export async function mintCollection(
  collection: NFTCollection,
  trader_card: NFT,
): Promise<MintCollectionResponse> {
  const authenticationToken = getRolaToken();
  if (!authenticationToken) {
    console.error("You are not authenticated");
    return {
      outcome: "FAILED",
      error: "You are not authenticated with ROLA!",
      refunded: 0,
    };
  } else {
    let request: MintCollectionRequest = {
      authentication_token: authenticationToken,
      collection: collection,
      trader_card: trader_card,
    };
    return await callPostEndpoint("/mintCollection", request);
  }
}

export async function createCollectionFromAddress(
  collection_address: ResourceAddress,
  description?: string,
  background_image_url?: string,
  website_url?: string,
  twitter_url?: string,
  discord_url?: string,
  instagram_url?: string,
): Promise<CreateCollectionFromLedgerResponse> {
  const authenticationToken = getRolaToken();
  if (!authenticationToken) {
    console.error("You are not authenticated");
    return;
  } else {
    let request: CreateCollectionFromLedgerRequest = {
      authentication_token: authenticationToken,
      background_image_url: background_image_url,
      collection_address: collection_address,
      description: description,
      discord_url: discord_url,
      instagram_url: instagram_url,
      twitter_url: twitter_url,
      website_url: website_url,
    };

    return await callPostEndpoint("/createCollectionFromAddress", request);
  }
}

export async function getCollectionFromAddress(
  collection_address: ResourceAddress,
): Promise<GetCollectionFromAddressResponse> {
  let request: GetCollectionFromAddressRequest = {
    address: collection_address,
  };
  return await callPostEndpoint("/getCollectionFromAddress", request);
}
