import Skeleton from "react-loading-skeleton";

interface CollectionStatsProps {
  label: string,
  value: string | undefined,
  isLoading: boolean,
}

export default function CollectionStatsItem({ label, value, isLoading }: CollectionStatsProps) {
  return (
        <div className="px-4 md:px-6 border-r border-solid border-r-[#F3F3F3] last:border-0">
          <span className="uppercase block text-xs text-[#93989A]">
            {label}
          </span>
          { isLoading ? (
            <Skeleton />
            ) : (
            <span className="text-[28px] font-semibold">{value}</span>
            )}
        </div>
      )
    }
