import { useQuery } from 'react-query';

import actions  from '../../backend/collections/CollectionGetters';

const useFetchCollections = (category?: string) => {
  return useQuery(
    ['fetchCollections'],
    async () => {
      const collections = await actions.fetchCollections(category);
      return collections;
    },
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
    },
  );
};

export default useFetchCollections;