import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import FilterList from "./FilterList";
import { IoCloseOutline } from "react-icons/io5";

export default function FilterDrawer({
  isMobileFilterOpen,
  onClose,
}: {
  isMobileFilterOpen: boolean;
  onClose: () => void;
}) {
  return (
    <Drawer
      open={isMobileFilterOpen}
      onClose={onClose}
      direction="left"
      className="!w-[310px] px-2 overflow-y-auto"
    >
      <div className="mt-2 px-2">
        <IoCloseOutline className="w-6 h-6 ml-auto" onClick={onClose} />
      </div>
      {/* <FilterList /> */}
    </Drawer>
  );
}
