import { ReactComponent as HomeIcon } from "../assets/sidebarMenu/homeIcon.svg";
import { ReactComponent as ExploreIcon } from "../assets/sidebarMenu/exploreIcon.svg";
import { ReactComponent as SavedIcon } from "../assets/sidebarMenu/savedIcon.svg";
import { ReactComponent as SettingsIcon } from "../assets/sidebarMenu/settingsIcon.svg";
import { ReactComponent as WalletIcon } from "../assets/sidebarMenu/walletIcon.svg";
import { ReactComponent as MarketIcon } from "../assets/sidebarMenu/marketIcon.svg";
import { ReactComponent as HistoryIcon } from "../assets/sidebarMenu/historyIcon.svg";
import { ReactComponent as ActiveBidIcon } from "../assets/sidebarMenu/activeBidIcon.svg";
import { ReactComponent as CollectionIcon } from "../assets/sidebarMenu/collectionIcon.svg";
import { ReactComponent as ClaimIcon } from "../assets/sidebarMenu/claimIcon.svg"
import SidebarMenuItemType from "../types/SidebarMenuItemType";

const getSidebarMenuItems = (): Array<SidebarMenuItemType> => {
  const sidebarMenuItems: Array<SidebarMenuItemType> = [
    {
      name: "Home",
      Icon: HomeIcon,
      link: "/",
      type: "link",
    },
    {
      name: "Explore",
      Icon: ExploreIcon,
      link: "explore/category/all",
      type: "link",
    },
    /*
    {
      name: "Active Bid",
      Icon: ActiveBidIcon,
      link: "/active-bid",
      type: "link",
    },
    {
      name: "Saved",
      Icon: SavedIcon,
      link: "/saved",
      type: "link",
    },*/
    {
      name: "Account",
      type: "category",
    },
    {
      name: "My Nfts",
      Icon: MarketIcon,
      link: "/user/my-nfts",
      type: "link",
    },
    {
      name: "My Collections",
      Icon: CollectionIcon,
      link: "/collections",
      type: "link",
    },
    {
      name: "Claim resources",
      Icon: WalletIcon,
      link: "/claim",
      type: "link",
    },
    /*{
      name: "Wallet",
      Icon: WalletIcon,
      link: "/wallet",
      type: "link",
    },
    {
      name: "History",
      Icon: HistoryIcon,
      link: "/history",
      type: "link",
    },
    {
      name: "Settings",
      Icon: SettingsIcon,
      link: "/settings",
      type: "link",
    },*/
  ];
  return sidebarMenuItems;
};

export default getSidebarMenuItems;
