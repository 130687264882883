import CollectionStatsItem from "./CollectionStatsItem";

interface CollectionStatsProps {
  items: number;
  owners: number | undefined;
  volume: number | undefined;
  floorPrice: number;
  fee: string;
  isLoading: boolean;
}

export default function CollectionStats({ items, owners, volume, floorPrice, fee, isLoading }: CollectionStatsProps) {
  return (
    <div className="flex flex-wrap gap-4 lg:gap-0 my-8">
        <CollectionStatsItem label='Items' value={items.toString()} isLoading={isLoading} />
        <CollectionStatsItem label='Owners' value={owners? owners.toString(): "None"} isLoading={isLoading} />
        {
            floorPrice !== 0 ? (
                <CollectionStatsItem label='Floor price' value={floorPrice.toString()} isLoading={isLoading} />
            ):(
                <CollectionStatsItem label='Floor price' value={"-"} isLoading={isLoading} />
            )
        }
        <CollectionStatsItem label='Volume' value={volume? volume.toString(): "None"} isLoading={isLoading} />
        <CollectionStatsItem label='Fee' value={fee} isLoading={isLoading} />
    </div>
  );
}
