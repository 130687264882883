import React, { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";

import HomePage from "./pages/HomePage";
import "./App.css";
import "swiper/css";
import SidebarMenu from "./components/global/SidebarMenu/SidebarMenu";
import getSidebarMenuItems from "./utils/getSidebarMenuItems";
import NavbarMenu from "./components/global/NavbarMenu/NavbarMenu.js";
import NftDetailsPage from "./pages/NftDetailsPage";

// Radix Wallet
import { GatewayApiClient } from "@radixdlt/babylon-gateway-api-sdk";
import {
  createLogger,
  DataRequestBuilder,
  RadixDappToolkit,
} from "@radixdlt/radix-dapp-toolkit";
import EditCollection from "./pages/EditCollection";
import CollectionDetails from "./pages/CollectionDetails";
import CollectionCreator from "./pages/CollectionCreator";
import CreateCollectionCreator from "./pages/CreateCollectionCreator";
import UploadJsonCreator from "./pages/UploadJsonCreator";
import EditNft from "./pages/EditNft";
import ClaimPreBabylonNfts from "./pages/ClaimPreBabylonNfts";
import TermsAndConditions from "./pages/TermsAndConditions";
import { getShortCollections } from "./backend/collections/CollectionGetters";
import { shortCollectionsState } from "./state/api/shortCollectionsState";
import {
  backend_url,
  dapp_definition_address,
  gateway_url,
  network_id,
} from "./Constants";
import { connectedWallet } from "./state/global/connectedWallet";
import { updateFromLedger } from "./backend/UpdateBackend";
import ExplorePage from "./pages/ExplorePage";
import MyNftsPage from "./pages/MyNftsPage";
import { deleteToken, isRolaTokenValid, setRolaToken } from "./backend/Rola";
import ImportCollectionPage from "./pages/ImportCollection";

export const gateway_api_url = gateway_url();

export const rdt = RadixDappToolkit({
  dAppDefinitionAddress: dapp_definition_address,
  networkId: network_id,
  gatewayBaseUrl: gateway_api_url,
  logger: createLogger(2),
});

export const gateway = GatewayApiClient.initialize({
  basePath: gateway_api_url,
  applicationName: "RadLand Frontend",
});
function App() {
  rdt.walletApi.setRequestData(
    DataRequestBuilder.accounts().withProof().exactly(1),
  );

  rdt.buttonApi.setMode("light");
  rdt.buttonApi.setTheme("black");

  console.log(
    dapp_definition_address,
    network_id,
    backend_url,
    gateway_api_url,
  );

  const getChallenge: () => Promise<string> = () =>
    fetch(backend_url + "/create-challenge")
      .then((res) => res.json())
      .then((res) => res.challenge);

  rdt.walletApi.provideChallengeGenerator(getChallenge);

  rdt.walletApi.dataRequestControl(async ({ proofs }) => {
    const { valid, authenticationToken }: any = await fetch(
      backend_url + "/verify",
      {
        method: "POST",
        body: JSON.stringify(proofs),
        headers: { "content-type": "application/json" },
      },
    ).then((res): Promise<{ valid: boolean }> => res.json());

    console.log(valid, authenticationToken);

    if (valid) {
      setRolaToken(authenticationToken);
    } else {
      rdt.disconnect();
    }
  });

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const queryClient = new QueryClient();
  const setConnectedWallet = useSetRecoilState(connectedWallet);
  const setShortCollections = useSetRecoilState(shortCollectionsState);

  useEffect(() => {
    isRolaTokenValid().then((valid) => {
      if (!valid) {
        console.log("Could not find a rola token");
        deleteToken();
        rdt.disconnect();
      }
    });
  }, []);

  useEffect(() => {
    updateFromLedger();
    shortCollectionsGetter();
  });
  async function shortCollectionsGetter() {
    const result = await getShortCollections();
    let collections = result.collections.map((collection) => {
      return {
        id: collection.id,
        name: collection.name,
        creator: collection.owner,
        imgUrl: collection.icon_url,
        itemsCount: collection.item_amount,
      };
    });
    setShortCollections(collections);
  }

  const handleToggleSidebarClick = (): void => {
    setIsSidebarOpen((isSidebarOpen) => !isSidebarOpen);
  };

  const subscription = rdt.walletApi.walletData$.subscribe((walletData) => {
    setConnectedWallet(walletData);
  });

  return (
    <QueryClientProvider client={queryClient}>
      <div className="h-full relative w-full flex justify-between font-poppins bg-grey-medium max-w-[1920px] m-auto">
        <Router>
          <div
            className={`${
              isSidebarOpen ? "w-[300px]" : "w-[65px]"
            } hidden z-[200] md:flex sticky top-0 h-screen`}
          >
            <SidebarMenu
              isOpen={isSidebarOpen}
              sidebarMenuItems={getSidebarMenuItems()}
              toggleSidebarOpen={handleToggleSidebarClick}
            />
          </div>
          <div className="w-full flex flex-col overflow-x-clip">
            <div className="sticky top-0 z-[100]">
              <NavbarMenu />
            </div>

            <Routes>
              <Route path="/" Component={HomePage} />
              <Route path="/collections/" Component={CollectionCreator} />
              <Route
                path="/collections/create/"
                Component={CreateCollectionCreator}
              />
              <Route
                path="/collections/import/"
                Component={ImportCollectionPage}
              />
              <Route
                path="/collections/edit/:collectionId"
                Component={EditCollection}
              />
              <Route
                path="/collections/:collectionId"
                Component={CollectionDetails}
              />
              <Route
                path="/collections/:id/upload-json"
                Component={UploadJsonCreator}
              />
              <Route
                path="/collections/:collection_id/:item_id/edit"
                Component={EditNft}
              />
              <Route
                path="/collections/:collection_id/:item_id"
                Component={NftDetailsPage}
              />
              <Route path="/claim" Component={ClaimPreBabylonNfts} />
              <Route
                path="/explore/category/:category"
                Component={ExplorePage}
              />
              <Route path="/user/my-nfts" Component={MyNftsPage} />
              <Route
                path="/terms-and-conditions"
                element={<TermsAndConditions />}
              />
            </Routes>
          </div>
        </Router>
      </div>
    </QueryClientProvider>
  );
}

export default App;
