import { useState } from "react";
import {IoChevronDown, IoChevronUp} from "react-icons/io5";
import Skeleton from "react-loading-skeleton";

const NFTDetailsItem: React.FC<{
  isLoading: boolean,
  children: string | JSX.Element | any,
  isOpen: boolean,
  containerStyles?: string,
  title: string,
}> = ({children, isLoading, isOpen, containerStyles, title}) => {
  const [isItemOpen, setIsItemOpen] = useState<boolean>(isOpen);

  return (
    <div className={`flex flex-col`}>
      <div className="flex justify-between items-baseline py-4 p-4 border-b border-grey-medium">
        <p className="text-black text-xl font-semibold">{title}</p>
        {isItemOpen ? (
          <IoChevronUp onClick={() => setIsItemOpen(!isItemOpen)} className="text-black text-2xl cursor-pointer" />
        ) : (
          <IoChevronDown onClick={() => setIsItemOpen(!isItemOpen)} className="text-black text-2xl cursor-pointer" />
        )}

      </div >
      {isLoading ? (
        <div className={`rounded-md p-4 ${isItemOpen ? '' : 'hidden'}`}>
        <Skeleton height={80} />
        </div>
      ) : (
        <div className={`${containerStyles} ${isItemOpen ? '' : 'hidden'} border-b border-grey-medium`}>
        {children}
        </div>
      )}
    </div>
  )
};

export default NFTDetailsItem;