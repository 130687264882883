import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation, Autoplay} from "swiper";
import {MdChevronRight, MdChevronLeft} from "react-icons/md";
import CollectionCardFull from "../CollectionCardFull.tsx/CollectionCardFull";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";



const CollectionSliderFull: React.FC<{
  collections: Array<any> | undefined,
  isLoading?: boolean,
}> = ({collections, isLoading}) => {
    const filteredCollections = collections?.filter((collection) => collection.background_image.length > 0)
  return (
    <div className="relative w-full overflow-hidden">
    <Swiper
      modules={[Navigation, Autoplay]}
      navigation={{
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }}
      autoplay={true}
      spaceBetween={50}
      centeredSlides={true}
      slidesPerView={1}
      loop={true}
    >
      {filteredCollections &&
        !isLoading &&
        filteredCollections.map((collection, index) => (
          <SwiperSlide key={index} className="overflow-hidden">
            <div className="w-full rounded-[14px] ">
              <CollectionCardFull
                id={collection.id}
                name={collection.data.name}
                volume={collection.stats.volume}
                backgroundUrl={collection.background_image}
              />
            </div>
          </SwiperSlide>
        ))}
      {isLoading && (
        <SwiperSlide>
           <SkeletonTheme baseColor="#d6d4d4" highlightColor="#edebeb">
            <Skeleton height={400} borderRadius={14} />
          </SkeletonTheme>
        </SwiperSlide>
      )}
    </Swiper>
  
    {/* Navigation buttons inside the Swiper */}
    <div className="absolute top-[45%] -left-0 z-50">
      <div className="w-[48px] h-[48px] border-solid border-[#CFDBD599] rounded-full bg-grey-light text-secondaryBlack swiper-button-prev cursor-pointer flex justify-center items-center">
        <MdChevronLeft className="text-2xl md:text-4xl" />
      </div>
    </div>
    <div className="absolute top-[45%] -right-0 z-50">
      <div className="w-[48px] h-[48px] border-solid border-[#CFDBD599] rounded-full bg-grey-light text-secondaryBlack swiper-button-next cursor-pointer flex justify-center items-center">
        <MdChevronRight className="text-2xl md:text-4xl" />
      </div>
    </div>
  </div>
  
  )
};

export default CollectionSliderFull;