
import Tags from "../utils/Tags";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {NFTCollection, CollectionData, default_collection} from "../../types/Collection";
import {getCurrentUser, getNewResourceFromTransaction} from "../../ledger/Global";
import {createCollectionBadge} from "../../ledger/collections/CreateCollectionBadge";
import RadixWalletModal from "../modals/RadixWalletModal";
import ErrorModal from "../modals/ErrorModal";
import {radland_admin_badge} from "../../Constants";
import {createCollection} from "../../backend/collections/CollectionUpdates";
import {updateFromLedger} from "../../backend/UpdateBackend";

const tagOptions = ["3D", "Art", "Modern", "Futuristic", "Abstract", "Anime"];

export default function CreateCollectionForm() {

  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [icon, setIcon] = useState<string>('');
  const [banner, setBanner] = useState<string>('');
  const [website, setWebsite] = useState<string>('');
  const [twitter, setTwitter] = useState<string | undefined>(undefined);
  const [instagram, setInstagram] = useState<string | undefined>(undefined);
  const [discord, setDiscord] = useState<string | undefined>(undefined);
  const [tags, setTags] = useState<string[]>(["NFT", "Collectible"]);
  const [collection, setCollection] = useState<NFTCollection>(default_collection);

  const [isRadixWalletModalOpen, setIsRadixWalletModalOpen] = useState<boolean>(false);
  const [walletMessage, setWalletMessage] = useState<string>("");
  const [walletTitle, setWalletTitle] = useState<string>("")
  const [status, setStatus] = useState<"success" | "error" | "loading">(
      "loading"
  );

  const [isErrorModalOpen, setIsErrorModalOpen] = useState<boolean>(false);
  const [errorTitle, setErrorTitle] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const navigate = useNavigate();

  const handleSubmit = async () => {

    let collection_data: CollectionData = {
      name: name,
      description: description,
      tags: tags,
      icon_url: icon,
      info_url: website
    };

    let new_collection = collection;
    new_collection.data = collection_data;
    new_collection.owner = getCurrentUser();
    new_collection.background_image = banner;
    new_collection.twitter_url = twitter;
    new_collection.discord_url = discord;
    new_collection.instagram_url = instagram;
    new_collection.stats.owners = {};
    new_collection.radland_admin_badge = radland_admin_badge;

    setCollection(new_collection);
    if(collection_data.name === ""){
      setIsErrorModalOpen(true)
      setErrorTitle("Cannot create collection")
      setErrorMessage("Please choose a name for your collection!")
    }
    else{
      if(getCurrentUser() === ""){
        setIsErrorModalOpen(true)
        setErrorTitle("Cannot create collection")
        setErrorMessage("Please connect your wallet!")
      }
      else{
        makeTransaction()
      }
    }
  };


  async function makeTransaction() {
    setIsRadixWalletModalOpen(true)
    setWalletTitle("Minting your collection admin badge")
    setWalletMessage("This badge gives you control over your collection, and allows you to revoke RadLand access to minting items.")
    setStatus("loading");
    let receipt = await createCollectionBadge(getCurrentUser(), collection);
    switch (receipt.outcome) {
      case "SUCCESS": {
        if (receipt.info.transaction_status === "CommittedSuccess") {
          setStatus("success");
          setWalletTitle("Transaction succeeded!")
          setWalletMessage(`Successfully minted your admin badge!`);
          collection.user_admin_badge = getNewResourceFromTransaction(receipt.info);
          await createCollection(collection);
          navigate("/collections")
        }
        break;
      }

      case "FAILED": {
        setStatus("error")
        setWalletTitle("Transaction failed!")
        setWalletMessage(`Transaction failed with error: ${receipt.error}.`)
        break;
      }

      case "TIMEOUT": {
        setStatus("error");
        setWalletTitle("Unable to get answer from wallet!")
        setWalletMessage("Interaction with wallet has timed out")
        await updateFromLedger();
      }
    }
  }


  const handleChangeName = (e:any) => {
    setName(e.target.value);
  };

  const handleChangeDescription = (e:any) => {
    setDescription( e.target.value );
  };

  const handleChangeIcon = (e:any) => {
    setIcon( e.target.value );
  };

  const handleChangeBanner = (e:any) => {
    setBanner( e.target.value );
  };

  const handleChangeWebsite = (e:any) => {
    setWebsite( e.target.value );
  };

  const handleChangeTwitter = (e:any) => {
    if( e === ""){
      setTwitter(undefined);
    }
    else{
      setTwitter( e.target.value );
    }
  };

  const handleChangeDiscord = (e:any) => {
    if( e === ""){
      setDiscord(undefined);
    }
    else{
      setDiscord( e.target.value );
    }
  };

  const handleChangeInstagram = (e:any) => {
    if( e === ""){
      setInstagram(undefined);
    }
    else{
      setInstagram( e.target.value );
    }
  };

  return (
    <form
      className="w-full max-w-[608px] mx-auto p-2 mt-[48px]"
    >
      <p className="text-text-gray text-xs font-medium w-full">
        <span className="text-[#FD1616]">*</span> are required fields
      </p>

      <div className="w-full mt-3">
        <label className="mb-1 font-bold" htmlFor="collectionName">
          Collection Name <span className="text-[#FD1616]">*</span>
        </label>
        <input
          value={name}
          onChange={handleChangeName}
          type="text"
          id="collectionName"
          name="collectionName"
          placeholder="Enter collection name"
          className="w-full mt-1 px-[20px] py-[16px] placeholder:text-[#A6A9B9] border border-solid border-[#F3F3F3] outline-none focus:outline-none rounded-lg box-border"
        />
      </div>

      <div className="mt-[24px]">
        <label className="font-bold" htmlFor="description">
          Description
        </label>
        <p className="my-2 text-sm text-[#93989A]">
          The description of the collection will be shown on the detail page,
          below the image. Markdown is allowed.
        </p>

        <textarea
          value={description}
          onChange={handleChangeDescription}
          name="description"
          id="description"
          placeholder="Write a description of your collection here"
          className="min-h-[180px] w-full px-[20px] py-[16px] placeholder:text-[#A6A9B9] border border-solid border-[#F3F3F3] outline-none focus:outline-none rounded-lg"
        ></textarea>
      </div>

      <div className="w-full mt-3">
        <label className="mb-1 font-bold" htmlFor="collectionName">
          Collection Icon
        </label>
        <p className="my-2 text-sm text-[#93989A]">
          {"Icon representing the collection that will be displayed in wallet and on the collection presentation page. (240x190)"}
        </p>
        <input
            value={icon}
            onChange={handleChangeIcon}
            type="text"
            id="collectionName"
            name="collectionName"
            placeholder="Collection’s icon URL"
            className="w-full mt-1 px-[20px] py-[16px] placeholder:text-[#A6A9B9] border border-solid border-[#F3F3F3] outline-none focus:outline-none rounded-lg box-border"
        />
      </div>

      <div className="w-full mt-3">
        <label className="mb-1 font-bold" htmlFor="collectionName">
          Collection Banner <span className="text-[#FD1616]"></span>
        </label>
        <p className="my-2 text-sm text-[#93989A]">
          {"Banner to be displayed on the collection presentation page. (1920x250)"}
        </p>
        <input
            value={banner}
            onChange={handleChangeBanner}
            type="text"
            id="collectionName"
            name="collectionName"
            placeholder="Collection’s banner URL"
            className="w-full mt-1 px-[20px] py-[16px] placeholder:text-[#A6A9B9] border border-solid border-[#F3F3F3] outline-none focus:outline-none rounded-lg box-border"
        />
      </div>

      <div className="mt-[24px]">
        <label className="font-bold">
          Tags
        </label>
        <div className="mt-2">
          <Tags options={tagOptions} tags={tags} setTags={setTags} />
        </div>
      </div>

      <div className="mt-[24px]">
        <label className="font-bold" htmlFor="description">
          Website
        </label>
        <p className="my-2 text-sm text-[#93989A]">
          We will provide a link to this URL on the item's detail page so that
          users can click on it to learn more. You can also link to your own
          webpage with more details.
        </p>
        <input
          value={website}
          onChange={handleChangeWebsite}
          type="url"
          id="website"
          name="website"
          placeholder="Collection’s website URL"
          className="w-full mt-1 px-[20px] py-[16px] placeholder:text-[#A6A9B9] border border-solid border-[#F3F3F3] outline-none focus:outline-none rounded-lg box-border"
        />
      </div>

      <div className="mt-[24px]">
        <label className="font-bold" htmlFor="username">
          Twitter
        </label>

        <div className="mt-1 w-full flex items-center gap-x-1 px-[20px] py-[16px] border border-solid border-[#F3F3F3] rounded-lg box-border">
          <p className="text-[#0404094D]">twitter.com/</p>
          <input
            value={twitter == null ? "" : twitter}
            onChange={handleChangeTwitter}
            type="text"
            id="username"
            name="username"
            placeholder="username"
            className="w-full border-none outline-none focus:outline-none"
          />
        </div>
      </div>

      <div className="mt-[24px]">
        <label className="font-bold" htmlFor="username">
          Instagram
        </label>

        <div className="mt-1 w-full flex items-center gap-x-1 px-[20px] py-[16px] border border-solid border-[#F3F3F3] rounded-lg box-border">
          <p className="text-[#0404094D]">instagram.com/</p>
          <input
            value={instagram == null ? "" : instagram}
            onChange={handleChangeInstagram}
            type="text"
            id="username"
            name="username"
            placeholder="username"
            className="w-full border-none outline-none focus:outline-none"
          />
        </div>
      </div>

      <div className="mt-[24px]">
        <label className="font-bold" htmlFor="username">
          Discord
        </label>

        <div className="mt-1 w-full flex items-center gap-x-1 px-[20px] py-[16px] border border-solid border-[#F3F3F3] rounded-lg box-border">
          <p className="text-[#0404094D]">discord.com/</p>
          <input
            value={discord == null ? "" : discord}
            onChange={handleChangeDiscord}
            type="text"
            id="username"
            name="username"
            placeholder="username"
            className="w-full border-none outline-none focus:outline-none"
          />
        </div>
      </div>

      <RadixWalletModal
          isOpen={ isRadixWalletModalOpen }
          setIsOpen={ setIsRadixWalletModalOpen }
          title={walletTitle}
          message={walletMessage}
          status={status}
      />
      <ErrorModal
          isOpen={isErrorModalOpen}
          setIsOpen={setIsErrorModalOpen}
          message={errorMessage}
          title={errorTitle}
      />

      {/* submit btns */}
      <div className="w-full flex flex-col md:flex-row items-center gap-6  mt-[32px]">
        <button
          className="w-full px-[24px] py-[16px] font-medium text-white bg-[#535AFA] flex items-center justify-center rounded-lg"
          type="button"
          onClick={handleSubmit}
        >
          Create Collection
        </button>
      </div>
    </form>
  );
}
