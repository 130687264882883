import { Tab } from "../../types/utils.components";

interface TabsProps {
  tabs: Tab[];
  activeTab: number;
  setActiveTab: (tab: number) => void;
  tabBarClassName?: string;
  tabsContainerClassName?: string;
}

export default function Tabs({
  tabs,
  activeTab,
  setActiveTab,
  tabBarClassName,
  tabsContainerClassName,
}: TabsProps) {
  return (
    <>
      <div
        className={`p-[4px] bg-[#F3F3F3] rounded-[10px] ${tabBarClassName} `}
      >
        <div
          className={`w-full flex items-center justify-center ${tabsContainerClassName}`}
        >
          {tabs.map((tab) => (
            <button
              key={tab.id}
              onClick={() => setActiveTab(tab.id)}
              className={`w-full h-full p-1 first:rounded-l-[10px] last:rounded-r-[10px] font-medium text-sm ${
                activeTab === tab.id ? "bg-white" : "bg-transparent"
              }`}
            >
              {tab.label}
            </button>
          ))}
        </div>
      </div>
      <div className="w-full h-full">{tabs[activeTab].content}</div>
    </>
  );
}
