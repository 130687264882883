// import { IoCheckmarkDoneOutline } from "react-icons/io5";

export default function WalletAddress({
  walletAddress,
}: {
  walletAddress: string;
}) {
  return (
    <div className="w-[146px] h-[29px] px-[10px] py-[4px] rounded-[40px] border border-solid border-[#F3F3F3] flex items-center justify-between gap-x-2">
      <p className="text-[#93989A] text-sm">{`${walletAddress?.substring(
        0,
        7
      )}...${walletAddress?.substring(walletAddress.length - 4)}`}</p>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        className="cursor-pointer"
        onClick={() => {
          navigator.clipboard.writeText(walletAddress);
        }}
      >
        <path
          d="M12.9333 13.8333H6.4C6.29391 13.8333 6.19217 13.7912 6.11716 13.7162C6.04214 13.6412 6 13.5394 6 13.4333V6.9C6 6.79391 6.04214 6.69217 6.11716 6.61716C6.19217 6.54214 6.29391 6.5 6.4 6.5H12.9333C13.0394 6.5 13.1412 6.54214 13.2162 6.61716C13.2912 6.69217 13.3333 6.79391 13.3333 6.9V13.4333C13.3333 13.5394 13.2912 13.6412 13.2162 13.7162C13.1412 13.7912 13.0394 13.8333 12.9333 13.8333Z"
          stroke="#040409"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10 6.49996V3.56663C10 3.46054 9.95788 3.3588 9.88286 3.28378C9.80785 3.20877 9.70611 3.16663 9.60002 3.16663H3.06669C2.9606 3.16663 2.85886 3.20877 2.78384 3.28378C2.70883 3.3588 2.66669 3.46054 2.66669 3.56663V10.1C2.66669 10.206 2.70883 10.3078 2.78384 10.3828C2.85886 10.4578 2.9606 10.5 3.06669 10.5H6.00002"
          stroke="#040409"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
}
