import {useNavigate} from "react-router";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation, Autoplay} from "swiper";
import {MdChevronRight, MdChevronLeft} from "react-icons/md";
import CollectionCard from "../CollectionCard/CollectionCard";
import SkeletonCollectionCard from "../CollectionCard/SkeletonCollectionCard";
import {ShortCollection} from "../../../types/Collection";

const CollectionSlider: React.FC<{
  title: string,
  collections: Array<any> | undefined,
  isLoading?: boolean,
  category: string,
  showMore?: boolean,
}> = ({collections, title, category, isLoading, showMore=true}) => {
  const navigate = useNavigate();
  return (
    <div className="relative w-full overflow-x-hidden">
      <div className="flex justify-between w-full items-center">
      <p className="text-left font-semibold text-black-base text-[24px] mb-[10px] ml-[15px]">{title}</p>
      </div>
       <div className={`w-[48px] h-[48px] border-solid border-[#CFDBD599] z-50 rounded-full bg-grey-light swiper-button-prev-${category} text-secondaryBlack flex justify-center items-center cursor-pointer absolute top-[45%] -left-0`}>
          <MdChevronLeft className="text-2xl md:text-4xl" />
        </div>
        <div className={`w-[48px] h-[48px] border-solid border-[#CFDBD599] z-50 rounded-full bg-grey-light text-secondaryBlack swiper-button-next-${category} flex justify-center items-center cursor-pointer absolute top-[45%] -right-0`}>
          <MdChevronRight className="text-2xl md:text-4xl" />
        </div>
      <Swiper
        spaceBetween={20}
        modules={[Navigation, Autoplay]}
        slidesPerView="auto"
        navigation={{
          nextEl: `.swiper-button-next-${category}`,
          prevEl: `.swiper-button-prev-${category}`,
        }}
        loop={false}
        breakpoints={{
          320: {
            width: 320,
            slidesPerView: 1,
            spaceBetween: 32,
          },
          560: {
            width: 560,
            slidesPerView: 2.2,
          },
          840: {
            width: 810,
            slidesPerView: 3.2,
          },
          1100: {
            width: 1100,
            slidesPerView: 4.2,
          },
          1400: {
            width: 1400,
            slidesPerView: 5.2,
            spaceBetween: 30,
          },
          1700: {
            width: 1700,
            slidesPerView: 6.2,
            spaceBetween: 30,
          },
        }}
      >
      {collections && !isLoading && collections.map((collection, index) => (
        <SwiperSlide key={index}>
          <div className="w-[310px] rounded-[14px]">
            <CollectionCard
              id={collection.id}
              name={collection.name}
              creator={"By "+collection.creator.slice(0,14) + "..."+collection.creator.slice(-5,-1)}
              backgroundUrl={collection.backgroundUrl}
            />
          </div>
        </SwiperSlide>
      ))}
      { isLoading && [...Array(6)].map((collection, index) => (
        <SwiperSlide key={index}>
          <div className="w-[310px] rounded-[14px]">
            <SkeletonCollectionCard />
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  </div>
  )
};

export default CollectionSlider;