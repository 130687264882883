// TermsAndConditions.jsx
import React from 'react';

const TermsAndConditions = () => {
  return (
    <div className="py-10 px-8 md:py-20 md:px-16 bg-grey-light text-black-base font-poppins">
      <h1 className="text-4xl font-bold mb-6">RadLand Terms and Conditions</h1>

      {/* Acceptance of Terms */}
      <section className="mb-6">
        <h2 className="text-3xl font-semibold mb-4">Acceptance of Terms</h2>
        <p>
          By engaging with RadLand's services, you agree to these Terms & Conditions. If you disagree, refrain from using our platform.
        </p>
      </section>

      {/* Services Description */}
      <section className="mb-6">
        <h2 className="text-3xl font-semibold mb-4">Services Description</h2>
        <p>
          RadLand provides innovative smart contract solutions within Radix Distributed Ledger Technology, offered by the RadLand development team.
        </p>
      </section>

      {/* Risk Acknowledgment */}
      <section className="mb-6">
        <h2 className="text-3xl font-semibold mb-4">Risk Acknowledgment</h2>
        <p>
          You acknowledge all risks associated with digital asset transactions on RadLand, including technical and market risks that may result in financial losses.
        </p>
      </section>

      {/* Jurisdictional Compliance */}
      <section className="mb-6">
        <h2 className="text-3xl font-semibold mb-4">Jurisdictional Compliance</h2>
        <p>
          Ensure your use of RadLand adheres to all applicable laws and regulations, especially securities laws in your jurisdiction.
        </p>
      </section>

      {/* Intellectual Property */}
      <section className="mb-6">
        <h2 className="text-3xl font-semibold mb-4">Intellectual Property Rights</h2>
        <p>
          Content on RadLand is proprietary and protected by intellectual property laws.
        </p>
      </section>

      {/* Amendments to Terms */}
      <section className="mb-6">
        <h2 className="text-3xl font-semibold mb-4">Amendments to Terms</h2>
        <p>
          These Terms & Conditions may be updated periodically, with the latest version posted on our website.
        </p>
      </section>

      {/* RadLand Disclaimer */}
      <h1 className="text-4xl font-bold mb-6">RadLand Disclaimer</h1>
      <p className="mb-4">
        As a user, you assume full responsibility for any losses, damages, or liabilities that may arise from your use of RadLand. This includes engagements with our smart contracts and any related services. RadLand and its team disclaim liability for such outcomes, whether direct or indirect.
      </p>
      <p className="mb-4">
        We advise prudent engagement with only assets you are willing to risk. RadLand, currently in 'Beta', is under constant development, and thus, cannot ensure the absolute accuracy or reliability of the information provided.
      </p>
      <p className="mb-4">
        It is your responsibility to understand and adhere to all applicable laws and regulations, including those from jurisdictions with strict regulations like the United States. We encourage seeking independent legal advice if you are uncertain about the legality of your activities on RadLand.
      </p>
      <p className="mb-4">
        By using RadLand, you agree to indemnify and hold harmless RadLand, its team, and affiliates from any repercussions, including financial losses or legal claims, that may result from your interactions with our services or third-party engagements facilitated through our platform.
      </p>
      <p className="mb-4">
        Any changes to these terms and the disclaimer will be communicated through our website. Your continued use of RadLand signifies acceptance of the updated terms and disclaimer.
      </p>
    </div>
  );
};

export default TermsAndConditions;
