import { useEffect } from "react";
import { TabAlt } from "../../types/utils.components";

interface TabsAltProps {
  tabs: TabAlt[];
  activeTab: number;
  setActiveTab: (tab: number) => void;
}

export default function TabsAlt({
  tabs,
  activeTab,
  setActiveTab,
}: TabsAltProps) {

  return (
    <>
      <div className="w-full h-full flex flex-wrap items-center gap-x-4 border-b border-solid border-b-[#F3F3F3]">
        {tabs.map((tab, i) => (
          <button
            key={tab.id}
            onClick={() => {
              setActiveTab(tab.id);
            }}
            className={`text-sm font-semibold cursor-pointer p-4 border-b-2  hover:text-text-baseDark ${
              !(activeTab === tab.id)
                ? "text-[#93989A] border-b-transparent"
                : "text-text-baseDark border-b-black-base"
            }`}
          >
            {tab.label}
          </button>
        ))}
      </div>

      <div className="w-full h-full">{tabs[activeTab].content}</div>
    </>
  );
}
