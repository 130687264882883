import { useNavigate } from "react-router";
import {NFTCollection} from "../../types/Collection";

export default function CollectionBrief({ collection }: { collection: NFTCollection }) {
  const navigate = useNavigate();
  return (
    <tr
      key={collection.id}
      className="border-b border-solid border-b-[#F3F3F3] text-left cursor-pointer"
      onClick={()=>navigate("../collections/"+collection.id)}
    >
      <td className="px-1 py-[20px]">
        <div className="w-full h-full flex items-center gap-x-2 md:gap-x-[14px]">
          <img
            src={collection.data.icon_url === "" ? "https://i.ibb.co/VLCGQRd/Avatar.jpg" : collection.data.icon_url}
            alt={collection.data.name}
            className="w-[42px] h-[42px] rounded-full object-cover"
          />
          <p className="font-semibold">{collection.data.name}</p>
          {collection.address === undefined && (
            <button className="rounded-lg bg-[#535AFA] w-[50px] h-[32px] text-xs text-white cursor-text">
              Draft
            </button>
          )}
        </div>
      </td>

      <td className="text-sm font-medium">
        <div className="flex items-center gap-x-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <path
              d="M7.13837 15C6.87277 15 6.62029 14.8774 6.46195 14.6664L3.15603 10.2409H1V8.62973H3.58253C3.85069 8.62973 4.10189 8.75356 4.25888 8.96337L6.96045 12.5788L11.0828 3.48275C11.2162 3.18954 11.5158 3 11.8468 3H17V4.61121H12.3923L7.9024 14.5173C7.78202 14.7826 7.52429 14.9646 7.22598 14.9949C7.19853 14.9987 7.16845 15 7.13837 15Z"
              fill="#535AFA"
            />
          </svg>
          <span>{collection.stats.volume}</span>
        </div>
      </td>

      <td className=" text-[#42A846] font-medium text-sm">{collection.stats.daily_volume}</td>
      <td className="text-sm font-medium hidden md:table-cell">
        <div className="flex items-center gap-x-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <path
              d="M7.13837 15C6.87277 15 6.62029 14.8774 6.46195 14.6664L3.15603 10.2409H1V8.62973H3.58253C3.85069 8.62973 4.10189 8.75356 4.25888 8.96337L6.96045 12.5788L11.0828 3.48275C11.2162 3.18954 11.5158 3 11.8468 3H17V4.61121H12.3923L7.9024 14.5173C7.78202 14.7826 7.52429 14.9646 7.22598 14.9949C7.19853 14.9987 7.16845 15 7.13837 15Z"
              fill="#535AFA"
            />
          </svg>
          <span>{collection.stats.floor_price}</span>
        </div>
      </td>
      <td className="font-medium text-sm hidden md:table-cell">{Object.keys(collection.stats.owners).length}</td>
      <td className="font-medium text-sm hidden md:table-cell">{collection.stats.items}</td>
    </tr>
  );
}
