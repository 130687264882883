import {useState} from "react";
import {CollectionItem} from "../../types/Collection";
import ErrorModal from "../modals/ErrorModal";
import {updateItem} from "../../backend/collections/ItemUpdates";
import {useNavigate} from "react-router-dom";
import {xrd_address} from "../../Constants";

interface EditNftFormProps {
    item: CollectionItem
}

export default function EditNftForm({
                                        item
                                    }: EditNftFormProps) {
    const [traits, setTraits] = useState<string[]>(traitsVec(item.item_data.traits));
    const [newTrait, setNewTrait] = useState<string>("");
    const [isErrorOpen, setIsErrorOpen] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [errorTitle, setErrorTitle] = useState<string>("");
    const [price, setPrice] = useState<number>(-1);
    const [name, setName] = useState<string>("");
    const [imageURL, setImageURL] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const navigate = useNavigate();

    function traitsVec(traits: { [key: string]: string }): string[]{

        let string_vec = []
        for (let key in traits) {
            let new_string = `${key}: ${traits[key]}`
            string_vec.push(new_string)
        }
        return string_vec
    }

    function traitsMap(traits: string[]): { [key: string]: string } {
        let string_map: { [key: string]: string } = {};
        traits.forEach(item => {
            const key = item.split(":")[0].replace(' ', '');
            let value = item.split(":")[1].replace(' ', '');
            if (value) {
                string_map[key] = value;
            }
        })
        return string_map
    }

    const handleAddTrait = () => {
        if (newTrait) {
            setTraits(traits.concat(newTrait));
            setNewTrait("");
        }
    };

    async function handleClick() {
        if (item.minted) {
            setIsErrorOpen(true)
            setErrorTitle("Cannot edit data of minted item")
            setErrorMessage("An NFT is immutable.")
        } else {
            if (name !== "") {
                item.item_data.name = name
            }
            if (description !== "") {
                item.item_data.description = description
            }
            if (imageURL !== "") {
                item.item_data.image_url = imageURL
            }
            if (traits.length > 0) {
                item.item_data.traits = traitsMap(traits)
            }
            price > 0 ? item.proposal = {
                id: -1,
                payment: {address: xrd_address, amount: price}
            } : item.proposal = undefined;
            await updateItem(item);
            navigate(`/collections/${item.collection_id}/${item.item_data.id}`);
        }
    }

    return (
        <form className="w-full max-w-[608px] mx-auto p-2 mt-[32px]">
            <p className="text-text-gray text-xs font-medium w-full">
                Not edited fields will remain the same
            </p>

            <div className="w-full mt-2">
                <label className="mb-1 font-bold" htmlFor="name">
                    NFT Name
                </label>
                <input
                    type="text"
                    id="name"
                    name="name"
                    onChange={(e) => {
                        setName(e.target.value)
                    }}
                    placeholder={item.item_data.name? item.item_data.name: "NFT Name"}
                    className="w-full mt-1 px-[20px] py-[16px] placeholder:text-[#A6A9B9] border border-solid border-[#F3F3F3] outline-none focus:outline-none rounded-lg box-border"
                />
            </div>

            <div className="mt-[24px]">
                <label className="font-bold" htmlFor="description">
                    Description
                </label>
                <p className="my-2 text-sm text-[#A6A9B9]">
                    The description of the item will be shown on the detail page, below
                    the image. Markdown is allowed.
                </p>

                <textarea
                    name="description"
                    id="description"
                    placeholder={item.item_data.description? item.item_data.description : "Write a description of your item here"}
                    onChange={(e) => {
                        setDescription(e.target.value)
                    }}
                    className="min-h-[180px] w-full px-[20px] py-[16px] placeholder:text-[#A6A9B9] border border-solid border-[#F3F3F3] outline-none focus:outline-none rounded-lg"
                ></textarea>
            </div>

            <div className="w-full mt-2">
                <label className="mb-1 font-bold" htmlFor="name">
                    Image URL
                </label>
                <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder={item.item_data.image_url ? item.item_data.image_url : "Image URL"}
                    onChange={(e) => {
                        setImageURL(e.target.value)
                    }}
                    className="w-full mt-1 px-[20px] py-[16px] placeholder:text-[#A6A9B9] border border-solid border-[#F3F3F3] outline-none focus:outline-none rounded-lg box-border"
                />
            </div>

            <div className="mt-[24px]">
                <label className="font-bold">
                    Price <span className="text-[#FD1616]"></span>
                </label>
                <div className="mt-2 px-[20px]  border border-[#F3F3F3] rounded-lg flex max-w-[429px]">
                    <input
                        type="number"
                        placeholder={item.proposal ? item.proposal.payment.amount.toString() : "Amount"}
                        onChange={(e) => {
                            setPrice(parseFloat(e.target.value))
                        }}
                        className="placeholder:text-[#A6A9B9] py-[16px] flex-grow"
                    />
                    <div className="w-[79px] flex items-center gap-x-[6px] pl-[20px] border-l border-l-[#F3F3F3]">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                        >
                            <path
                                d="M7.13837 15C6.87277 15 6.62029 14.8774 6.46195 14.6664L3.15603 10.2409H1V8.62973H3.58253C3.85069 8.62973 4.10189 8.75356 4.25888 8.96337L6.96045 12.5788L11.0828 3.48275C11.2162 3.18954 11.5158 3 11.8468 3H17V4.61121H12.3923L7.9024 14.5173C7.78202 14.7826 7.52429 14.9646 7.22598 14.9949C7.19853 14.9987 7.16845 15 7.13837 15Z"
                                fill="#535AFA"
                            />
                        </svg>
                        <span className="font-medium">XRD</span>
                    </div>
                </div>
            </div>

            <div className="mt-[24px]">
                <label className="font-bold">Traits</label>

                <div className="mt-4">
                    <div className="flex flex-wrap gap-2">
                        {
                            traits.map((trait, index) => (
                                <div
                                    key={index}
                                    className="rounded-[21px] w-fit text-xs flex items-center gap-x-[10px] py-[6px] px-[14px] bg-[#535AFA] text-white"
                                >
                                    {trait}
                                    <span
                                        className="text-sm cursor-pointer"
                                        onClick={() => {
                                            // remove trait
                                            setTraits(traits.filter((t) => t !== trait));
                                        }}
                                    >
                                        x
                                    </span>
                                </div>
                            ))
                        }
                    </div>

                    <div className="mt-[36px]">
                        <div className="flex items-center max-w-[401px] mx-auto">
                            <input
                                type="text"
                                value={newTrait}
                                onChange={(e) => setNewTrait(e.target.value)}
                                placeholder="New trait"
                                className="px-[20px] w-full py-[16px] placeholder:text-[#A6A9B9] border border-solid border-[#F3F3F3] outline-none focus:outline-none rounded-lg"
                            />
                            <button
                                className="w-[109px] h-[52px] rounded-lg bg-[#535AFA] text-white text-sm -ml-2"
                                onClick={handleAddTrait}
                                type="button"
                            >
                                Add
                            </button>
                        </div>
                        <p className="text-center text-xs text-[#93989A] mt-2">
                            Add New Traits in this fashion “[Category]:[Trait]”
                        </p>
                    </div>
                </div>
                <ErrorModal
                    isOpen={isErrorOpen}
                    setIsOpen={setIsErrorOpen}
                    message={errorMessage}
                    title={errorTitle}
                />
            </div>

            {/* submit btn */}
            <button
                className="w-full mt-[32px] px-[24px] py-[16px] font-medium text-white bg-[#535AFA] flex items-center justify-center rounded-lg"
                onClick={handleClick}
                type="button"
            >
                Save
            </button>
        </form>
    );
}
