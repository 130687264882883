import {useNavigate} from "react-router";
import UIButton from "../UIButton";

const CollectionCardFull: React.FC<{
    id: string,
    name: string,
    volume: number,
    backgroundUrl: string,
  }> = ({id, name, backgroundUrl, volume}) => {
    const navigate = useNavigate();
    const divStyle = {
        backgroundImage: `linear-gradient(to bottom, transparent, black), url('${backgroundUrl}')`,
      };
    return (
       <div style={divStyle} className={`flex flex w-full h-[400px] rounded-[15px] cursor-pointer overflow-hidden bg-no-repeat bg-cover`} onClick={() => navigate(`/collections/${id}`)} >
        <div className="my-[20px] ml-[25px] mt-auto mr-auto">
          <p className="text-white text-left text-[24px] font-semibold pb-[5px]">{name}</p>
          <p className="text-white text-[20px] text-left">Volume: {volume} xrd</p>
        </div>
        <div className="my-[20px] mt-auto ml-auto mr-[25px] w-[140px]">
        <UIButton onClick={() => navigate(`/collections/${id}`)} variant="btn-buy-purple">
            View Collection
        </UIButton>
      </div>
       </div>
    )
  };
  
  export default CollectionCardFull;