import {useEffect, useState} from "react";
import Tags from "../utils/Tags";
import {getCollectionById} from "../../backend/collections/CollectionGetters";
import {NFTCollection, default_collection} from "../../types/Collection";
import {useLocation, useNavigate} from "react-router-dom";
import ErrorModal from "../modals/ErrorModal";
import {updateCollection} from "../../backend/collections/CollectionUpdates";

const tagOptions = ["3D", "Art", "Modern", "Futuristic", "Abstract", "Anime"];

export default function DetailsTab() {
    const [collection, setCollection] = useState<NFTCollection>(default_collection);
    const [name, setName] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [tags, setTags] = useState<string[]>([]);
    const [websiteURL, setWebsiteURL] = useState<string>("")
    const [isErrorOpen, setIsErrorOpen] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [errorTitle, setErrorTitle] = useState<string>("");

    const navigate = useNavigate();
    const location = useLocation();
    let collection_id = parseFloat(location.pathname.split('/')[3]);

    async function getData() {
        try {
            const result = await getCollectionById(collection_id);
            setCollection(result);
        } catch (err) {
            setIsErrorOpen(true);
            setErrorTitle("This collection is private")
            setErrorMessage("Cannot access data of unpublished collection")
        }
    }

    useEffect(() => {
        getData()
    }, [])

    async function handleClick()  {
        if(collection.address){
            setIsErrorOpen(true)
            setErrorTitle("Cannot edit details of a published collection!")
            setErrorMessage("Collection details are immutable.")
        }
        else{
            if(name !== "") { collection.data.name = name }
            if(description !== "") { collection.data.description = description }
            if(websiteURL !== "") { collection.data.info_url = websiteURL }
            if(tags.length > 0) {collection.data.tags = tags}

            await updateCollection(collection);
            navigate(`/collections/${collection_id}`);
        }
    }

  // edit collection form
  return (
    <form className="w-full max-w-[608px] mx-auto p-2 mt-[48px]">
        <p className="text-text-gray text-xs font-medium w-full">
            Not edited fields will remain the same
        </p>

      <div className="w-full mt-3">
        <label className="mb-1 font-bold" htmlFor="collectionName">
          Collection Name
        </label>
        <input
          type="text"
          id="collectionName"
          name="collectionName"
          placeholder= {collection.data.name}
          onChange={(e) => setName(e.target.value) }
          className="w-full mt-1 px-[20px] py-[16px] placeholder:text-[#A6A9B9] border border-solid border-[#F3F3F3] outline-none focus:outline-none rounded-lg box-border"
        />
      </div>

      <div className="mt-[24px]">
        <label className="font-bold" htmlFor="externalLink">
          Website Link
        </label>
        <p className="my-2 text-sm text-text-gray">
          We will provide a link to this URL on the collection's detail page so
          that users can click on it to learn more. You can also link to your
          own webpage with more details.
        </p>
        <input
          type="text"
          id="externalLink"
          name="externalLink"
          placeholder={collection.data.info_url}
          onChange={(e) => setWebsiteURL(e.target.value) }
          className="w-full px-[20px] py-[16px] placeholder:text-[#A6A9B9] border border-solid border-[#F3F3F3] outline-none focus:outline-none rounded-lg box-border"
        />
      </div>

      <div className="mt-[24px]">
        <label className="font-bold" htmlFor="description">
          Description
        </label>
        <p className="my-2 text-sm text-text-gray">
          The description of the collection will be shown on the detail page,
          below the image. Markdown is allowed.
        </p>

        <textarea
          name="description"
          id="description"
          placeholder={collection.data.description}
          onChange={(e) => setDescription(e.target.value) }
          className="min-h-[180px] w-full px-[20px] py-[16px] placeholder:text-[#A6A9B9] border border-solid border-[#F3F3F3] outline-none focus:outline-none rounded-lg"
        ></textarea>
      </div>

      {/* tags */}
      <div className="mt-[24px]">
        <label className="font-bold">Tags</label>
        <div className="mt-2">
          <Tags options={tagOptions} tags={collection.data.tags} setTags={setTags} />
        </div>
      </div>

      {/* submit btn */}
      <button
        className="w-full mt-[32px] px-[24px] py-[16px] font-medium text-white bg-[#535AFA] flex items-center justify-center rounded-lg"
        type="button"
        onClick={handleClick}
      >
        Save changes
      </button>
        <ErrorModal
            isOpen={isErrorOpen}
            setIsOpen={setIsErrorOpen}
            message={errorMessage}
            title={errorTitle}
        />
    </form>
  );
}
