import { useState } from "react";
import {useNavigate} from "react-router";
import RadLandImageDefault from "../../../assets/default/Radland-icon.jpg"

const CollectionCard: React.FC<{
  id: string,
  name: string,
  creator: string,
  backgroundUrl: string
}> = ({id, name, creator, backgroundUrl}) => {
  const navigate = useNavigate();
  const [isImageError, setIsImageError] = useState(false);
  
  return (
     <div onClick={() => navigate(`/collections/${id}`)} 
        className="flex flex-col w-full md:w-[252px] bg-white rounded-[15px] p-[6px] cursor-pointer text-left shadow-lg overflow-hidden">
      <div className="overflow-hidden rounded-[15px]">
      <img src={!isImageError ? backgroundUrl : RadLandImageDefault} onError={() => setIsImageError(true)} alt="collection" className="object-cover w-full md:w-[240px] h-[190px] hover:scale-[1.20] transition duration-500" />
      </div>
      <div className="my-[20px] ml-[25px]">
        <p className="text-black text-left text-[16px] font-semibold pb-[5px]">{name?.length > 23 ? name.substring(0, 20) + '...' : name}</p>
        <p className="text-grey-dark text-[12px]">{creator}</p>
      </div>
     </div>
  )
};

export default CollectionCard;