import { useNavigate } from "react-router";
import {ReactComponent as XrdIcon } from '../../../assets/global/xrd.svg'
import {NFTCollection, CollectionItem, ShortCollectionItem} from "../../../types/Collection";
import UIButton from "../UIButton";
import {getCurrentUser} from "../../../ledger/Global";
import ActionButtonsSection from "../../NftDetailsPage/ActionButtonsSection";
import { Proposal } from "../../../types/Kaupa";

interface NewDesignItemProps {
    imageUrl: string,
    itemId: string,
    collectionId: number,
    collectionName: string,
    price: Proposal | undefined,
    owner: string,
    title: string,
    collection: NFTCollection,
    nft: CollectionItem,
}

const NewDesignItem = ({
    imageUrl,
    price,
    owner,
    title,
    itemId,
    collectionId,
    collection,
    collectionName,
    nft,
}: NewDesignItemProps)  => {
    const navigate = useNavigate();
    const connectedWallet = getCurrentUser()

    return (
        <div className="w-full max-h-fit p-2 pb-2 shadow-sm bg-white rounded-xl flex flex-col group cursor-pointer">
            <div onClick={() => navigate(`/collections/${collectionId}/${itemId}`)}
                 className="overflow-hidden rounded-t-2xl">
                <img
                    src={imageUrl}
                    alt=""
                    className="w-full h-auto aspect-square rounded-xl object-cover group-hover:scale-110 transition-all duration-200 ease-in-out"
                />
            </div>
            <div className="p-2">
            <div className="text-left mb-5">
                <p className="font-bold text-black-baseNew text-[18px]">{title}</p>
                <p className="font-medium text-grey-dark text-[12px]">{collectionName}</p>

            </div>
            <div className="flex items-center">
                <div className="w-[100px] h-[36px] mr-auto">
                 <ActionButtonsSection
                          item={nft}
                          collection={collection}
                          btnStyle="btn-card-action"
                      />
                </div>
                { price &&
                    <>
                        <XrdIcon className="text-blue-light mr-1" width="25" height="25" fill="#FFF" />
                        <p className="font-bold text-black-base text-[18px] ml-[2px]">{nft?.proposal?.payment?.amount}</p>
                    </>
                }
            </div>
            </div>
        </div>
    );
}

export default NewDesignItem;
