
import CollectionSlider from "../components/global/CollectionSlider/CollectionSlider";
import { useState, useEffect } from "react";
import useFetchCollections from "../hooks/CollectionDetails/FetchCollections";
import { useParams } from "react-router";
import ExplorePageNavigation from "../components/ExplorePage/ExplorePageNavigation";
import CollectionSliderFull from "../components/global/CollectionSliderFull/CollectionSliderFull";
import CollectionStatsSection from "../components/global/CollectionsStats/CollectionsStatsSection";
import useFetchLatestCollections from "../hooks/CollectionDetails/FetchLatestCollectios";
import useFetchTrendingCollections from "../hooks/CollectionDetails/FetchTrendingCollections";
import ExplorePageNotFound from "../components/ExplorePage/ExplorePageNotFound";

type ParamsType = {
    category: string;
  };

const ExplorePage: React.FC = () => {
  const { category } = useParams<ParamsType>();
  const [recentlyAddedCollections, setRecentlyAddedCollections] = useState<any>([])
  const [activeTabStats, setActiveTabStats] = useState('Top')
  const { data: collectionsData, isLoading: isCollectionsDataLoading, isRefetching: isCollectionsDataRefetching, refetch: refetchCollections} = useFetchCollections(category === 'all' ? '' : category);
  const { data: trendingCollectionsData, isLoading: isTrendingCollectionsDataLoading, isRefetching: isTrendingCollectionsDataRefetching, refetch: refetchTrendingCollections} = useFetchTrendingCollections(category === 'all' ? '' : category);
  const { data: recentlyAddedCollectionsData, isLoading: isRecentlyAddedCollectionsDataLoading, isRefetching: isRecentlyAddedCollectionsDataRefetching, refetch: refetchRecentlyAddedCollections} = useFetchLatestCollections(category === 'all' ? '' : category);


  useEffect(() => {
    if (category) {
        refetchCollections();
        refetchRecentlyAddedCollections();
    }
   }, [category]);

   useEffect(() => {
    if (category) {
        if (activeTabStats === 'Top') {
            refetchCollections()
        } else refetchTrendingCollections()
    }
   }, [category, activeTabStats]);

   useEffect(() => {
    if (recentlyAddedCollectionsData) {
        const collections: any[]  = recentlyAddedCollectionsData?.map((collection: any) => {return ({
            id: collection.id,
            name: collection.data.name,
            creator: collection.owner,
            backgroundUrl: collection.data.icon_url,
        })})
        setRecentlyAddedCollections(collections)
    }
   }, [recentlyAddedCollectionsData]);

   useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
 
    return (
      <div className={`w-full h-full flex flex-col py-[30px] px-[30px] md:py-[80px] md:px-[77px] text-center background-explore-page overflow-x-hidden`}>
       <ExplorePageNavigation currentCategory={category ? category : ''}/>
       { isCollectionsDataLoading || isCollectionsDataRefetching || collectionsData.length ? (
        <>
            <CollectionSliderFull isLoading={isCollectionsDataLoading || isCollectionsDataRefetching} collections={collectionsData} />
            <div className="mt-[40px] w-full">
                <div className="flex space-x-8 ml-[15px]">
                <p onClick={() =>setActiveTabStats('Top')} className={`text-[24px] text-black font-semibold text-left ${activeTabStats === 'Top' ? ' underline underline-offset-8' :  'cursor-pointer'}`}>Top</p>
                <p onClick={() =>setActiveTabStats('Trending')} className={`text-[24px] text-black font-semibold text-left ${activeTabStats === 'Trending' ? ' underline underline-offset-8' :  'cursor-pointer'}`}>Trending</p>
            </div>
            <CollectionStatsSection 
                collections={activeTabStats === 'Top' ? collectionsData : trendingCollectionsData}
                isLoading={activeTabStats === 'Top' ? isCollectionsDataLoading || isCollectionsDataRefetching : isTrendingCollectionsDataLoading || isTrendingCollectionsDataRefetching}/>
            </div>
            <div className="w-full my-[40px]">
                <CollectionSlider title="Recently added" category="recentlyAdded" isLoading={isRecentlyAddedCollectionsDataLoading || isRecentlyAddedCollectionsDataRefetching} collections={recentlyAddedCollections} />
            </div>
        </>
       ) : (
        <div className="mt-[30px]">
        <ExplorePageNotFound />
        </div>
       )}
      
      </div>
    )
}

export default ExplorePage;