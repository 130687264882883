import { useState } from "react";
import Tags from "../utils/Tags";
import RadixWalletModal from "../modals/RadixWalletModal";
import ErrorModal from "../modals/ErrorModal";
import { NFTCollection } from "../../types/Collection";
import { createCollectionFromAddress } from "../../backend/collections/CollectionUpdates";
import { useNavigate } from "react-router-dom";

type RadixWalletModalState = {
  isRadixWalletModalOpen: boolean;
  walletMessage: string;
  walletTitle: string;
  status: "success" | "error" | "loading";
};

type ErrorModalState = {
  isErrorModalOpen: boolean;
  errorTitle: string;
  errorMessage: string;
};

type FormState = {
  name: string;
  description: string;
  icon: string;
  banner: string;
  website: string;
  twitter: string | undefined;
  instagram: string | undefined;
  discord: string | undefined;
  tags: string[];
};

const tagOptions = ["3D", "Art", "Modern", "Futuristic", "Abstract", "Anime"];

const formInputs = {
  name: {
    stateControl: true,
    disabled: true,
  },
  description: {
    stateControl: true,
    disabled: false,
  },
  icon: {
    stateControl: true,
    disabled: true,
  },
  banner: {
    stateControl: true,
    disabled: false,
  },
  tags: {
    stateControl: true,
    disabled: true,
  },
  website: {
    stateControl: true,
    disabled: false,
  },
  twitter: {
    stateControl: true,
    disabled: false,
  },
  instagram: {
    stateControl: true,
    disabled: false,
  },
  discord: {
    stateControl: true,
    disabled: false,
  },
};

export const ImportCollectionForm: React.FC<{ collection: NFTCollection }> = ({
  collection,
}) => {
  const [formState, setFormState] = useState<FormState>({
    name: collection.data.name,
    description: collection.data.description,
    icon: collection.data.icon_url,
    website: collection.data.info_url,
    banner: "",
    twitter: undefined,
    instagram: undefined,
    discord: undefined,
    tags: collection.data.tags,
  });

  const [radixWalletModalState, setRadixWalletModalState] =
    useState<RadixWalletModalState>({
      isRadixWalletModalOpen: false,
      walletMessage: "",
      walletTitle: "",
      status: "loading",
    });
  const [errorModalState, setErrorModalState] = useState<ErrorModalState>({
    isErrorModalOpen: false,
    errorTitle: "",
    errorMessage: "",
  });

  const [isImporting, setIsImporting] = useState<boolean>(false);

  const navigate = useNavigate();

  const setTags = (value: React.SetStateAction<string[]>) => {
    if (formInputs["tags"].disabled) return;

    setFormState((prevState) => ({
      ...prevState,
      tags: typeof value === "function" ? value(prevState.tags) : value,
    }));
  };

  const getInputDynamicConfig = (inputName: keyof typeof formInputs) => {
    if (!formInputs[inputName]) return {};

    return {
      disabled: formInputs[inputName].disabled,
      ...(formInputs[inputName].stateControl && {
        value: formState[inputName],
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
          setFormState((prevState) => ({
            ...prevState,
            [inputName]: e.target.value,
          }));
        },
      }),
    };
  };

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    if (!isImporting) {
      e.preventDefault();

      const payload = {
        ...formState,
        // add any other fields here
      };

      console.log(
        `handleSubmit in ImportCollectionForm component with payload: `,
        payload,
      );

      setIsImporting(true);

      setRadixWalletModalState({
        isRadixWalletModalOpen: true,
        status: "loading",
        walletMessage:
          "This action will only succeed if you are the collection's owner. The process can take time but your collection will appear in your collections when it is finished.",
        walletTitle: "Importing collection",
      });
      try {
        await createCollectionFromAddress(
          collection.address!,
          payload.description,
          payload.banner,
          payload.website,
          payload.twitter,
          payload.discord,
          payload.instagram,
        );
        setRadixWalletModalState({
          isRadixWalletModalOpen: false,
          status: "loading",
          walletMessage: "",
          walletTitle: "Importing collection",
        });

        navigate("/collections");
      } catch (error: any) {
        setRadixWalletModalState({
          isRadixWalletModalOpen: true,
          status: "error",
          walletTitle: "Error while trying to import collection",
          walletMessage:
            "This collection doesn't belong to you. If it does, please contact an administrator.",
        });
      }
    }
  }

  return (
    <form
      onSubmit={handleSubmit}
      className="w-full max-w-[608px] mx-auto p-2 mt-[48px]"
    >
      <div className="w-full mt-3">
        <label className="mb-1 font-bold" htmlFor="name">
          Collection Name
        </label>
        <input
          type="text"
          id="name"
          name="name"
          placeholder="Enter collection name"
          className="w-full mt-1 px-[20px] py-[16px] placeholder:text-[#A6A9B9] border border-solid border-[#F3F3F3] outline-none focus:outline-none rounded-lg box-border"
          {...getInputDynamicConfig("name")}
        />
      </div>

      <div className="mt-[24px]">
        <label className="font-bold" htmlFor="description">
          Description
        </label>
        <p className="my-2 text-sm text-[#93989A]">
          The description of the collection will be shown on the detail page,
          below the image. Markdown is allowed.
        </p>

        <textarea
          name="description"
          id="description"
          placeholder="Write a description of your collection here"
          className="min-h-[180px] w-full px-[20px] py-[16px] placeholder:text-[#A6A9B9] border border-solid border-[#F3F3F3] outline-none focus:outline-none rounded-lg"
          {...getInputDynamicConfig("description")}
        ></textarea>
      </div>

      <div className="w-full mt-3">
        <label className="mb-1 font-bold" htmlFor="icon">
          Collection Icon
        </label>
        <p className="my-2 text-sm text-[#93989A]">
          {
            "Icon representing the collection that will be displayed in wallet and on the collection presentation page. (240x190)"
          }
        </p>
        <input
          type="text"
          id="icon"
          name="icon"
          placeholder="Collection’s icon URL"
          className="w-full mt-1 px-[20px] py-[16px] placeholder:text-[#A6A9B9] border border-solid border-[#F3F3F3] outline-none focus:outline-none rounded-lg box-border"
          {...getInputDynamicConfig("icon")}
        />
      </div>

      <div className="w-full mt-3">
        <label className="mb-1 font-bold" htmlFor="banner">
          Collection Banner <span className="text-[#FD1616]"></span>
        </label>
        <p className="my-2 text-sm text-[#93989A]">
          {
            "Banner to be displayed on the collection presentation page. (1920x250)"
          }
        </p>
        <input
          type="text"
          id="banner"
          name="banner"
          placeholder="Collection’s banner URL"
          className="w-full mt-1 px-[20px] py-[16px] placeholder:text-[#A6A9B9] border border-solid border-[#F3F3F3] outline-none focus:outline-none rounded-lg box-border"
          {...getInputDynamicConfig("banner")}
        />
      </div>

      <div className="mt-[24px]">
        <label className="font-bold">Tags</label>
        <div className="mt-2">
          <Tags options={tagOptions} tags={formState.tags} setTags={setTags} />
        </div>
      </div>

      <div className="mt-[24px]">
        <label className="font-bold" htmlFor="website">
          Website
        </label>
        <p className="my-2 text-sm text-[#93989A]">
          We will provide a link to this URL on the item's detail page so that
          users can click on it to learn more. You can also link to your own
          webpage with more details.
        </p>
        <input
          type="url"
          id="website"
          name="website"
          placeholder="Collection’s website URL"
          className="w-full mt-1 px-[20px] py-[16px] placeholder:text-[#A6A9B9] border border-solid border-[#F3F3F3] outline-none focus:outline-none rounded-lg box-border"
          {...getInputDynamicConfig("website")}
        />
      </div>

      <div className="mt-[24px]">
        <label className="font-bold" htmlFor="twitter">
          Twitter
        </label>

        <div className="mt-1 w-full flex items-center gap-x-1 px-[20px] py-[16px] border border-solid border-[#F3F3F3] rounded-lg box-border">
          <p className="text-[#0404094D]">twitter.com/</p>
          <input
            type="text"
            id="twitter"
            name="twitter"
            placeholder="username"
            className="w-full border-none outline-none focus:outline-none"
            {...getInputDynamicConfig("twitter")}
          />
        </div>
      </div>

      <div className="mt-[24px]">
        <label className="font-bold" htmlFor="instagram">
          Instagram
        </label>

        <div className="mt-1 w-full flex items-center gap-x-1 px-[20px] py-[16px] border border-solid border-[#F3F3F3] rounded-lg box-border">
          <p className="text-[#0404094D]">instagram.com/</p>
          <input
            type="text"
            id="instagram"
            name="instagram"
            placeholder="username"
            className="w-full border-none outline-none focus:outline-none"
            {...getInputDynamicConfig("instagram")}
          />
        </div>
      </div>

      <div className="mt-[24px]">
        <label className="font-bold" htmlFor="discord">
          Discord
        </label>

        <div className="mt-1 w-full flex items-center gap-x-1 px-[20px] py-[16px] border border-solid border-[#F3F3F3] rounded-lg box-border">
          <p className="text-[#0404094D]">discord.com/</p>
          <input
            type="text"
            id="discord"
            name="discord"
            placeholder="username"
            className="w-full border-none outline-none focus:outline-none"
            {...getInputDynamicConfig("discord")}
          />
        </div>
      </div>

      <RadixWalletModal
        isOpen={radixWalletModalState.isRadixWalletModalOpen}
        setIsOpen={(value: boolean) => {
          setRadixWalletModalState({
            ...radixWalletModalState,
            isRadixWalletModalOpen: value,
          });
        }}
        loadingMessage={"Please wait while we import the collection"}
        title={radixWalletModalState.walletTitle}
        message={radixWalletModalState.walletMessage}
        status={radixWalletModalState.status}
      />

      <ErrorModal
        isOpen={errorModalState.isErrorModalOpen}
        setIsOpen={(value: boolean) => {
          setErrorModalState({
            ...errorModalState,
            isErrorModalOpen: value,
          });
        }}
        title={errorModalState.errorTitle}
        message={errorModalState.errorMessage}
      />

      <div className="w-full flex flex-col md:flex-row items-center gap-6  mt-[32px]">
        <button
          className="w-full px-[24px] py-[16px] font-medium text-white bg-[#535AFA] flex items-center justify-center rounded-lg"
          type="submit"
        >
          Import Collection
        </button>
      </div>
    </form>
  );
};
