import {AccountAddress, ComponentAddress, NFT} from "../../types/Radix";
import {MakeProposal} from "../../types/Kaupa";
import {sendTransaction} from "../Global";
import {NFTCollection} from "../../types/Collection";
import {TransactionResult} from "../../types/Transaction";


/**
 * Makes a proposal - i.e. make an NFT sell offer.
 * Warning: The maker fee should be paid in a single token(or else the manifest will crash).
 * @param account - account selling the NFTs.
 * @param kaupa_address - component address of the Kaupa instance that manages the sale.
 * @param trader_nft
 * @param proposals_to_make - NFTs and their selling terms.
 * @param collection
 */
export async function makeProposal(
    account: AccountAddress,
    kaupa_address: ComponentAddress,
    trader_nft: NFT,
    proposals_to_make: MakeProposal[],
    collection: NFTCollection
): Promise<TransactionResult> {

    let ids_string = "";
    let call_string = "";
    let count = 0;
    let nft_address = "";
    proposals_to_make.forEach( proposal => {
        let item = proposal.item;
        if(item.proposal != null && item.collection_address != null)
        {
            nft_address = item.collection_address;
            call_string += makeSingleProposalString(kaupa_address, collection, proposal, trader_nft, "bucket", count);
            ids_string+= `NonFungibleLocalId("${item.item_data.id}"),`
            count += 1;
        }
    })
    ids_string = ids_string.slice(0, -1)


    let manifest = `
    CALL_METHOD
        Address("${account}")
        "withdraw_non_fungibles"
        Address("${nft_address}")
        Array<NonFungibleLocalId>(${ids_string});
    
    ${call_string}
    
    CALL_METHOD
        Address("${account}")
        "deposit_batch"
        Expression("ENTIRE_WORKTOP");`;

    return await sendTransaction(manifest, `Puts the selected NFTs on sale`);
}


/**
 * Creates a sub-manifest for a single NFT sale.
 * @param kaupa_address - component address of the Kaupa instance that manages the sale.
 * @param collection
 * @param proposal_to_make - NFT to sell.
 * @param trader_card - trader card of the seller
 * @param bucket_prefix - prefix to use for the buckets.
 * @param count - current count of the NFTs to sell.
 */
function makeSingleProposalString(kaupa_address: ComponentAddress, collection: NFTCollection, proposal_to_make: MakeProposal, trader_card: NFT, bucket_prefix: string, count: number): string {
    if(proposal_to_make.item.proposal != null){
        return `
        TAKE_NON_FUNGIBLES_FROM_WORKTOP
            Address("${proposal_to_make.item.collection_address}")
            Array<NonFungibleLocalId>(NonFungibleLocalId("${proposal_to_make.item.item_data.id}"))   
            Bucket("${bucket_prefix}_${count}");
            
        CALL_METHOD
            Address("${kaupa_address}")
            "make_proposal"
            NonFungibleGlobalId("${trader_card.address}:${trader_card.id}")
            None
            Enum<0u8>()
            Array<Bucket>(Bucket("${bucket_prefix}_${count}"))
            Enum<0u8>(Map<Address, Enum>( Address("${proposal_to_make.item.proposal.payment.address}") => Enum<0u8>(Decimal("${proposal_to_make.item.proposal.payment.amount}"))))
            false
            Array<Bucket>();`
    }
    else { return "" }
}