import {AccountAddress, ComponentAddress, NFT} from "../../types/Radix";
import {sendTransaction} from "../Global";
import {ProofToArg} from "../ManifestUtils";
import {ShortCollectionItem} from "../../types/Collection";

/**
 * Rescinds a proposal - i.e. cancels a sale.
 * @param account - account selling the sale.
 * @param kaupa_address - component address of the Kaupa instance that manages the sale.
 * @param trader_nft
 * @param items
 */
export async function rescindProposals(
    account: AccountAddress,
    kaupa_address: ComponentAddress,
    trader_nft: NFT,
    items: ShortCollectionItem[]
) {
    let proposals_str = "";
    let count = 0;
    items.forEach(item => {
        let proposal = item.proposal;
        if(proposal && proposal.id !== -1){
            proposals_str += rescindSingleProposalString(kaupa_address, proposal.id, "proof", count);
            count+=1
        }
    });

    let manifest = `
    ${ProofToArg(account, trader_nft, "proof")}
        
    ${proposals_str}
    
    CALL_METHOD
        Address("${account}")
        "deposit_batch"
        Expression("ENTIRE_WORKTOP");   
    `;

    return await sendTransaction(manifest);
}

/**
 * Creates a sub-manifest for a single proposal rescind.
 * @param kaupa_address - component address of the Kaupa instance that manages the sale.
 * @param proposal_id - id of the proposal to rescind.
 * @param proof_prefix - name of the original proof of the trader's nft.
 * @param count - current count of the proposals to rescind.
 */
function rescindSingleProposalString(
    kaupa_address: ComponentAddress,
    proposal_id: number,
    proof_prefix: string,
    count: number,
): string {

    return `
    CLONE_PROOF
            Proof("${proof_prefix}")
            Proof("${proof_prefix}_${count}");
        
    CALL_METHOD
        Address("${kaupa_address}")
        "rescind_proposal"
        Proof("${proof_prefix}_${count}")
        ${proposal_id}u128;
            
    `;
}