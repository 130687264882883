type SearchRecommendationCollection = {
    name: string;
    imgUrl: string;
    itemsCount: number;
    id: number
};

type SearchRecommendationProps = {
    collections: SearchRecommendationCollection[];
    onItemClick: (collectionId: number) => void
};

export default function SearchRecommendation({ collections, onItemClick }: SearchRecommendationProps) {

    return (
        <div>
            <p className="text-[#93989A] px-6 text-xs font-medium uppercase pb-3 border-b border-b-[#F3F3F3]">
                Collections
            </p>
            <div className="py-4 px-6 flex flex-wrap gap-x-8 gap-y-4">
                {collections.length ? (
                    collections.map((collection, i) => (
                        <div
                            key={collection.id} // Ensure each mapped element has a unique key
                            className="flex items-center gap-x-3 cursor-pointer hover:bg-grey-medium rounded-md px-4 py-2"
                            onClick={() => onItemClick(collection.id)}
                        >
                            <img
                                src={collection.imgUrl}
                                alt=""
                                className="w-[40px] h-[40px] rounded-full object-cover"
                            />
                            <div>
                                <p className="text-sm font-semibold leading-[130%]">
                                    {collection.name}
                                </p>
                                <p className="text-xs font-medium text-[#93989A] mt-1">
                                    {`${collection.itemsCount
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} items`}
                                </p>
                            </div>
                        </div>
                    ))
                ) : (
                    <p className="text-grey-dark text-sm my-auto">No collections match the provided query</p>
                )}
            </div>
        </div>
    );
}