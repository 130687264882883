import { useLocation } from "react-router";
import { SidebarMenuItemType } from "../../../types/global.components";
import SidebarMenuItem from "./SidebarMenuItem";

import sidebarArrow from "../../../assets/sidebarMenu/sidebarArrow.svg";
import radlandLogo from "../../../assets/sidebarMenu/radlandLogo.svg";
import twitterLogo from "../../../assets/global/twitter_grey.svg";
import telegramLogo from "../../../assets/global/telegram_dark.svg"
import React from "react";
import {Link, useNavigate} from "react-router-dom";
import { useRecoilValue } from "recoil";
import { connectedWallet as connectedWalletRecoil } from "../../../state/global/connectedWallet";

const SidebarMenu: React.FC<{
  isOpen: boolean;
  sidebarMenuItems: Array<SidebarMenuItemType>;
  toggleSidebarOpen: () => void;
}> = ({ sidebarMenuItems, isOpen, toggleSidebarOpen }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const menuItemsForLoggedUser = ['Claim resources', 'My Collections', 'My Nfts']
  const connectedWallet = useRecoilValue(connectedWalletRecoil)
  return (
    <div
      className={`relative w-full h-screen flex flex-col bg-grey-light border border-grey-medium
        ${isOpen ? "px-[18px]" : "px-[8px]"}`}
    >
      <img
        alt="radland logo"
        className="w-[86px] h-[65px] mx-auto my-[30px] cursor-pointer"
        onClick={ () => {navigate('/')} }
        src={radlandLogo}
      />
      <button
        className={`absolute top-[90px] right-[0px] mr-[-15px] transform
            transition ease-in-out duration-300`}
        onClick={toggleSidebarOpen}
      >
        <img
          alt="collapse sidebar button"
          className={`${isOpen ? "" : "rotate-180"} w-8 h-8`}
          src={sidebarArrow}
        />
      </button>
      {sidebarMenuItems.map((el, index) => (
        <div key={index} className={`mb-[8px]`}>
          <SidebarMenuItem
            name={el?.name}
            Icon={el?.Icon}
            isOpen={isOpen}
            link={el?.link}
            isActive={location.pathname === el?.link}
            type={el?.type}
            isDisabled={menuItemsForLoggedUser.includes(el?.name) && !connectedWallet?.persona?.identityAddress}
          />
        </div>
      ))}
      {isOpen && (
          <div className="mt-auto py-7 px-2 w-full">
            <div className="flex items-center justify-start gap-x-4">
              <a href="https://twitter.com/RadLandNFT" target="_blank" rel="noopener noreferrer">
                <img
                    src={twitterLogo}
                    alt=""
                    className="text-[#93989A] cursor-pointer"
                />
              </a>
              <a href="https://t.me/RadLandNFT" target="_blank" rel="noopener noreferrer">
                <img
                    src={telegramLogo}
                    alt=""
                    className="text-[#93989A] cursor-pointer"
                />
              </a>
            </div>
            <p className="text-[#93989A] text-sm text-left mt-2">
              © 2023 Radland. All rights reserved
            </p>
            <Link to="/terms-and-conditions" className="text-[#93989A] text-sm text-left mt-2 hover:underline cursor-pointer">
                Terms & Disclaimer
            </Link>
          </div>
      )}
    </div>
  );
};

export default SidebarMenu;
