import CollectionsStatsTable from "./CollectionsStatsTable";

import { NFTCollection } from '../../../types/Collection';
const CollectionStatsSection: React.FC<{
  collections: NFTCollection[],
  isLoading: boolean,
}> = ({collections, isLoading}) => {
  return (
      <div className="w-full pt-[20px] pb-[0px] rounded-[10px]">
        <CollectionsStatsTable collections={collections} isLoading={isLoading} />
      </div>
  )
};

export default CollectionStatsSection;