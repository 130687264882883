import { AccountAddress, ComponentAddress } from "../../types/Radix";
import { FungibleBucketToArg } from "../ManifestUtils";
import { sendTransaction } from "../Global";
import { TakeProposal } from "../../types/Kaupa";

/**
 * Accepts a proposal - i.e. buys an NFT.
 * @param account - account buying the NFT.
 * @param kaupa_address - component address of the Kaupa instance that manages the sale.
 * @param proposals_to_take - NFTs to buy.
 */
export async function acceptProposals(
  account: AccountAddress,
  kaupa_address: ComponentAddress,
  proposals_to_take: TakeProposal[],
) {
  let proposal_string = "";
  let count = 0;
  let total_xrd = 0;
  proposals_to_take.forEach((proposal) => {
    if (proposal.item.proposal != null) {
      proposal_string += acceptSingleProposalString(
        account,
        kaupa_address,
        proposal,
        "bucket",
        count,
      );
      count += 1;
      total_xrd += proposal.item.proposal.payment.amount;
    }
  });

  let manifest = `        
        ${proposal_string}
        
        CALL_METHOD
            Address("${account}")
            "deposit_batch"
            Expression("ENTIRE_WORKTOP");   
    `;

  let message =
    proposals_to_take.length > 1
      ? `Buying ${proposals_to_take.length} NFTs for ${total_xrd} XRD from RadLand`
      : `Buying ${proposals_to_take[0]!.item.item_data.name} for ${total_xrd} XRD from Radland`;

  return await sendTransaction(manifest, message);
}

/**
 * Creates a sub-manifest for a single NFT buy.
 * @param account - account buying the NFTs
 * @param kaupa_address -  component address of the Kaupa instance that manages the sale.
 * @param proposal_taking - NFT to buy.
 * @param bucket_prefix - prefix to use for the buckets.
 * @param count - current count of the NFTs to buy.
 */
function acceptSingleProposalString(
  account: AccountAddress,
  kaupa_address: ComponentAddress,
  proposal_taking: TakeProposal,
  bucket_prefix: string,
  count: number,
) {
  let proposal = proposal_taking.item.proposal;
  if (proposal != null) {
    return `
    ${FungibleBucketToArg(account, proposal.payment, `${bucket_prefix}_payment_${count}`)}
            
    CALL_METHOD
        Address("${kaupa_address}")
        "accept_proposal"
        None
        ${proposal.id}u128
        false
        Array<Bucket>(Bucket("${bucket_prefix}_payment_${count}"))
        Array<Bucket>();
    `;
  } else {
    return "";
  }
}
