import { useEffect, useState } from "react";
import { NFTCollection, CollectionItem } from "../../types/Collection";
import NewDesignItem from "../global/NftItems/NewDesignItem";

const UserNftsFromCollection: React.FC<{
  collectionName: string;
  collectionIcon: string;
  collection: NFTCollection;
  showOnlyListedNfts: boolean;
  isCollectionsActive: boolean;
  nfts: Array<CollectionItem>;
}> = ({
  collectionName,
  collectionIcon,
  nfts,
  collection,
  showOnlyListedNfts,
  isCollectionsActive,
}) => {
  const [filteredNfts, setFilteredNfts] = useState<CollectionItem[]>([]);

  useEffect(() => {
    if (nfts?.length) {
      if (!showOnlyListedNfts) setFilteredNfts(nfts);
      if (showOnlyListedNfts)
        setFilteredNfts(
          nfts?.filter((item: any) => item?.proposal?.payment?.amount),
        );
    }
  }, [nfts, showOnlyListedNfts]);

  return (
    <div className={`w-full h-full flex flex-col mb-4`}>
      <div className="flex items-center justify-between mb-4 p-2 bg-white w-full rounded-lg shadow-sm">
        <div className="flex items-center w-fit">
          <img
            className="rounded-2xl h-[50px] w-[50px]"
            src={collectionIcon}
          ></img>
          <p className="ml-[10px] font-bold text-[20px]">{collectionName}</p>
        </div>
        <p className="ml-[10px] font-bold text-[18px]">
          {"Floor price: " +
            (collection.stats.floor_price !== 0
              ? collection.stats.floor_price + " XRD"
              : "None")}
        </p>
      </div>
      <div
        className={`grid gap-2 lg:gap-4 grid-cols-2 ${isCollectionsActive ? "lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4" : "lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-5"}`}
      >
        {filteredNfts.map((nft: CollectionItem, index) => (
          <NewDesignItem
            key={index}
            imageUrl={nft.item_data.image_url}
            price={nft?.proposal}
            owner={nft.owner}
            title={nft.item_data.name}
            itemId={nft?.item_data?.id}
            collectionId={nft?.collection_id}
            collectionName={collectionName}
            collection={collection}
            nft={nft}
          />
        ))}
      </div>
      {filteredNfts.length === 0 && (
        <div className="px-6 py-6 bg-white rounded-lg shadow-sm w-[300px] mx-auto">
          <p>Oops! No NFTs meet the selected criteria.</p>
        </div>
      )}
    </div>
  );
};

export default UserNftsFromCollection;
