import { MintingInformationResponse } from "../../types/response/Collection";
import { MintingInformationRequest } from "../../types/requests/Collection";
import { callPostEndpoint } from "../Utils";
import { backend_url } from "../../Constants";
import client from "../client";
import { GetShortItemsRequest } from "../../types/requests/Item";
import { GetShortItemsResponse } from "../../types/response/Item";
import { AccountAddress } from "../../types/Radix";
import { NFTCollection, CollectionItem } from "../../types/Collection";
import { GetItemsOwnedByResponse } from "../../types/response/Item";
import { GetItemsOwnedByRequest } from "../../types/requests/Item";
import { getRolaToken } from "../Rola";

const fetchItemsFromCollection = async (id: string) => {
  const response = await client.get(
    `/getItemsByCollectionId?id=${id}&authenticationToken=${getRolaToken()}`,
  );
  return response.data;
};

export async function fetchShortItemsFromCollection(
  collection_id: number,
  user: string,
  status: "All" | "Listed" | "Owned",
  price_order: "ASC" | "DESC",
  traits_filter: { [traits_name: string]: string[] },
  name_filter?: string,
  min_price?: number,
  max_price?: number,
  index?: number,
): Promise<GetShortItemsResponse> {
  const authentication_token = getRolaToken();
  if (!authentication_token) {
    console.error("You are not authenticated");
  }

  const request: GetShortItemsRequest = {
    authentication_token: authentication_token ? authentication_token : "",
    collection_id: collection_id,
    user: user,
    name_filter: name_filter,
    min_price: min_price,
    max_price: max_price,
    price_order: price_order,
    status: status,
    traits_filter: traits_filter,
    index: index,
  };
  return await callPostEndpoint("/getShortItemsForCollection", request);
}

export async function getItemById(
  collectionId: number,
  id: string,
): Promise<CollectionItem> {
  try {
    let response;
    response = await fetch(
      backend_url +
        "/getItemById?collectionId=" +
        collectionId +
        "&id=" +
        id +
        "&authenticationToken=" +
        getRolaToken(),
    );
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error; // Rethrow the error for handling by the caller
  }
}

export async function getMintingInformation(
  collection_id: number,
): Promise<MintingInformationResponse> {
  let request: MintingInformationRequest = { collection_id: collection_id };
  return await callPostEndpoint("/getMintingInformation", request);
}

export async function getIdsOwnedBy(
  user: AccountAddress,
  collection: NFTCollection,
): Promise<GetItemsOwnedByResponse> {
  let request: GetItemsOwnedByRequest = {
    user: user,
    collection_id: collection.id,
  };
  return await callPostEndpoint("/getIdsOwnedBy", request);
}

const actions = {
  fetchItemsFromCollection,
};

export default actions;
