import { useEffect, useState } from "react";

interface CheckBoxProps {
  trait: string;
  onClick: (isChecked: boolean, trait: string) => void;
}

export default function CheckBox({ trait, onClick }: CheckBoxProps) {
  const [checked, setChecked] = useState(false);
  const [needsUpdate, setNeedsUpdate] = useState(false);

  function onChecked() {
    setChecked(!checked);
    setNeedsUpdate(true);
  }

  useEffect(() => {
    if (needsUpdate) {
      onClick(checked, trait);
      setNeedsUpdate(false);
    }
  }, [checked, needsUpdate, onClick, trait]);
  // check box
  // return (
  //   <div
  //     className="flex items-center justify-center w-4 h-4 border border-solid border-neutral-300 rounded-full"
  //     onClick={onChecked}
  //   >
  //     {checked && <div className="w-2 h-2 bg-black-base rounded-full"></div>}
  //   </div>
  // );
  return (
    <>
      <input
        type="checkbox"
        className="hidden"
        checked={checked}
        onChange={onChecked}
      />
      <label
        htmlFor="checkbox"
        onClick={onChecked}
        className="flex items-center justify-center w-[17px] h-[17px] border border-solid border-neutral-300 rounded-md cursor-pointer relative"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={`absolute h-[14px] w-[14px] ${
            checked ? "block" : "hidden"
          }`}
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <polyline points="20 6 9 17 4 12"></polyline>
        </svg>
      </label>
    </>
  );
}
