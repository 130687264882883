import { useQuery } from 'react-query';

import actions  from '../../backend/collections/CollectionGetters';

const useFetchCollectionDetails = (id: string) => {
  return useQuery(
    ['fetchCollectionDetails'],
    async () => {
      const collectionDetails = await actions.fetchCollectionDetails(id);
      return collectionDetails;
    },
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
    },
  );
};

export default useFetchCollectionDetails;