import {ReactComponent as RadixIcon} from '../../assets/global/radixIcon.svg';
import Skeleton from 'react-loading-skeleton';

const HomePageStatsItem: React.FC<{
  Icon: any,
  title: string,
  amount: string | number,
  type: string,
  isLoading: boolean,
}> = ({Icon, title, amount, type, isLoading}) => {

  return (
      <div className="flex w-full items-center justify-between lg:justify-center max-lg:py-[10px] lg:my-[15px] lg:mx-[0px]">
        <div className='flex flex-col'>
            <Icon className='w-[22px] h-[22px] md:w-[34px] md:h-[34px]'/>
            <p className="lg:hidden text-[14px] md:text-base mt-[6px] font-normal text-grey-dark">{title}</p>
        </div>
        <div className="flex flex-col ml-[22px] text-left">
            <p className="max-lg:hidden text-[14px] md:text-base font-normal text-grey-dark">{title}</p>
          { isLoading ? (
            <Skeleton count={1} height={24} width={90}/>
          ) : (
            <div className="flex items-center">
            {type === 'volume' &&
              <RadixIcon className='mr-[8px]' />
            }
              <p className="text-[20px] md:text-[24px] font-semibold text-black">{type === 'string' ? `${amount}` : `${Math.round(Number(amount))}`}</p>
          </div>
          )
          }
        </div>
      </div>
  )
};

export default HomePageStatsItem;