
const TraitCard: React.FC<{
  name: string,
  category: string,
  rare_stat: string,
  rareness: string,

}> = ({name, category, rare_stat, rareness}) => {
  return (
      <div className={`w-full h-full flex-col bg-grey-medium p-[10px]`}>
       <p className="text-[14px] text-grey-dark">{category}</p>
       <p className="uppercase text-[16px] font-semibold text-black-base">{name}</p>
          <p className="text-[14px] text-grey-dark">{`${rare_stat} %`}</p>
      </div>
  )
};

export default TraitCard;