import { useRef, useState } from "react";

interface DragAndDropProps {
  onUpload: (files: File[]) => void;
  children: React.ReactNode;
  styles?: string;
  accept?: string;
  multiple?: boolean;
}

export default function DragAndDrop({
  onUpload,
  children,
  styles,
  accept,
  multiple
}: DragAndDropProps) {
  const [dragging, setDragging] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);

    const files = Array.from(e.dataTransfer.files);
    setFiles(files);
    onUpload(files);
  };

  const handleContainerClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || []);
    setFiles(files)
    onUpload(files);
  };

  return (
    <div
      className={`${styles}
      border-2 border-dashed border-[#93989A] p-4 rounded-[15px] h-[300px] cursor-pointer  ${
        dragging ? "bg-white " : "bg-[#FBFBFE]"
      }`}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onClick={handleContainerClick}
    >
      <>
        <div className="flex flex-col items-center justify-center w-full h-full text-center">
          <div>
            {children}
            {files.length > 0 ? (
                <p className="mt-1 text-sm text-[#535AFA]">
                  <span>{files[0].name}</span>
                  {/* <span
                  className="text-[#93989A] ml-1 p-1 px-2 border rounded-full"
                  onClick={(e) => {
                    e.stopPropagation();
                    setFiles([]);
                  }}
                >
                  X
                </span> */}
                </p>
            ) : (
                <p className="mt-1 text-sm text-[#93989A]">
                  Click to <span className="text-[#535AFA]">browse</span> or drop
                  your asset here
                </p>
            )}
          </div>
        </div>
        <input
            ref={fileInputRef}
            type="file"
            id="fileInput"
            className="hidden"
            multiple={multiple}
            accept={accept}
          onChange={handleFileInputChange}
        />
      </>
    </div>
  );
}
