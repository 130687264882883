import {NFTCollection} from "../../types/Collection";
import {AccountAddress} from "../../types/Radix";
import {sendTransaction} from "../Global";
import {badge_image_url, website_url} from "../../Constants";
import {TransactionResult} from "../../types/Transaction";

/**
 * Creates a badge that will control the given collection.
 * @param account - account that will receive the badge.
 * @param collection - collection that will be controlled by the badge.
 */
export async function createCollectionBadge(account: AccountAddress, collection: NFTCollection): Promise<TransactionResult> {

    let badge_name = collection.data.name + " badge";
    let badge_description  = "Controls the collection";
    let badge_icon = badge_image_url;
    let symbol = "";
    let tags = 'Array<String>("badge")';
    let info_url = `${website_url}`;

    let manifest = `
    CREATE_FUNGIBLE_RESOURCE_WITH_INITIAL_SUPPLY
        Enum<0u8>()
        false
        0u8
        Decimal("1")
        Tuple(
            Enum<1u8>(
                Tuple(
                    Enum<1u8>(
                        Enum<1u8>()
                    ),
                    Enum<1u8>(
                        Enum<1u8>()
                    )
                )
            ),
            Enum<1u8>(
                Tuple(
                    Enum<1u8>(
                        Enum<0u8>()
                    ),
                    Enum<1u8>(
                        Enum<1u8>()
                    )
                )
            ),
            Enum<1u8>(
                Tuple(
                    Enum<1u8>(
                        Enum<1u8>()
                    ),
                    Enum<1u8>(
                        Enum<1u8>()
                    )
                )
            ),
            Enum<1u8>(
                Tuple(
                    Enum<1u8>(
                        Enum<1u8>()
                    ),
                    Enum<1u8>(
                        Enum<1u8>()
                    )
                )
            ),
            Enum<1u8>(
                Tuple(
                    Enum<1u8>(
                        Enum<0u8>()
                    ),
                    Enum<1u8>(
                        Enum<1u8>()
                    )
                )
            ),
            Enum<1u8>(
                Tuple(
                    Enum<1u8>(
                        Enum<0u8>()
                    ),
                    Enum<1u8>(
                        Enum<1u8>()
                    )
                )
            )
        )
        Tuple(                                                                   # Metadata initialization
            Map<String, Tuple>(                                                
                "name" => Tuple(
                    Some(Enum<Metadata::String>("${badge_name}")),                       # Collection Name
                    true                                                         # Locked
                ),
                "description" => Tuple(
                    Some(Enum<Metadata::String>("${badge_description}")),                # Collection description
                    true                                                         # Locked
                ),
                "tags" => Tuple(
                    Some(Enum<Metadata::StringArray>(${tags})),              # Collection tags
                    true                                                         # Locked
                ),
                "symbol" => Tuple(
                    Some(Enum<Metadata::String>("${symbol}")),                   # Collection symbol
                    true                                                         # Locked
                ),
                "icon_url" => Tuple(
                    Some(Enum<Metadata::Url>("${badge_icon}")),                   # Collection icon
                    true                                                         # Locked
                ),
                "info_url" => Tuple(
                    Some(Enum<Metadata::String>("${info_url}")),                   # Collection info
                    true                                                         # Locked
                )
            ),
            Map<String, Enum>(                                                   # Metadata roles
                "metadata_setter" => Some(Enum<AccessRule::DenyAll>()),         # Metadata setter role
                "metadata_setter_updater" => Some(Enum<AccessRule::DenyAll>()),                               # Metadata setter updater role as None defaults to OWNER
                "metadata_locker" => Some(Enum<AccessRule::DenyAll>()),          # Metadata locker role
                "metadata_locker_updater" => Some(Enum<AccessRule::DenyAll>())                                # Metadata locker updater role as None defaults to OWNER
            )
        )
        Enum<0u8>();
    
    CALL_METHOD
        Address("${account}")
        "deposit_batch"
        Expression("ENTIRE_WORKTOP");`;

    return await sendTransaction(manifest, `Creates an admin badge for your collection "${collection.data.name}"`);
}