import React, {useEffect, useState} from "react";
import NftItem from "../components/claimPreBabylonNfts/NftItem";
import {Claimable} from "../types/Claims";
import {claimItems, getClaimableInformation, getClaimable} from "../backend/Claims";
import RadixWalletModal from "../components/modals/RadixWalletModal";
import {canDeposit, getCurrentUser, getNFTTraderCard, makeDeposit} from "../ledger/Global";
import {claimRevenue} from "../ledger/kaupa/ClaimRevenue";
import {kaupa_component, radland_backend_account, radland_trader_card, xrd_address} from "../Constants";
import {updateFromLedger} from "../backend/UpdateBackend";
import ErrorModal from "../components/modals/ErrorModal";

export default function ClaimPreBabylonNfts() {
    const [claimable , setClaimable] = useState<Claimable>({user: "", items: [], collectable_funds: 0})

    const [isRadixWalletModalOpen, setIsRadixWalletModalOpen] = useState<boolean>(false);
    const [walletMessage, setWalletMessage] = useState<string>("");
    const [walletTitle, setWalletTitle] = useState<string>("")
    const [status, setStatus] = useState<"success" | "error" | "loading">("loading");
    const [loadingMessage, setLoadingMessage] = useState<string|undefined>(undefined);

    const [isErrorModalOpen, setIsErrorModalOpen] = useState<boolean>(false);
    const [errorTitle, setErrorTitle] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string>("");

    async function onFundsClick() {
        setIsRadixWalletModalOpen(true);
        let trader_card = await getNFTTraderCard(radland_trader_card);
        if(!trader_card){
            setStatus("error");
            setWalletTitle("Something went wrong!")
            setWalletMessage("You have no trader card but can claim revenue, please contact an administrator")
        }
        else{
            
            setStatus("loading");
            setWalletTitle("Collecting your sales revenue")
            setWalletMessage("");
            let receipt = await claimRevenue(getCurrentUser(), kaupa_component, trader_card);

            switch (receipt.outcome){
                case "SUCCESS":{
                    if(receipt.info.transaction_status === "CommittedSuccess"){
                        setStatus("success");
                        setWalletTitle("Claimed revenue with success!")
                        setWalletMessage("");
                        await updateFromLedger();
                    }
                    break;
                }

                case "FAILED":{
                    setStatus("error")
                    setWalletTitle("Transaction failed!")
                    setWalletMessage(`Transaction failed with error: ${receipt.error}.`)
                    break;
                }

                case "TIMEOUT": {
                    setStatus("error");
                    setWalletTitle("Unable to get answer from wallet!")
                    setWalletMessage("Interaction with wallet has timed out")
                    await updateFromLedger();
                }
            }
        }
    }

    async function onClaimAllClick(){

        let can_deposit = await canDeposit(getCurrentUser());
        if(!can_deposit){
            setIsRadixWalletModalOpen(false)
            setIsErrorModalOpen(true)
            setErrorTitle("Cannot airdrop items")
            setErrorMessage("Please make sure to allow deposit to your account for the claiming process.")
            return;
        }

        setIsRadixWalletModalOpen(true);
        setStatus("loading");
        setWalletTitle("Fetching deposit amount");
        setWalletMessage("A deposit is needed to airdrop your NFTs")
        setLoadingMessage("Waiting for backend response")
        let deposit = 0;
        let block = false;

        // Get how much deposit to ask for
        try{
            let info = await getClaimableInformation(claimable.items.length);
            deposit = info.airdrop_cost;
        } catch (err){
            setStatus("error");
            setWalletTitle("Something went wrong")
            setWalletMessage("Please check your internet connection or contact an administrator.")
            block = true;
        }

        // Making the deposit
        if(!block){
            setStatus('loading')
            setLoadingMessage(undefined);
            setWalletTitle("Please sign the deposit transaction")
            setWalletMessage("This deposit is needed to airdrop your NFTs, extra funds will be refunded")
            let res = await makeDeposit(getCurrentUser(), deposit, `Making a ${deposit} XRD deposit to pay for airdrop costs. Extra funds will be refunded`, xrd_address, radland_backend_account);
            switch (res.outcome) {
                case "SUCCESS":{
                    break;
                }

                case "FAILED":{
                    block = true;
                    setStatus("error");
                    setWalletTitle("Could not make the deposit");
                    setWalletMessage(`Transaction failed with error: ${res.error}`)
                    break;
                }

                case "TIMEOUT": {
                    block = true;
                    setStatus("error");
                    setWalletTitle("Unable to get answer from wallet!")
                    setWalletMessage("Interaction with wallet has timed out")
                }
            }
        }

        // Actually claiming the funds
        if(!block){
            try{
                setStatus("loading");
                setWalletTitle("Airdropping your items")
                setLoadingMessage("Making the airdrop")
                setWalletMessage("Please wait while we process the transaction")
                let res = await claimItems(getCurrentUser());
                if(res.outcome === "SUCCESS"){
                    setStatus("success");
                    setWalletTitle("Items claimed successfully!")
                    setWalletMessage(`All items were claimed and ${res.refunded} XRD were refunded`)
                }
                else{
                    if(res.outcome === "PARTIAL SUCCESS"){
                        setStatus("error")
                        setWalletTitle("Some items could have not been claimed")
                        setWalletMessage(`Only ${res.nb_minted} items were claimed and ${res.refunded} have been refunded`)
                    }
                    else{
                        setStatus("error")
                        setWalletTitle("Something went wrong.")
                        setWalletMessage("No items have been claimed. Please contact an administrator")
                    }
                }
            }
            catch (error){
                setStatus("error")
                setWalletTitle("Something went wrong.")
                setWalletMessage("No items have been claimed. Please contact an administrator")
            }
        }
    }

    async function updateClaimable() {
        let result = await getClaimable()
        setClaimable(result.claimable);
    }

    useEffect(() => {
        updateClaimable()
    }, [])


  return (
    <div className="w-full h-full py-[30px] px-[30px] md:py-[80px] md:px-[77px] bg-[#F7F9FA]">
        <div className="flex flex-col md:flex-row justify-between items-center gap-2">
            <h3 className="text-[24px] font-semibold">{`Revenue from selling NFTs: ~ ${claimable.collectable_funds} XRD`}</h3>
            <button
                className="flex items-center gap-x-[10px] px-[15px] py-[13px] bg-[#535AFA] text-white rounded-lg"
                onClick={onFundsClick}
            >{`Claim sales revenue`}</button>
        </div>
      <div className="flex flex-col md:flex-row justify-between items-center gap-2">
        <h3 className="text-[24px] font-semibold">My Pre-Babylon NFTs</h3>
          {
              claimable.items.length > 0 ? (
                  <button
                      className="flex items-center gap-x-[10px] px-[15px] py-[13px] bg-[#535AFA] text-white rounded-lg"
                      onClick={onClaimAllClick}
                  >
                      <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                      >
                          <path
                              d="M9.38 11.6802L11.94 14.2402L14.5 11.6802"
                              stroke="white"
                              strokeWidth="1.5"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                          />
                          <path
                              d="M11.9395 4L11.9395 14.17"
                              stroke="white"
                              strokeWidth="1.5"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                          />
                          <path
                              d="M20 12C20 16.42 17 20 12 20C7 20 4 16.42 4 12"
                              stroke="white"
                              strokeWidth="1.5"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                          />
                      </svg>
                      <span className="text-sm">Claim All</span>
                  </button>
              ) : null
          }
      </div>

      <div className="mt-6 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 md:gap-4 gap-2">
        {claimable.items.map((item, i) => (
          <NftItem key={i} item={item} />
        ))}
      </div>
        <RadixWalletModal
            message={walletMessage}
            title={walletTitle}
            status={status}
            isOpen={isRadixWalletModalOpen}
            setIsOpen={setIsRadixWalletModalOpen}
            loadingMessage={loadingMessage}
        />
        <ErrorModal
            isOpen={isErrorModalOpen}
            setIsOpen={setIsErrorModalOpen}
            message={errorMessage}
            title={errorTitle}
        />
    </div>
  );
}
