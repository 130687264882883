import { SelectOption } from "../../../types/utils.components";
import Select from "../../utils/Select";
import { ReactComponent as SearchIcon } from "../../../assets/global/search_icon.svg";
import { ReactComponent as FilterIcon } from "../../../assets/global/filter_icon.svg";
import React, {useEffect, useState} from "react";

interface FilterBarProps {
  setIsFilterOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsMobileFilterOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSearchFilter: (text?: string) => void;
  getItemsCount: () => number;
  reverseOrder: (selectedValue: string) => void;
}

const rankByOptions: SelectOption[] = [
  {
    label: "Price low to high",
    value: "price-low-to-high",
  },
  {
    label: "Price high to low",
    value: "price-high-to-low",
  }
];

export default function FilterBar({
  setIsFilterOpen,
  setIsMobileFilterOpen,
    setSearchFilter,
    getItemsCount,
    reverseOrder
}: FilterBarProps) {

  const [searchText, setSearchText] = useState<string>("");
  const [rank, setRank] = useState<string>("");
  const [needsUpdate, setNeedsUpdate] = useState<boolean>(false);

  const handleSearchType = (e: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
    if(e.currentTarget.value){
      setSearchText(e.currentTarget.value)
    }
    else{
      setSearchText("")
    }
    setNeedsUpdate(true);
  }

  const handleRankBy = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if(e.currentTarget.value){
      if(e.currentTarget.value !== rank){
        setRank(e.currentTarget.value)
        console.log(rank);
        reverseOrder(e.currentTarget.value)
      }
    }
  }

  useEffect(()=>{
    if(needsUpdate){
      setSearchFilter(searchText === "" ? undefined : searchText)
      setNeedsUpdate(false)
    }
  }, [needsUpdate, setSearchFilter, searchText])

  return (
    <div className="my-4 w-full flex flex-col xl:flex-row gap-2">
      <div className="flex w-full flex-grow items-center gap-x-2">
        <button
          className="items-center gap-x-[10px] border border-solid border-[#F3F3F3] px-4 py-3 rounded-[10px] hidden lg:flex"
          onClick={() => setIsFilterOpen((prev) => !prev)}
        >
          <FilterIcon />
          <span className="text-sm font-medium">Filter</span>
        </button>

        <button
          className="items-center gap-x-[10px] border border-solid border-[#F3F3F3] px-4 py-3 rounded-[10px] flex  lg:hidden"
          onClick={() => setIsMobileFilterOpen((prev) => !prev)}
        >
          <FilterIcon />
          <span className="text-sm font-medium">Filter</span>
        </button>

        {/* search bar */}
        <div className="w-full flex items-center gap-x-4 rounded-lg bg-[#F7F9FA] px-4 py-3">
          <SearchIcon />
          <input
            type="search"
            placeholder="Search by IDs, name, or trait"
            className="placeholder:text-[#93989A] outline-none bg-transparent w-full h-full focus:outline-none"
            onChange={handleSearchType}
            value={searchText}
          />
        </div>
      </div>

      <div className="flex items-center gap-x-2">
        {/* sort by */}
        <div className="min-w-[186px]">
          <Select
            options={rankByOptions}
            value={rank}
            onChange={handleRankBy}
            optionClassname="text-sm font-medium"
            selectComponentClassname="text-sm font-medium"
          />
        </div>

        {/* result count */}
        <div className="min-w-[176px] px-4 py-3 flex items-center gap-x-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
          >
            <circle cx="6" cy="6" r="6" fill="#E8FAEF" />
            <circle cx="6" cy="6" r="4" fill="#47C478" />
          </svg>
          <span className="text-[#93989A] text-sm">{ getItemsCount()>=100 ? `${getItemsCount()}+ results`: `${getItemsCount()} results`}</span>
        </div>
      </div>
    </div>
  );
}
