import CreateCollectionForm from "../components/createCollectionCreator/CreateCollectionForm";

export default function CreateCollectionCreator() {
  return (
    <div className="w-full h-full py-[30px] px-[30px] md:py-[80px] md:px-[77px] bg-white text-text-baseDark">
      <h2 className="font-semibold text-[32px] text-center">
        Create New Collection
      </h2>

      <CreateCollectionForm />
    </div>
  );
}
