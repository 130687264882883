import { useQuery } from 'react-query';

import actions  from '../../backend/collections/CollectionGetters';

const useFetchAllCollectionsSlider = () => {
  return useQuery(
    ['fetchAllCollectionsSlider'],
    async () => {
      const collections = await actions.fetchCollectionsSlider();
      return collections;
    },
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
    },
  );
};

export default useFetchAllCollectionsSlider;