import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { ReactComponent as DotIcon } from "../../assets/global/dot.svg";

interface MintConfirmModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  listItem: (mintAmount: number) => Promise<void>;
}

export default function ListForSaleModal({
                                           isOpen,
                                           setIsOpen,
                                           listItem
}: MintConfirmModalProps)
{
  const [listingAmount, setListingAmount] = useState(0);

  async function onClick(){
    console.log("Entered handle.")
    return await listItem(listingAmount);
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => setIsOpen(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black-base/60" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full relative max-w-[600px] transform overflow-hidden rounded-[15px] bg-white px-4 py-6 md:py-8 md:px-8 text-center align-middle shadow-xl transition-all">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  className="absolute top-4 right-6 md:top-6 md:right-10 cursor-pointer"
                  onClick={() => setIsOpen(false)}
                >
                  <path
                    d="M4.16602 4.1665L15.8319 15.8324"
                    stroke="#040409"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M4.16615 15.8324L15.832 4.1665"
                    stroke="#040409"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <h3 className="text-[24px] font-semibold">List NFT for Sale</h3>

                <div className="mt-[40px] md:mt-[60px] text-left max-w-[420px] mx-auto">
                  <div>
                    <p className="font-semibold">Select a type of sale</p>
                    <div className="px-[20px] mt-3 rounded-[10px] py-[10px] border border-[#F3F3F3] flex justify-between items-center">
                      <div>
                        <p className="font-semibold">Fixed price</p>
                        <p className="mt-1 text-[#93989A] text-sm">
                          Set a fixed price before your item is listed.
                        </p>
                      </div>

                      <DotIcon />
                    </div>
                  </div>

                  <div className="mt-4">
                    <p className="font-semibold flex items-center">
                      Set a price
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        className="ml-2"
                      >
                        <path
                          d="M8.00065 14.6663C11.6673 14.6663 14.6673 11.6663 14.6673 7.99967C14.6673 4.33301 11.6673 1.33301 8.00065 1.33301C4.33398 1.33301 1.33398 4.33301 1.33398 7.99967C1.33398 11.6663 4.33398 14.6663 8.00065 14.6663Z"
                          stroke="#040409"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M8 5.33301V8.66634"
                          stroke="#040409"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M7.99609 10.667H8.00208"
                          stroke="#040409"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </p>

                    <div className="mt-3 px-[20px]  border border-[#F3F3F3] rounded-lg flex max-w-[429px]">
                      <input
                        type="number"
                        placeholder="Amount"
                        value={listingAmount}
                        onChange={(e) => setListingAmount(+e.target.value)}
                        className="placeholder:text-[#A6A9B9] py-[16px] flex-grow"
                      />
                      <div className="w-[79px] flex items-center gap-x-[6px] pl-[20px] border-l border-l-[#F3F3F3]">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                        >
                          <path
                            d="M7.13837 15C6.87277 15 6.62029 14.8774 6.46195 14.6664L3.15603 10.2409H1V8.62973H3.58253C3.85069 8.62973 4.10189 8.75356 4.25888 8.96337L6.96045 12.5788L11.0828 3.48275C11.2162 3.18954 11.5158 3 11.8468 3H17V4.61121H12.3923L7.9024 14.5173C7.78202 14.7826 7.52429 14.9646 7.22598 14.9949C7.19853 14.9987 7.16845 15 7.13837 15Z"
                            fill="#535AFA"
                          />
                        </svg>
                        <span className="font-medium">XRD</span>
                      </div>
                    </div>
                  </div>

                  <div className="mt-6  md:mt-8">
                    <p className="font-semibold">Summary</p>
                    <div className="mt-4">
                      <p className="flex items-center justify-between">
                        <span>Listing price</span>
                        <span>{listingAmount} XRD</span>
                      </p>
                      <p className="flex items-center justify-between mt-3">
                        <span>Platform fee</span>
                        <span>0%</span>
                      </p>
                    </div>
                  </div>

                  <div className="mt-6  md:mt-8">
                    <p className="flex items-center justify-between font-bold">
                      <span>Estimated earnings</span>
                      <span>
                        {listingAmount} XRD
                      </span>
                    </p>
                  </div>

                  <button
                    onClick={onClick}
                    className="w-full h-[50px] rounded-lg bg-[#535AFA] mt-[40px] text-white text-sm font-medium"
                  >
                    Complete listing
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
