import {backend_url} from "../Constants";
import {CollectionItem} from "../types/Collection";

/**
 * Sends a request to the Backend to update from ledger.
 */
export async function updateFromLedger(item?: CollectionItem) {
    console.log("making request to update")
    let url = new URL(backend_url + '/updateFromLedger');
    if(item){
        url.searchParams.append('collectionID', item.collection_id.toString());
        url.searchParams.append('itemID', item.item_data.id.toString());
    }

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json', // Set the appropriate content type
            },

        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        try{
            return await response.json() as CollectionItem | undefined;
        } catch (err){
            return undefined;
        }
    } catch (err) {
        throw new Error(`${err}`);
    }
}