import { useState } from "react";

const NFTImage: React.FC<{
  url: string,
  rank?: number,
}> = ({url, rank}) => {
  const [isClicked, setIsClicked] = useState<boolean>(false);

  return (
    <>
      <div className={`w-full h-full flex`}>
        <img className="object-contain w-full cursor-pointer rounded-2xl" alt="asset" src={url}
          onClick={() => setIsClicked(true)} />
      </div>
      { isClicked && (
      <div onClick={() => setIsClicked(false)} className="absolute cursor-pointer inset-x-0 inset-y-0 bg-black-transparent flex z-20">
        <img className="relative h-3/4 m-auto object-contain" alt="asset" src={url} />
      </div>
      )}
    </>
  )
};

export default NFTImage;