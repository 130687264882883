import {useNavigate} from 'react-router-dom';
import ExplorePageNavigationItem from './ExplorePageNavigationItem';

const ExplorePageNavigation: React.FC<{currentCategory: string}> = ({currentCategory}) => {
  const navigate = useNavigate();

  const handleClick = (category: string) => {
    navigate(`/explore/category/${category.toLowerCase()}`)
  }
  return (
      <div className="w-full flex pb-[20px] space-x-10">
        <ExplorePageNavigationItem category='All' isActive={currentCategory === 'all' ? true : false} onClick={(category: string) =>handleClick(category)} />
        <ExplorePageNavigationItem category='PFPs' isActive={currentCategory === 'pfps' ? true : false} onClick={(category: string) =>handleClick(category)} />
        <ExplorePageNavigationItem category='Art' isActive={currentCategory === 'art' ? true : false} onClick={(category: string) =>handleClick(category)} />
        <ExplorePageNavigationItem category='Gaming' isActive={currentCategory === 'gaming' ? true : false} onClick={(category: string) =>handleClick(category)} />
        <ExplorePageNavigationItem category='Memberships' isActive={currentCategory === 'memberships' ? true : false} onClick={(category: string) =>handleClick(category)} />
      </div>
  )
};

export default ExplorePageNavigation;