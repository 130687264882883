import { ReactComponent as PlusIcon } from "../../assets/global/plus_icon.svg";
import {useEffect, useState} from "react";
import {NFTCollection, default_collection} from "../../types/Collection";
import {useLocation, useNavigate} from "react-router-dom";
import {getCollectionById} from "../../backend/collections/CollectionGetters";
import {updateCollection} from "../../backend/collections/CollectionUpdates";
import ErrorModal from "../modals/ErrorModal";

const dragAndDropChildren = (
  <div className="flex flex-col items-center justify-center w-full h-full">
    <div className="border-[1.5px] border-dashed rounded-lg boder-[#F3F3F3] p-[28px]">
      <PlusIcon />
    </div>
    <p className="mt-3 text-sm text-text-gray">Recommended size: 800 x 800</p>
  </div>
);

export default function GraphicsTab() {
    const [collection, setCollection] = useState<NFTCollection>(default_collection);
    const [icon, setIcon] = useState<string>("");
    const [background, setBackground] = useState<string>("")
    const [isErrorOpen, setIsErrorOpen] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [errorTitle, setErrorTitle] = useState<string>("");

    const navigate = useNavigate();
    const location = useLocation();
    let collection_id = parseFloat(location.pathname.split('/')[3]);

    async function getData() {
        try {
            const result = await getCollectionById(collection_id);
            setCollection(result);
        } catch (err) {
            setIsErrorOpen(true);
            setErrorTitle("This collection is private")
            setErrorMessage("Cannot access data of unpublished collection")
        }
    }

    useEffect(() => {
        getData()
    }, [])

    async function handleClick()  {
        if(collection.address){
            setIsErrorOpen(true)
            setErrorTitle("Cannot edit details of a published collection!")
            setErrorMessage("Collection details are immutable.")
        }
        else{
            if(icon != "") { collection.data.icon_url = icon }
            if(background != "") { collection.background_image = background }

            await updateCollection(collection);
            navigate(`/collections/${collection_id}`);
        }
    }
  return (
    <div className="w-full max-w-[608px] mx-auto p-2 mt-[48px]">
        <p className="text-text-gray text-xs font-medium w-full">
            Not edited fields will remain the same
        </p>

        <div className="w-full mt-3">
            <label className="mb-1 font-bold" htmlFor="collectionName">
                 Collection's icon
            </label>
            <input
                type="text"
                id="collectionName"
                name="collectionName"
                placeholder= {collection.data.icon_url}
                onChange={(e) => setIcon(e.target.value) }
                className="w-full mt-1 px-[20px] py-[16px] placeholder:text-[#A6A9B9] border border-solid border-[#F3F3F3] outline-none focus:outline-none rounded-lg box-border"
            />
        </div>

      {/* banner image upload */}
        <div className="w-full mt-3">
            <label className="mb-1 font-bold" htmlFor="collectionName">
                Collection's Background
            </label>
            <input
                type="text"
                id="collectionName"
                name="collectionName"
                placeholder= {collection.background_image}
                onChange={(e) => setBackground(e.target.value) }
                className="w-full mt-1 px-[20px] py-[16px] placeholder:text-[#A6A9B9] border border-solid border-[#F3F3F3] outline-none focus:outline-none rounded-lg box-border"
            />
        </div>


        {/* submit btn */}
        <button
            className="w-full mt-[32px] px-[24px] py-[16px] font-medium text-white bg-[#535AFA] flex items-center justify-center rounded-lg"
            type="button"
            onClick={handleClick}
        >
            Save changes
        </button>
        <ErrorModal
            isOpen={isErrorOpen}
            setIsOpen={setIsErrorOpen}
            message={errorMessage}
            title={errorTitle}
        />
    </div>
  );
}
