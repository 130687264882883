import {useEffect, useState} from "react";
import { Tab } from "../types/utils.components";
import Tabs from "../components/utils/Tabs";
import DetailsTab from "../components/editCollection/DetailsTab";
import GraphicsTab from "../components/editCollection/GraphicsTab";

export default function EditCollection() {
  const [activeTab, setActiveTab] = useState<number>(0);

  const tabs: Tab[] = [
    {
      id: 0,
      label: "Details",
      content: <DetailsTab/>,
    },
    {
      id: 1,
      label: "Graphics",
      content: <GraphicsTab />,
    },
  ];

  return (
    <div className="w-full h-full py-[30px] px-[30px] md:py-[80px] md:px-[77px] bg-white text-text-baseDark">
      <h2 className="font-semibold text-[32px] text-center">
        Collection details
      </h2>

      <Tabs
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabBarClassName="mt-[48px] w-full mx-auto max-w-[474px] h-[53px]"
        tabsContainerClassName="h-full"
      />
    </div>
  );
}
