import HomePageStatsItem from "./HomePageStatsItem";
import {ReactComponent as Owners} from '../../assets/marketStats/totalOwnersIcon.svg';
import {ReactComponent as Items} from '../../assets/marketStats/totalItemsIcon.svg';
import {ReactComponent as Volume} from '../../assets/marketStats/totalVolumeIcon.svg';
import {ReactComponent as Transactions} from '../../assets/marketStats/totalTransactionsIcon.svg';
import { useEffect, useState } from "react";
import { getHomeStats } from "../../backend/collections/Stats";

const HomePageStats: React.FC = () => {

  const [totalItems, setTotalItems] = useState<number>(0);
  const [totalOwners, setTotalOwners] = useState<number>(0);
  const [totalVolume, setTotalVolume] = useState<number>(0);
  const [totalTransactions, setTotalTransactions] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);


  async function getStats() {
    const {total_items, total_owners, total_volume, total_transactions} = await getHomeStats();
    console.log(total_items, total_owners, total_volume, total_transactions)
    setTotalItems(total_items);
    setTotalOwners(total_owners);
    setTotalVolume(total_volume);
    setTotalTransactions(total_transactions);
    setLoading(false);
  }

  useEffect(() => {
    getStats()
  }, [])

  return (
      <div className="w-full grid grid-cols-1 max-lg:divide-y lg:grid-cols-4 lg:divide-x 
        px-[32px] lg:px-[35px] py-4 lg:py-[44px] rounded-[15px] bg-grey-light2
        border border-grey-medium">
        <HomePageStatsItem
          Icon={Items}
          title="Total items"
          amount={totalItems}
          type="number"
          isLoading={loading}
        />
        <HomePageStatsItem
          Icon={Owners}
          title="Total owners"
          amount={totalOwners}
          type="number"
          isLoading={loading}
        />
        <HomePageStatsItem
          Icon={Volume}
          title="Total volume"
          amount={totalVolume}
          type="volume"
          isLoading={loading}
        />
        <HomePageStatsItem
          Icon={Transactions}
          title="Transactions"
          amount={totalTransactions}
          type="number"
          isLoading={loading}
        />
        
      </div>
  )
};

export default HomePageStats;