import {useState} from 'react';
import {useNavigate} from 'react-router-dom';

const SidebarMenuItem: React.FC<{
  name: string,
  link?: string,
  Icon?: any,
  isOpen: boolean,
  isActive: boolean,
  type: string,
  isDisabled: boolean,
}> = ({name, link, Icon, isOpen, isActive, type, isDisabled}) => {
  const navigate = useNavigate();
  const [isHovering, setIsHovering] = useState(false);
  const greyColorFill: string = 'hsl(197, 3%, 59%)';
  const darkPurpleColorFill: string = 'hsl(237, 94%, 65%)';

  const handleMouseMove = (): void => {
    setIsHovering(!isHovering);
  };

  const handleSidebarItemClick = (url: string): void => {
    navigate(url);
  }


  if (type === 'link' && link) {
     return (
    <div onClick={() => isDisabled ? '' : handleSidebarItemClick(link)} onMouseEnter={handleMouseMove} onMouseLeave={handleMouseMove}
      className={`w-full relative flex items-center py-[13px] cursor-pointer rounded-[10px] 
        ${isOpen ? 'pl-[17px] content-start' : ''} ${isDisabled ? 'hover:cursor-not-allowed' : ''}
      ${(isActive || (isHovering && !isDisabled)) ? 'bg-purple-light' : ''}`}>
      <Icon className={`${isOpen ? 'mr-[20px]' : 'mx-auto'}`}  fill={`${(isActive || (isHovering && !isDisabled)) ? darkPurpleColorFill : greyColorFill}`}/>
      { isOpen &&
        <p className={`font-medium text-[14px] ${(isActive || (isHovering && !isDisabled)) ? 'text-purple-medium' : 'text-grey-dark'}`}>{name}</p>
      }
      { !isOpen && isHovering &&
        <div className='bg-grey-light py-2 px-3 rounded-r-xl border-l-0 border border-purple-medium absolute left-[55px]'>
           <p className={`font-medium text-[12px] whitespace-nowrap  ${(isActive || (isHovering && !isDisabled)) ? 'text-purple-medium' : 'text-grey-dark'}`}>{name}</p>
        </div>
      }
    </div>
  )} else {
    return (
      <>
        { isOpen &&
          <p className='text-black-base font-medium cursor-default
            text-[12px] pl-[17px] mt-[30px] mb-[13px]'>
            {name}
          </p>
        }
      </>
    )
  };
};

export default SidebarMenuItem;