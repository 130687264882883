import {ReactComponent as CollectionIcon } from '../../assets/userDashboard/collectionIcon.svg'
import {ReactComponent as ListedIcon } from '../../assets/userDashboard/listedIcon.svg'

const SortingMenu: React.FC<{
    isCollectionsActive: boolean,
    isListedActive: boolean,
    onCollectionsClick: () => void,
    onListedClick: () => void,
}> = ({onCollectionsClick, onListedClick, isCollectionsActive, isListedActive}) => {
    return (
      <div className={`w-full h-full flex justify-between mb-4`}>
        <div onClick={onCollectionsClick} className={`px-[16px] py-[12px] border-grey-medium shadow-sm rounded-lg pointer flex
        items-center cursor-pointer ${isCollectionsActive ? 'bg-purple-medium' : 'bg-white' }`}>
            <CollectionIcon className="mr-2" width="25" height="25" fill="#FFF" />
            <p className={`font-medium text-[14px] ${isCollectionsActive ? 'text-white' : 'text-black-base' }`}>Collections</p>
       </div>
       <div onClick={onListedClick} className={`px-[16px] py-[12px] border-grey-medium  shadow-sm rounded-lg pointer flex
       items-center cursor-pointer ${isListedActive ? 'bg-purple-medium' : 'bg-white' }`}>
            <ListedIcon className="mr-2" width="25" height="25" fill="#FFF" />
            <p className={`font-medium text-[14px] ${isListedActive ? 'text-white' : 'text-black-base' }`}>Listed</p>
       </div>
      </div>
    )
}

export default SortingMenu;