import { ImportCollectionForm } from "../components/importCollection/ImportCollectionForm";
import React, { useState } from "react";
import { NFTCollection } from "../types/Collection";
import { getCollectionFromAddress } from "../backend/collections/CollectionUpdates";
import { ResourceAddress } from "../types/Radix";

export default function ImportCollectionPage() {
  const [collection, setCollection] = useState<NFTCollection | undefined>();
  const [resourceToImport, setResourceToImport] = useState<ResourceAddress>("");

  async function onClick(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (resourceToImport !== "" && resourceToImport.includes("resource_")) {
      const collection = await getCollectionFromAddress(resourceToImport);
      setCollection(collection.collection);
    }
  }

  const onChange = (e: {
    currentTarget: { value: React.SetStateAction<string> };
  }) => {
    if (e.currentTarget.value) {
      setResourceToImport(e.currentTarget.value);
    } else {
      setResourceToImport("");
    }
  };

  return (
    <div className="w-full h-full py-[30px] px-[30px] md:py-[80px] md:px-[77px] bg-white text-text-baseDark">
      <h2 className="font-semibold text-[32px] text-center">
        Import A Collection
      </h2>
      {collection ? (
        <ImportCollectionForm collection={collection} />
      ) : (
        <form
          onSubmit={onClick}
          className="w-full max-w-[608px] mx-auto p-2 mt-[48px]"
        >
          <div className="w-full mt-3">
            <label className="mb-1 font-bold" htmlFor="name">
              Collection Address <span className="text-[#FD1616]">*</span>
            </label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Enter collection address"
              className="w-full mt-1 px-[20px] py-[16px] placeholder:text-[#A6A9B9] border border-solid border-[#F3F3F3] outline-none focus:outline-none rounded-lg box-border"
              onChange={onChange}
            />
          </div>

          <div className="w-full flex flex-col md:flex-row items-center gap-6  mt-[32px]">
            <button
              className="w-full px-[24px] py-[16px] font-medium text-white bg-[#535AFA] flex items-center justify-center rounded-lg"
              type="submit"
            >
              Find Collection
            </button>
          </div>
        </form>
      )}
    </div>
  );
}
