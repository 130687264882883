import EditNftForm from "../components/editNft/EditNftForm";
import {useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
import {CollectionItem, default_item} from "../types/Collection";
import {getItemById} from "../backend/collections/ItemGetters";

export default function EditNft() {
    const [item, setItem] = useState<CollectionItem>(default_item);

    const location = useLocation();
    let collection_id = parseFloat(location.pathname.split('/')[2]);
    let item_id = location.pathname.split('/')[3];

    async function getData() {
        setItem(await getItemById(collection_id, item_id))
    }

    useEffect(() => {
        getData()
    }, [getData])

  return (
    <div className="w-full h-full py-[30px] px-[30px] md:py-[80px] md:px-[77px] bg-white text-text-baseDark">
      <h2 className="font-semibold text-[32px] text-center">{ item ? `Edit ${item.item_data.name}`: `Edit item ???` }</h2>

      <EditNftForm
          item={item}
      />
    </div>
  );
}
