import UIButton from "../global/UIButton";
import {getCurrentUser, getNFTTraderCard, mintNFTCard} from "../../ledger/Global";
import {acceptProposals} from "../../ledger/kaupa/AcceptProposal";
import {NFTCollection, CollectionItem, toShortItem} from "../../types/Collection";
import ListForSaleModal from "../modals/ListForSaleModal";
import React, {useState} from "react";
import RadixWalletModal from "../modals/RadixWalletModal";
import {makeProposal} from "../../ledger/kaupa/MakeProposal";
import {updateItem} from "../../backend/collections/ItemUpdates";
import {rescindProposals} from "../../ledger/kaupa/RescindProposal";
import ErrorModal from "../modals/ErrorModal";
import {NFT} from "../../types/Radix";
import {kaupa_component, radland_trader_card, xrd_address} from "../../Constants";
import {updateFromLedger} from "../../backend/UpdateBackend";

const ActionButtonsSection: React.FC<{
    item: CollectionItem | undefined
    collection: NFTCollection | undefined
    btnStyle?: string
}> = ({item, collection, btnStyle = 'btn-buy-purple'}) => {

    const [isListForSaleModalOpen, setIsListForSaleModalOpen] = useState<boolean>(false);
    const [isRadixWalletModalOpen, setIsRadixWalletModalOpen] = useState<boolean>(false);
    const [walletMessage, setWalletMessage] = useState<string>("");
    const [walletTitle, setWalletTitle] = useState<string>("");
    const [status, setStatus] = useState<"success" | "error" | "loading">(
        "loading"
    );
    const [trader_card, setTraderCard] = useState<NFT>({address: "", id: "#-1#"})

    const [isErrorModalOpen, setIsErrorModalOpen] = useState<boolean>(false);
    const [errorTitle, setErrorTitle] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [currentItem, setCurrentItem] = useState<CollectionItem|undefined>(item);

    function getButtonName(): string{
        if(currentItem){
            if(currentItem.owner === getCurrentUser()){
                if(currentItem.minted){
                    if(currentItem.proposal){
                        return "Unlist";
                    }
                    else{
                        return "List for sale";
                    }
                }
                else{
                    if(currentItem.proposal){
                        return "Cancel listing upon minting"
                    }
                    else{
                        return "List item upon minting"
                    }
                }
            }
            else{
                if(currentItem.proposal){
                    return "Buy now"
                }
                else{
                    return ""
                }
            }
        }

        return "Buy now"
    }

    function getOnButtonClick() : () => void {

        if(currentItem){
            if(currentItem.owner === getCurrentUser()){
                if(currentItem.proposal){
                    return makeRemoveFromSaleTransaction
                }
                else{
                    return makeListTransaction
                }
            }
            else{
                if(currentItem.proposal){
                    return makeBuyTransaction
                }
                else{
                    return () => { }
                }
            }
        }

        return () => {};
    }

    async function getTraderCard(){
        let trader_card = await getNFTTraderCard(radland_trader_card);
        if(!trader_card){
            setIsRadixWalletModalOpen(true);
            try{
                setWalletTitle("Minting your RadLand trader card")
                setWalletMessage("This NFT is needed to put items on sale and collect future revenue.")
                setStatus("loading");
                let mint_return = await mintNFTCard();
                if (mint_return.receipt.transaction_status === "CommittedSuccess") {
                    setStatus("success");
                    setWalletMessage(`Successfully minted your RadLand trader card!`);
                    trader_card = mint_return.nft;
                }
                else{
                    setStatus("error")
                    setWalletTitle("Transaction failed!")
                    setWalletMessage(`Transaction failed with error: ${mint_return.receipt.error_message}.`)
                    return;
                }

            } catch(error) {
                setStatus("error")
                setWalletTitle("Transaction rejected by wallet")
                // @ts-ignore
                let error_message  = error.toString();
                setWalletMessage(`Transaction rejected with error: ${error_message}`);
                return;
            }
        }
        setTraderCard(trader_card);
    }

    async function putOnSale(listingAmount: number) {
        // In this case, a user is necessarily connected, so we don't need to check
        if(isListForSaleModalOpen){
            setIsListForSaleModalOpen(false);
            if(currentItem){
                currentItem.proposal = {id: -1, payment: {address: xrd_address, amount: listingAmount}};

                if(currentItem.minted){
                    setIsRadixWalletModalOpen(true);
                    setWalletTitle(`Listing ${currentItem.item_data.name} for ${listingAmount} XRD`)
                    setWalletMessage("")
                    setStatus("loading");
                    let receipt = await makeProposal(
                        getCurrentUser(),
                        kaupa_component,
                        trader_card,
                        [{item: currentItem, maker_fee: {address: xrd_address, amount: 0}}],
                        collection!
                    );
                    switch (receipt.outcome) {
                        case "SUCCESS":{
                            if (receipt.info.transaction_status==="CommittedSuccess"){
                                let new_item = await updateFromLedger(currentItem);
                                if(new_item){
                                    setCurrentItem(new_item);
                                }
                                setStatus("success");
                                setWalletTitle(`Your item was successfully listed for ${listingAmount} XRD`)
                                setWalletMessage(`When your item is sold, you will be able to claim your XRDs in the "claim" page`);
                            }
                            break;
                        }

                        case "FAILED":{
                            setStatus("error")
                            setWalletTitle("Transaction failed")
                            setWalletMessage(`Transaction failed with error: ${receipt.error}.`)
                            break;
                        }

                        case "TIMEOUT": {
                            setStatus("error");
                            setWalletTitle("Unable to get answer from wallet!")
                            setWalletMessage("Interaction with wallet has timed out")
                            let new_item = await updateFromLedger(currentItem);
                            if(new_item){
                                console.log(new_item);
                                setCurrentItem(new_item);
                            }
                        }

                    }
                }
                else{
                    try{
                        await updateItem(currentItem);
                        setStatus("success")
                        setWalletTitle("Item has been put on future sale!")
                        setWalletMessage("YOur item will be put on sale upon minting!")
                        setIsRadixWalletModalOpen(true);
                    } catch (error) {
                        setStatus("error");
                        setWalletTitle("Something went wrong")
                        setWalletMessage("Make sure that you are connected to the internet or contact an administrator")
                        setIsRadixWalletModalOpen(true);
                    }
                }
            }
        }
    }

    async function makeListTransaction() {
        // In this case, a user is necessarily connected
        if(currentItem){
            await getTraderCard();
            if(!trader_card){
                return
            }
            else{
                setIsListForSaleModalOpen(true);
                setIsRadixWalletModalOpen(false);
            }

        }
    }

    async function makeRemoveFromSaleTransaction() {
        // In this case, a user is necessarily connected
        if(currentItem){
            if(currentItem.minted){
                let trader_card = await getNFTTraderCard(radland_trader_card);
                if(!trader_card){
                    setIsRadixWalletModalOpen(true);
                    setStatus("error");
                    setWalletTitle("Cannot remove item from sale!")
                    setWalletMessage("You lost the trader card associated to this NFT!")
                }
                else{
                    setIsRadixWalletModalOpen(true);
                    setStatus("loading");
                    setWalletTitle("Removing item from sale!")
                    setWalletMessage("")
                    const receipt = await rescindProposals(getCurrentUser(), kaupa_component, trader_card, [toShortItem(currentItem)]);

                    switch (receipt.outcome){
                        case "SUCCESS":{
                            if(receipt.info.transaction_status === "CommittedSuccess"){
                                let new_item = await updateFromLedger(currentItem);
                                if(new_item){
                                    setCurrentItem(new_item);
                                }
                                setStatus("success");
                                setWalletTitle("item successfully removed from sale!")
                                setWalletMessage("")
                            }
                            break;
                        }

                        case "FAILED":{
                            setStatus("error")
                            setWalletTitle("Transaction failed")
                            setWalletMessage(`Transaction failed with error: ${receipt.error}`)
                            break;
                        }

                        case "TIMEOUT": {
                            setStatus("error");
                            setWalletTitle("Unable to get answer from wallet!")
                            setWalletMessage("Interaction with wallet has timed out")
                            let new_item = await updateFromLedger(currentItem);
                            if(new_item){
                                console.log(new_item);
                                setCurrentItem(new_item);
                            }
                        }
                    }
                }
            }
            else{
                try{
                    currentItem.proposal = undefined;
                    await updateItem(currentItem);
                    setIsRadixWalletModalOpen(true);
                    setStatus("success")
                    setWalletMessage("Transaction success: items won't be put on sale upon minting")
                } catch (err){
                    setIsRadixWalletModalOpen(true);
                    setStatus("error")
                    setWalletMessage("Something went wrong")
                }
            }
        }
    }

    async function makeBuyTransaction(){
        const user = getCurrentUser();
        if(user === ""){
            setIsErrorModalOpen(true)
            setErrorTitle("Cannot buy item")
            setErrorMessage("Please connect your wallet!")
        }
        else{
            if(currentItem){
                setIsRadixWalletModalOpen(true);
                setWalletTitle(`Buying ${currentItem.item_data.name} for ${currentItem.proposal!.payment.amount} XRD`)
                setWalletMessage("")
                setStatus("loading")
                const receipt = await acceptProposals(user, kaupa_component, [{item: currentItem, taker_fee: {address: xrd_address, amount: 0 } }]);
                switch (receipt.outcome){
                    case "SUCCESS":{
                        if(receipt.info.transaction_status === "CommittedSuccess"){
                            let new_item = await updateFromLedger(currentItem);
                            console.log(new_item);
                            if(new_item){
                                setCurrentItem(new_item);
                            }
                            setStatus("success")
                            setWalletTitle("Successfully bought currentItem!")
                            setWalletMessage("")
                        }
                        break;
                    }
                    case "FAILED":{
                        setStatus("error")
                        setWalletTitle("Transaction failed!")
                        setWalletMessage(`Transaction failed with error: ${receipt.error}`)
                        break;
                    }
                    case "TIMEOUT":{
                        setStatus("error");
                        setWalletTitle("Unable to get answer from wallet!")
                        setWalletMessage("Interaction with wallet has timed out")
                        let new_item = await updateFromLedger(currentItem);
                        if(new_item){
                            setCurrentItem(new_item);
                        }
                    }
                }
            }
        }
    }

    return (
      <div className={`w-full flex justify-between gap-4`}>
        <div className="flex-grow">
            <UIButton onClick={getOnButtonClick()} variant={btnStyle}>
              <p>{getButtonName()}</p>
            </UIButton>
        </div>
          <ListForSaleModal
              isOpen={isListForSaleModalOpen}
              setIsOpen={setIsListForSaleModalOpen}
              listItem={putOnSale}
          />
          <RadixWalletModal
              isOpen={ isRadixWalletModalOpen }
              setIsOpen={ setIsRadixWalletModalOpen }
              title={walletTitle}
              refreshOnSuccess={true}
              message={walletMessage}
              status={status}
          />
          <ErrorModal
              isOpen={isErrorModalOpen}
              setIsOpen={setIsErrorModalOpen}
              message={errorMessage}
              title={errorTitle}
          />
      </div>
  )
};

export default ActionButtonsSection;