import { useEffect, useState, useRef } from "react";
import SearchNavbarMenu from "./SearchNavbarMenu";
import radlandLogo from '../../../assets/sidebarMenu/radlandLogo.svg';
import { Link, useNavigate } from "react-router-dom";
import SearchRecommendation from "../SearchRecommmendation/SearchRecommendation";
import { useRecoilValue } from "recoil";
import { shortCollectionsState } from "../../../state/api/shortCollectionsState";
import { connectedWallet as connectedWalletRecoil } from "../../../state/global/connectedWallet";


const NavbarMenu = () => {
    const [searchText, setSearchText] = useState("");
    const [searchResult, setSearchResult] = useState({ collections: [] });
    const [needsUpdate, setNeedsUpdate] = useState(true);
    const navigate = useNavigate();
    const connectedWallet = useRecoilValue(connectedWalletRecoil)
    const shortCollections = useRecoilValue(shortCollectionsState);
    const searchResultRef = useRef(null);


    useEffect(() => {
        if (needsUpdate) {
            let collections_result = shortCollections.filter(collection => {
                if (collection.name.toLowerCase().includes(searchText.toLowerCase())) {
                    return collection;
                }
                else {
                    return null;
                }
            })

            let newResult = { collections: collections_result, users: [] };
            setSearchResult(newResult);
            setNeedsUpdate(false);
        }
    }, [needsUpdate, shortCollections, searchText])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchResultRef.current && !searchResultRef.current.contains(event.target)) {
                setSearchText('');
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);


    const handleUserType = (e) => {
        if (e.currentTarget.value) {
            setSearchText(e.currentTarget.value)
        }
        else {
            setSearchText("")
        }
        setNeedsUpdate(true);
    }

    const handleItemClick = (collectionId) => {
        setSearchText('');
        navigate(`/collections/${collectionId}`);

    }
    return (
        <div className="w-full flex justify-between px-[32px] py-[20px] bg-white border-b border-grey-medium items-center relative">
            <div className="flex md:w-[50%] max-w-[500px]">
                <img
                    alt="radland logo"
                    className="w-[72px] h-[54px] md:hidden"
                    src={radlandLogo}
                />
                <div className="w-full hidden md:block">
                    <SearchNavbarMenu
                        searchText={searchText}
                        onUserType={handleUserType}
                    />
                </div>
            </div>
            <div className="flex items-center gap-x-3 mr-[32px]">
                <radix-connect-button />
            </div>

            {searchText && (
                <div ref={searchResultRef} className="absolute top-[92px] hidden md:block w-[90%] max-w-[993px] min-h-[100px] py-3  border border-[#F3F3F3] bg-white rounded-[15px] font-poppins search-box-shadow">
                    <SearchRecommendation
                        collections={searchResult.collections}
                        onItemClick={handleItemClick}
                    />
                </div>
            )}
        </div>
    );
};

export default NavbarMenu;