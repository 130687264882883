import { ReactComponent as RadixIcon } from "../../../assets/global/radixIcon.svg";
import { useState, useEffect } from "react";
import { NFTCollection } from "../../../types/Collection";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import CollectionsStatsIcon from "./CollectionsStatsIcon";

const CollectionsStatsTable: React.FC<{
  isLoading: boolean;
  collections: NFTCollection[];
}> = ({ collections, isLoading }) => {
  const [array, setArray] = useState<any[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (collections) {
      const tmpCollectionsData: any = collections.map(
        (collection: NFTCollection) => {
          return {
            name: collection.data.name,
            volume: collection.stats.volume,
            volumeChange: collection.stats.daily_volume,
            floorPrice: collection.stats.floor_price,
            owners: collection.stats.owners,
            items: collection.stats.items,
            icon: collection.data.icon_url,
            id: collection.id,
          };
        },
      );
      setArray(tmpCollectionsData.slice(0, 5));
    }
  }, [collections]);

  return (
    <table className="w-full text-left">
      <thead>
        <tr className="text-grey-dark font-medium text-[10px] uppercase">
          <th className="text-left pl-4">Collection</th>
          <th className="text-right lg:text-left">Volume</th>
          <th className="hidden lg:table-cell">24H Volume</th>
          {/*  <th className='hidden lg:table-cell'>Floor price</th> */}
          <th className="hidden lg:table-cell">Owners</th>
          <th className="hidden lg:table-cell">Items</th>
        </tr>
      </thead>
      <tbody>
        {isLoading
          ? Array.from({ length: 5 }).map((_, index) => (
              <tr
                key={`loading-${index}`}
                className="[&>*]:py-[15px] [&>*]:border-b [&>*]:border-grey-light font-medium [&>*]:last:border-none"
              >
                <td className="pr-[20px]">
                  <Skeleton height={28} />
                </td>
                <td className="pr-[20px] w-[100px]">
                  <Skeleton height={28} />
                </td>
                <td className="hidden lg:table-cell w-[100px] pr-[20px]">
                  <Skeleton height={28} />
                </td>
                {/* <td className='hidden lg:table-cell w-[100px] pr-[20px]'>
              <Skeleton height={28}/>
             </td> */}
                <td className="hidden lg:table-cell w-[100px] pr-[20px]">
                  <Skeleton height={28} />
                </td>
                <td className="hidden lg:table-cell w-[100px] pr-[20px]">
                  <Skeleton height={28} />
                </td>
              </tr>
            ))
          : array.map((collection: any) => (
              <tr
                key={collection.id}
                className="[&>*]:py-[20px] [&>*]:border-b [&>*]:border-grey-light font-medium [&>*]:last:border-none cursor-pointer hover:bg-grey-mediumTransparent"
                onClick={() => {
                  navigate(`/collections/${collection.id}`);
                }}
              >
                <td className="w-full font-semibold text-[16px] flex items-center rounded-md pl-4">
                  <CollectionsStatsIcon iconUrl={collection.icon} />
                  {collection?.name}
                </td>
                <td>
                  <div className="flex items-center text-right justify-end lg:justify-start">
                    <RadixIcon className="mr-[4px]" />
                    {Math.round(Number(collection.volume))}
                  </div>
                </td>
                <td>
                  <div className="flex items-center text-right justify-end lg:justify-start">
                    <RadixIcon className="mr-[4px]" />
                    {collection?.volumeChange}
                  </div>
                </td>
                {/*
              <td className='hidden lg:table-cell'>
                <div className='flex items-center'>
                  {collection?.floorPrice !== 0 && <RadixIcon className='mr-[4px]' />}
                  {collection?.floorPrice === 0 ? "-" : collection?.floorPrice}
                </div>
              </td>
              */}
                <td className="hidden lg:table-cell">
                  {collection
                    ? Object.keys(collection.owners).length
                    : undefined}
                </td>
                <td className="hidden lg:table-cell">{collection?.items}</td>
              </tr>
            ))}
      </tbody>
    </table>
  );
};

export default CollectionsStatsTable;
