import { AccountAddress, FungibleBucket, NFT } from "../types/Radix";
import { NFTCollection, CollectionStats } from "../types/Collection";

export function OptToArg(str: string | null): string {
  if (str == null) {
    return "None";
  } else {
    return `Some("${str}")`;
  }
}

export function FungibleBucketToArg(
  account: AccountAddress,
  bucket: FungibleBucket,
  bucket_name: string,
): string {
  return `
     CALL_METHOD
        Address("${account}")
        "withdraw"
        Address("${bucket.address}")
        Decimal("${bucket.amount}");
     
     TAKE_FROM_WORKTOP
        Address("${bucket.address}")
        Decimal("${bucket.amount}")
        Bucket("${bucket_name}");`;
}

export function ProofToArg(
  account: AccountAddress,
  proof: NFT,
  proof_name: string,
): string {
  return `
    CALL_METHOD
        Address("${account}")
        "create_proof_of_non_fungibles"
        Address("${proof.address}")
        Array<NonFungibleLocalId>(NonFungibleLocalId("${proof.id}"));
    
    CREATE_PROOF_FROM_AUTH_ZONE_OF_NON_FUNGIBLES
      Address("${proof.address}")
      Array<NonFungibleLocalId>(NonFungibleLocalId("${proof.id}"))
      Proof("${proof_name}");`;
}

export function VecContentToArg<T>(content: T[]): string {
  let vec_string = "";
  content.forEach((item) => {
    vec_string += `"${item}", `;
  });
  vec_string.slice(0, -2);

  return vec_string;
}

function OrderedTraitsVec(stats: CollectionStats): string[] {
  let trait_names: string[] = [];

  for (let key in stats.traits_stats) {
    trait_names.push(key);
  }

  return trait_names.sort();
}

export function MetadataToArg(collection: NFTCollection): string {
  let ordered_traits = OrderedTraitsVec(collection.stats);
  let additional_traits_args = "";
  let additional_trait_name = "";
  let data_name = "RadLandData";

  for (let trait of ordered_traits) {
    additional_traits_args += `,
                            Enum<0u8>(
                                12u8
                            )`;

    additional_trait_name += `,
                                    "${trait.split(" ").join("_")}"`;
  }

  return `
    Enum<0u8>(
        Enum<0u8>(
            Tuple(
                Array<Enum>(
                    Enum<14u8>(
                        Array<Enum>(
                            Enum<0u8>(
                                12u8
                            ),
                            Enum<0u8>(
                                12u8
                            ),
                            Enum<0u8>(
                                12u8
                            )${additional_traits_args}
                        )
                    )
                ),
                Array<Tuple>(
                    Tuple(
                        Enum<1u8>(
                            "${data_name}"
                        ),
                        Enum<1u8>(
                            Enum<0u8>(
                                Array<String>(
                                    "name",
                                    "description",
                                    "key_image_url"${additional_trait_name}
                                )
                            )
                        )
                    )
                ),
                Array<Enum>(
                    Enum<0u8>()
                )
            )
        ),
        Enum<1u8>(
            0u64
        ),
        Array<String>()
    )`;
}
