import { useState } from "react";
import collectionIcon from "../../../assets/default/Icon-Artist.jpg"

const CollectionsStatsIcon: React.FC<{
  iconUrl: string,
}> = ({iconUrl}) => {
  const [isImageError, setIsImageError] = useState(false)
  return (
    <img
    className="mr-[14px] w-[42px] h-[42px] object-cover rounded-full"
    alt="collection avatar"
    src={isImageError || !iconUrl ? collectionIcon : iconUrl}
    onError={() => setIsImageError(true)}
  />
  )
};

export default CollectionsStatsIcon;