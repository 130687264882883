import { useState, useEffect } from "react";
import useFetchCollections from "../hooks/CollectionDetails/FetchCollections";
import useFetchLatestCollections from "../hooks/CollectionDetails/FetchLatestCollectios";
import mapCollectionsDataForSlider, { mapShortCollectionsDataForSlider } from "../utils/mapCollectionsDataForSlider";
import useFetchAllCollectionsSlider from "../hooks/CollectionDetails/FetchAllCollectionsSlider";

import HomePageHeader from "../components/HomePage/HomePageHeader";
import HomePageStats from "../components/HomePage/HomePageStats";
import CollectionStatsSection from "../components/global/CollectionsStats/CollectionsStatsSection";
import CollectionSlider from "../components/global/CollectionSlider/CollectionSlider";



const HomePage: React.FC = () => {
  const [recentlyAddedCollections, setRecentlyAddedCollections] = useState<any[]>([]);
  const [allCollectionsSlider, setAllCollectionsSlider] = useState<any[]>([]);
  const { data: recentlyAddedCollectionsData, isLoading: isRecentlyAddedCollectionsDataLoading} = useFetchLatestCollections();
  const { data: collectionsData, isLoading: isCollectionsDataLoading } = useFetchCollections();
  const { data: allCollectionsSliderData, isLoading: isAllCollectionsSliderData } = useFetchAllCollectionsSlider();


  useEffect(() => {
    if (recentlyAddedCollectionsData) {
      const tmpRecentlyAddedCollections = mapCollectionsDataForSlider(recentlyAddedCollectionsData);
      setRecentlyAddedCollections(tmpRecentlyAddedCollections);
    }
  }, [recentlyAddedCollectionsData]);

  useEffect(() => {
    if (allCollectionsSliderData) {
      const tmpAllCollectionsSlider = mapShortCollectionsDataForSlider(allCollectionsSliderData.collections);
      setAllCollectionsSlider(tmpAllCollectionsSlider);
    }
  }, [allCollectionsSliderData]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  
    return (
      <div className={`w-full h-full flex flex-col py-[30px] px-[30px] md:py-[80px] md:px-[77px] text-center background-home-page overflow-x-hidden`}>
        <HomePageHeader />
        <div className="mt-[30px]">
          <HomePageStats />
        </div>
        <div className="w-full mt-[40px] mb-[20px]">
          <CollectionSlider title="Recently listed" category="recently_listed" showMore={false} isLoading={isRecentlyAddedCollectionsDataLoading} collections={recentlyAddedCollections} />
        </div>
        <div className="mt-[30px] w-full">
        <div className="mb-[20px]">
          <p className="text-[24px] text-black font-semibold text-left">Top Collections</p>
        </div>
          <CollectionStatsSection collections={collectionsData} isLoading={isCollectionsDataLoading}/>
        </div>
        <div className="w-full mt-[30px] my-[20px]">
          <CollectionSlider title="Explore All Collections" category="art" isLoading={isAllCollectionsSliderData} collections={allCollectionsSlider} />
        </div>
      </div>
    )
}

export default HomePage;