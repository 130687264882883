import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const SkeletonCollectionCard: React.FC = () => {
  return (
     <div className="flex flex-col w-full md:w-[252px] h-[289px] bg-white rounded-[15px] p-[7px] shadow-lg">
      <div className='rounded-[15px] overflow-hidden mb-[20px]'>
        <Skeleton width={286} height={190} containerClassName="flex-1"/>
      </div>
      <div className="self-center pb-[20px]">
        <Skeleton count={2} height={24} width={240}/>
      </div>
     </div>
  )
};

export default SkeletonCollectionCard;