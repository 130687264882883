import {useNavigate} from "react-router";
import UIButton from "../global/UIButton";

const HomePageHeader: React.FC = () => {
  const navigate = useNavigate();

  return (
      <div className="w-full text-left flex flex-col rounded-[15px] background-header">
        <div className="w-full h-full p-[50px] rounded-[15px] bg-gradient-to-r from-purple-headerGradientStart from-[40%] to-purple-headerGradientEnd">
          <p className="font-semibold text-[18px] md:text-[32px] text-white mb-[12px] xl:w-[60%]">
            Surfing the DeFi Waves!
          </p>
        <p className="text-[12px] md:text-[14px] text-white opacity-60">Uncover Digital Treasures & Ride the NFT tides!</p>
        </div>
      </div>
  )
};

export default HomePageHeader;