const mapCollectionsDataForSlider = (collections: any) => {
    if (!Array.isArray(collections)) {
        // Return an empty array or handle the non-array scenario as needed
        return [];
    }

    const mappedCollectionsData: any[] = collections?.map((collection: any) => {return ({
        id: collection.id,
        name: collection.data.name,
        creator: collection.owner,
        backgroundUrl: collection.data.icon_url,
      })})
    return mappedCollectionsData
}
export default mapCollectionsDataForSlider;

const mapShortCollectionsDataForSlider = (collections: any) => {
    if (!Array.isArray(collections)) {
        // Return an empty array or handle the non-array scenario as needed
        return [];
    }

    const mappedCollectionsData: any[] = collections?.map((collection: any) => {return ({
        id: collection.id,
        name: collection.name,
        creator: collection.owner,
        backgroundUrl: collection.icon_url,
      })})
    return mappedCollectionsData
}

export  {mapCollectionsDataForSlider, mapShortCollectionsDataForSlider};