import {useNavigate} from "react-router-dom";
import { ReactComponent as CodeIcon } from "../assets/createItem/code_icon.svg";
import DragAndDrop from "../components/utils/DragAndDrop";
import {getCollectionById, getCollectionItemsNames} from "../backend/collections/CollectionGetters";
import { useLocation } from 'react-router-dom';
import {NFTCollection, CollectionItem, JSONItemDataInput, JSONToCollectionItem} from "../types/Collection";
import React, {useState} from "react";
import {updateCollection} from "../backend/collections/CollectionUpdates";
import {createItems} from "../backend/collections/ItemUpdates";
import RadixWalletModal from "../components/modals/RadixWalletModal";
import ErrorModal from "../components/modals/ErrorModal";
import {getCurrentUser} from "../ledger/Global";

export default function UploadJsonCreator() {

  const [isRadixWalletModalOpen, setIsRadixWalletModalOpen] = useState<boolean>(false);
  const [walletMessage, setWalletMessage] = useState<string>("");
  const [walletTitle, setWalletTitle] = useState<string>("")
  const [status, setStatus] = useState<"success" | "error" | "loading">("loading");

  const [isErrorModalOpen, setIsErrorModalOpen] = useState<boolean>(false);
  const [errorTitle, setErrorTitle] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [newItems, setNewItems] = useState<JSONItemDataInput[]>([]);
  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    let id = location.pathname.split('/')[2];
    setIsRadixWalletModalOpen(true);
    setStatus("loading");
    setWalletTitle("Fetching collection information")
    setWalletMessage("")
    let collection: NFTCollection  = await getCollectionById(parseFloat(id));
    let items_name_vec = (await getCollectionItemsNames(collection)).items_name;
    let items_name = new Set(items_name_vec);
    if (collection != null) {
      if(getCurrentUser() !== collection.owner){
        setIsRadixWalletModalOpen(false);
        setIsErrorModalOpen(true);
        setErrorTitle("You do not own this collection")
        setErrorMessage("Cannot add items to a collection that you do not own")
      }
      else{
        setIsRadixWalletModalOpen(true);
        setStatus("loading");
        setWalletTitle("Adding items to collection")
        setWalletMessage("Depending on the amount of items, this can take some time")
        let items: CollectionItem[] = [];
        let traits_stats = collection.stats.traits_stats;
        for (let json_item of newItems) {
          try{
            let item = await JSONToCollectionItem(collection, json_item);

            if(items_name.has(item.item_data.name)){
              setIsErrorModalOpen(true);
              setErrorTitle("Two items have the same name!")
              setErrorMessage("This JSON file creates duplicates")
              setIsRadixWalletModalOpen(false);
              return
            }
            items_name.add(item.item_data.name);
            collection.stats.items += 1;

            // Update the collection's stats with new traits
            for(let key in item.item_data.traits){
              let trait_category = traits_stats[key];
              if(!trait_category){
                trait_category = {items_in_category: 0, items_with_trait: {}}
              }
              trait_category.items_in_category+=1;

              let trait = item.item_data.traits[key];
              let with_trait = trait_category.items_with_trait[trait];
              if(!with_trait){
                with_trait = 0;
              }
              with_trait+=1;
              trait_category.items_with_trait[trait] = with_trait;

              traits_stats[key] = trait_category;
            }

            items.push(item);
          } catch (err){
            setIsErrorModalOpen(true);
            setErrorTitle(`Could not parse file correctly! Got error; ${err}`)
            setErrorMessage(`Error parsing JSON`)
            return
          }
        }
        collection.stats.traits_stats = traits_stats;
        try{
          await createItems(items);
          await updateCollection(collection);
        }
        catch (err){
          setIsRadixWalletModalOpen(false);
          setIsErrorModalOpen(true);
          setErrorTitle("Could not send items to backend")
          // @ts-ignore
          let error_message = err.toString();
          setErrorMessage(`Request failed with error ${error_message}.\nMake sure you are connected to the internet or contact an administrator`)
          return
        }

        try{
          await updateCollection(collection);
          setIsErrorModalOpen(false);
          setIsRadixWalletModalOpen(false);
          navigate(`/collections/${collection.id}`);
        }
        catch (err){
          setIsRadixWalletModalOpen(false);
          setIsErrorModalOpen(true);
          setErrorTitle("Could not update collection")
          // @ts-ignore
          let error_message = err.toString();
          setErrorMessage(`Request failed with error ${error_message}.\nMake sure you are connected to the internet or contact an administrator`)
          return
        }
      }
    }
    else {
      setIsErrorModalOpen(true);
      setErrorTitle("This collection does not exist")
      setErrorMessage("Cannot add items to a collection that does not exist")
    }
}

  const handleNFTFiles = async (files: File[]) => {
    let items: JSONItemDataInput[] = [];
    for (const file of files) {
      if (file.type === "application/json") {
        try {
          setWalletTitle("Loading data from file")
          setWalletMessage("")
          setStatus("loading")
          setIsRadixWalletModalOpen(true)
          const text = await file.text();
          const data: JSONItemDataInput[] = JSON.parse(text);
          items = items.concat(data);
          setWalletTitle("File parsed successfully!")
          setWalletMessage("")
          setStatus("success")
        } catch (error) {
          // Deal with error
          setIsErrorModalOpen(true);
          setErrorTitle("Could not parse file correctly!")
          setErrorMessage(`Error parsing JSON from file: ${file.name}`)
        }
      }
      else {
        setIsErrorModalOpen(true);
        setErrorTitle("Unsupported file extension")
        setErrorMessage(`File "${file.name}" has an unsupported extension. Please provide JSON files`)
      }
    }
    setNewItems(items)
  }


  return (
    <div className="w-full h-full py-[30px] px-[30px] md:py-[80px] md:px-[77px] bg-white text-text-baseDark">
      <h2 className="font-semibold text-[32px] text-center">Upload JSON</h2>

      <form
        className="max-w-[608px] mx-auto p-2 mt-[48px]"
        onSubmit={handleSubmit}
      >
        <div className="">
          <label className="mb-1 font-bold">
            Upload JSON Metadata File <span className="text-[#FD1616]">*</span>
          </label>
          <p className="mb-[20px] mt-1 text-[#93989A] text-xs">
            File types supported: .JSON (Max size: 50 MB). Fore more info on the
            JSON structure, visit: 
            <a href="https://rad-land.gitbook.io/" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-800">
            rad-land.gitbook.io
            </a>
          </p>
          <DragAndDrop
            onUpload={(files) => {
              handleNFTFiles(files);
            }}
          >
            <div className="flex flex-col items-center justify-center w-full h-full">
              <CodeIcon />
            </div>
          </DragAndDrop>
        </div>

        {/* submit btns */}
        <div className="w-full flex flex-col md:flex-row items-center gap-6  mt-[32px]">
          <button
            className="w-full px-[24px] py-[16px] font-medium text-white bg-[#535AFA] flex items-center justify-center rounded-lg"
            type="submit"
          >
            Upload
          </button>
          <RadixWalletModal
              message={walletMessage}
              title={walletTitle}
              status={status}
              isOpen={isRadixWalletModalOpen}
              setIsOpen={setIsRadixWalletModalOpen}
              loadingMessage={"Loading NFTs and sending them to backend"}
          />
          <ErrorModal
              isOpen={isErrorModalOpen}
              setIsOpen={setIsErrorModalOpen}
              message={errorMessage}
              title={errorTitle}
          />
        </div>
      </form>
    </div>
  );
}
