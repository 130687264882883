import {AccountAddress, ComponentAddress, NFT} from "../../types/Radix";
import {ProofToArg} from "../ManifestUtils";
import {sendTransaction} from "../Global";

export async function claimRevenue(account: AccountAddress, kaupa_component: ComponentAddress, trader_nft: NFT){
    let manifest = `
    ${ProofToArg(account, trader_nft, "trader_card_proof")}
    
    CALL_METHOD
        Address("${kaupa_component}")
        "collect_funds"
        Proof("trader_card_proof")
        true
        false
        None;
    
    CALL_METHOD
        Address("${account}")
        "deposit_batch"
        Expression("ENTIRE_WORKTOP");`

    return await sendTransaction(manifest, `Collecting your sales revenue`);
}